import Popup from 'Components/Elements/Popup'
import ConciergePayBookingCard from 'Pages/Admin/Concierges/SingleConcierge/Elements/ConciergePayBookingCard'
import React from 'react'

const BookingsPopup = ({ open, data, close, onBookingClick }) => {
    return (
        <Popup
            open={open}
            close={close}
            heading={'Booking History'}
            size={"sm"}>
            {data?.map((itm, ind) => (
                <ConciergePayBookingCard
                    name={itm?.guest_name}
                    pax={itm?.total_pax}
                    number={itm?.number}
                    date={itm?.date_time}
                    status={itm?.status}
                    bookingStatusSteps={
                        [{ name: 'Placed', value: 1 },
                        { name: 'Confirmed', value: 2 },
                        { name: 'Billed', value: 3 }
                        ]
                    }
                // onClick={onBookingClick && onBookingClick(itm)}
                />
            ))}
        </Popup>
    )
}

export default BookingsPopup