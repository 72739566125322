import React from 'react'
import ChatCard from 'Components/ChatCard'
import DashboardLayout from 'Components/DashboardLayout'
import Back from 'Components/Elements/Back'
import LoginInput from 'Components/Elements/LoginInput'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import NotFound from 'Utility/NotFound'
import ChatCardShimmer from 'Utility/Shimmers/ChatCardShimmer'
import InfiniteScroll from 'react-infinite-scroll-component'
import useChatHelper from './helper'
import PhoneNumberPopup from './PhoneNumberPopup'


const ConceirgeChat = () => {
    const { chat, pagination, loading, searchText, setSearchText, currentPage, setCurrentPage, phoneNumberPopup, setPhoneNumberPopop, secondLoader
    } = useChatHelper()

    return (
        <>
            <DashboardLayout active={'chat'}>
                <main className=" lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                    <div className="px-4 space-y-4">
                        <div className="flex justify-between items-center">
                            <Back title={'Chat'} />
                            <div className='w-fit'>
                                <LoginInput
                                    name={'searchText'}
                                    value={searchText}
                                    onChange={(e) => { setSearchText(e.target.value) }}
                                    placeholder={'Search business'}
                                    inputPadding={"px-4 py-[9px]"}
                                    inputClass={"flex-1"}
                                    parentClass={"w-fit"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="py-6">
                        <div className="relative border-t mx-4 py-4 border-lightGrey">
                            {loading
                                ? <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                                    {[0, 1, 2, 3, 4, 5]?.map(itm => {
                                        return <ChatCardShimmer />
                                    })}
                                </div>
                                :
                                chat?.length > 0 ?
                                    <InfiniteScroll
                                        dataLength={chat?.length ? chat?.length : 10} //This is important field to render the next data
                                        next={() => {
                                            setCurrentPage(currentPage + 1)
                                        }}
                                        hasMore={pagination && pagination?.current_page !== pagination?.total_pages}
                                        loader={secondLoader
                                            && <div className="flex justify-center items-center my-4">
                                                <PreloaderSm />
                                            </div>
                                        }
                                    >
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                                            {chat?.map(itm => {
                                                return <ChatCard
                                                    key={itm?.id}
                                                    profile={itm?.images?.length > 0 && itm?.images[0]?.thumb}
                                                    restaurant_name={itm?.name}
                                                    user_name={itm?.user?.first_name}
                                                    phone_code={itm?.phone_code}
                                                    phone_number={itm?.phone_number}
                                                    phoneNumber={itm?.phone_number && itm?.phone_number != 'null' ?
                                                        `+${itm?.phone_code} ${itm?.phone_number}` :
                                                        itm?.mobile_number && itm?.mobile_number != 'null' ?
                                                            `+${itm?.mobile_code} ${itm?.mobile_number}` : itm?.wa_number && itm?.wa_number != 'null' ?
                                                                `+${itm?.wa_code} ${itm?.wa_number}` : "----"}
                                                    whatsappNumber={itm?.wa_number && itm?.wa_number != 'null' ?
                                                        `${itm?.wa_code}${itm?.wa_number}` :
                                                        itm?.phone_number && itm?.phone_number != 'null' ?
                                                            `${itm?.phone_code}${itm?.phone_number}` :
                                                            itm?.mobile_number && itm?.mobile_number != 'null' ?
                                                                `${itm?.mobile_code}${itm?.mobile_number}` : "----"}
                                                    setPhoneNumberPopop={setPhoneNumberPopop}
                                                />
                                            })}
                                        </div>
                                    </InfiniteScroll>
                                    : <NotFound />
                            }
                        </div>
                    </div>
                </main>
            </DashboardLayout>

            <PhoneNumberPopup
                open={phoneNumberPopup}
                close={setPhoneNumberPopop}
                data={phoneNumberPopup}
            />
        </>
    )
}
export default ConceirgeChat