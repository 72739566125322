import React from 'react'
import useWishlistDetail from './helper'
import DashboardLayout from 'Components/DashboardLayout'
import Back from 'Components/Elements/Back'
import RequestCard from 'Components/RequestCard'
import NotFound from 'Utility/NotFound'
import SubmittedRequestCard from '../Elements/SubmittedRequestCard'
import moment from 'moment'
import ActivityLogCard from '../Elements/ActivityLogCard'
import ConciergeWishlistShimmer from 'Utility/Shimmers/ConciergeWishlistShimmer'

const WishListDetail = () => {
    const { wishlist, loading, activeTab, setActiveTab } = useWishlistDetail()
    return (
        <DashboardLayout active={'wishlist'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen max-h-screen overflow-y-auto">
                <div className="pt-4 flex justify-between items-center">
                    <Back
                        title={'Wishlist Detail'}
                    />
                </div>
                <div className="pb-6 pt-4">
                    <div className='flex flex-col space-y-6'>
                        {loading ?
                            <ConciergeWishlistShimmer />
                            :
                            <RequestCard
                                assignedUser={wishlist?.assigned_user ? `${wishlist?.assigned_user?.first_name} ${wishlist?.assigned_user?.last_name}` : ""}
                                address={wishlist?.address}
                                id={wishlist?.id}
                                number={wishlist?.number}
                                status={wishlist?.status}
                                title={wishlist?.name}
                                image={wishlist?.image}
                                category={wishlist?.type}
                                date={wishlist?.created_at}
                                user={wishlist?.user}
                                isConcierge={true}
                                adminWishlist={true}
                                is_active={wishlist?.is_active}
                                addedBy={wishlist?.user ? `${wishlist?.user?.first_name} ${wishlist?.user?.last_name}` : ""}
                                allowEdit={false}
                                phoneNumber={wishlist?.user?.phone_number && wishlist?.user?.phone_number != 'null' ? `+${wishlist?.user?.phone_code} ${wishlist?.user?.phone_number}` : wishlist?.user?.mobile_number && wishlist?.user?.mobile_number != 'null' ? `+${wishlist?.user?.mobile_code} ${wishlist?.user?.mobile_number}` : "----"}
                            />
                        }
                        <div className="border-b border-lightGrey flex items-baseline gap-5 text-blueGrey">
                            <p onClick={() => setActiveTab('submittedRequest')} className={`${activeTab == 'submittedRequest' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>
                                Submitted Request
                            </p>
                            <p onClick={() => setActiveTab('activityLogs')} className={`${activeTab == 'activityLogs' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>
                                Activity Logs
                            </p>
                        </div>
                        <div className='pb-4'>
                            {loading ?
                                [0, 1, 2, 3, 4, 5]?.map(itm => {
                                    return <div className='h-36 bg-gray-500 animate-pulse rounded-md mb-3'></div>
                                }) :
                                activeTab == 'submittedRequest' ?
                                    wishlist?.wishlist_request?.length > 0 ?
                                        wishlist?.wishlist_request?.map((itm, ind) => {
                                            return (
                                                <SubmittedRequestCard
                                                    first_name={itm?.user?.first_name ? itm?.user?.first_name : ""}
                                                    last_name={itm?.user?.last_name ? itm?.user?.last_name : ""}
                                                    date={moment(itm?.date).format('DD MMMM YYYY')}
                                                    talk_person={itm?.talk_person}
                                                    status={itm?.status}
                                                    interest_level={itm?.interest_level}
                                                />
                                            )
                                        }) : <NotFound />
                                    : wishlist?.logs?.length > 0 ?
                                        wishlist?.logs?.map((itm, ind) => {
                                            return (
                                                <ActivityLogCard
                                                    first_name={itm?.user?.first_name ? itm?.user?.first_name : ""}
                                                    last_name={itm?.user?.last_name ? itm?.user?.last_name : ""}
                                                    email={itm?.user?.email ? itm?.user?.email : ""}
                                                    date={moment(itm?.date).format('DD-MM-YYYY')}
                                                    assignedToEmail={itm?.new_user?.email}
                                                    assignedToName={`${itm?.new_user?.first_name ? itm?.new_user?.first_name : ""} ${itm?.new_user?.last_name ? itm?.new_user?.last_name : ""}`}
                                                />
                                            )
                                        }) : <NotFound />}
                        </div>
                    </div>
                </div>
            </main>
        </DashboardLayout>
    )
}

export default WishListDetail