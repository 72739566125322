import React, { createContext, useContext, useState, useEffect } from 'react';

const PopupManagerContext = createContext();

export const PopupManagerProvider = ({ children }) => {
    const [popupCount, setPopupCount] = useState(0);

    useEffect(() => {
        const rootElement = document.getElementById('root');
        const authWrapper = document.getElementById('auth__wrapper');
        if (rootElement) {
            if (popupCount > 0) {
                rootElement.classList.add('overflow-hidden');
                rootElement.classList.add('h-[100vh]');
                if (authWrapper) {
                    authWrapper.classList.add('overflow-hidden');
                    authWrapper.classList.add('h-[100vh]');
                }
            } else {
                rootElement.classList.remove('h-[100vh]');
                rootElement.classList.remove('overflow-hidden');
                if (authWrapper) {
                    authWrapper.classList.remove('overflow-hidden');
                    authWrapper.classList.remove('h-[100vh]');
                }
            }
        }
    }, [popupCount]);

    const openPopup = () => setPopupCount((prevCount) => prevCount + 1);
    const closePopup = () => setPopupCount((prevCount) => Math.max(prevCount - 1, 0));

    return (
        <PopupManagerContext.Provider value={{ openPopup, closePopup }}>
            {children}
        </PopupManagerContext.Provider>
    );
};

export const usePopupManager = () => useContext(PopupManagerContext);
