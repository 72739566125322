import React from 'react'
import AddCuisinePopup from './Elements/AddCuisinePopup'
import useRestaurantFormHelper from './helper'
import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'

const RestaurantForm = (props) => {
    const { handleChange, errors, cuisines, formData, setErrors, handleSubmit, loading, addCuisine, setAddCuisine, getCuisineLoader,
        getCuisineData, categories, addBusiness, isSubCategoryRequired, navigate, roleValue
    } = props

    const { step, setStep } = useRestaurantFormHelper(addBusiness)

    return (
        <>
            {/* {step == 1 &&
                <Step1
                    step={step}
                    setStep={setStep}
                    categoriesData={categories}
                    onClick={(id) => {
                        let event = {
                            target: {
                                name: 'categories',
                                value: [id]
                            }
                        }
                        handleChange(event)
                    }}
                    value={formData?.categories}
                    getCategoriesLoader={getCategoriesLoader}
                />
            }
            {step == 2 && */}
            <Step2
                addBusiness={addBusiness}
                step={step}
                setStep={setStep}
                navigate={navigate}
                labelName={roleValue?.name}
                formData={formData}
                errors={errors}
                handleChange={handleChange}
                categories={categories}
                cuisines={cuisines}
                setAddCuisine={setAddCuisine}
                setErrors={setErrors}
                loading={loading}
                handleSubmit={handleSubmit}
                isSubCategoryRequired={isSubCategoryRequired}
                getCuisineLoader={getCuisineLoader}
            />
            {/* } */}

            <AddCuisinePopup
                open={addCuisine}
                close={setAddCuisine}
                getCuisineLoader={getCuisineLoader}
                getCuisineData={getCuisineData}
                selectedCategories={formData?.categories}
            />
        </>
    )
}

export default RestaurantForm