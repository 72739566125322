import { apiErrorMessage } from "Constants/Data/Errors";
import axios from "axios";
import { toast } from "react-toastify";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { getAdminConciergesData, getAdminSingleConciergesData } from "../../../Redux/Admin/Concierges/Action";
import { BASE_URL, get_bookings_by_id, get_concierges_url, get_profile_by_id, get_single_concierge_bookings_data, pay_concierger, update_concierge_commission, update_concierge_status } from "Adapters/variables";

// get concierge api data
export const getAdminConciergeApiData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_concierges_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            dispatch(getAdminConciergesData(data))
            success && success(false)
        } else {
            fail && fail(false)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail(false)
        return error;
    }
}

// get admin single concierge api data
export const getAdminSingleConciergeApiData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_profile_by_id}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            dispatch(getAdminSingleConciergesData(data))
            success && success(false)
        } else {
            fail && fail(false)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail(false)
        return error;
    }
}

// update concierge toogle status
export const updateConciergeToogleStatus = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${update_concierge_status}`, payload, headers);
        if (response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, {toastId: 'toast'})
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
        return error;
    }
}

// update concierge comission
export const updateConciergeCommission = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${update_concierge_commission}`, payload, headers);
        if (response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, {toastId: 'toast'})
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
        return error;
    }
}

// get admin single concierge user data at bookings page
export const getAdminSingleConciergeBookingsUserData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_single_concierge_bookings_data}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail(error)
        return error;
    }
}

// get admin single concierge bookings data
export const getAdminSingleConciergeBookingsData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_bookings_by_id}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail(error)
        return error;
    }
}

// pay concierge due
export const payConciergeRemainingDues = (access_token, payload, success, fail) => async (dispatch) => {
    const formData = new FormData();
    formData.append('paid_proof', payload.paid_proof);
    formData.append('paid_amount', payload.paid_amount);
    payload?.reservationIds?.forEach(item => {
        formData.append('reservation_id[]', item);
    });
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${pay_concierger}`, formData, headers);
        if (response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, {toastId: 'toast'})
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
        return error;
    }
}
