import Svgs from 'Assets/svgs';
import React from 'react';

const VerticalStatusSlider = ({ parentClass, steps, activeStep }) => {
    return (
        <>
            <div className={`flex flex-col justify-between items-center relative ${parentClass ? parentClass : ""}`}>
                {steps?.map((step, ind) => (
                    <React.Fragment key={ind}>
                        {ind !== 0 &&
                            <div className={`h-3 w-[1px] items-center cursor-pointer ${step.value <= activeStep ? "bg-secondary" : 'bg-blueGrey'}`} />
                        }
                        <div className={`rounded-full transition-all duration-300 relative place-items-center z-2 grid cursor-pointer `}>
                            <div className={`absolute text-xs whitespace-wrap ${step?.name === activeStep ? "left-[1.05rem]" : "left-[1.5rem]"} pl-2.5 w-max text-center text-blueGrey`}>
                                {step?.name}
                            </div>
                            <div className='text-secondary flex items-center justify-center'>
                                {step?.value <= activeStep
                                    ? step?.activeIcon ? step?.activeIcon : <Svgs.TickIcon className='bg-secondary rounded-full' fill={'var(--primary-color)'} />
                                    : step?.unActiveIcon ? step?.unActiveIcon : <div className='w-5 h-5 p-1 bg-lightGrey rounded-full flex items-center justify-center'>
                                        <Svgs.CrossIcon width={9} height={9} fill={'var(--secondary-color)'} />
                                    </div>
                                }
                                {/* {step.value <= activeStep
                                    ? step?.activeIcon
                                    : customUnActiveIcon
                                        ? customUnActiveIcon
                                        : step?.unActiveIcon
                                } */}
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </>
    );
};

export default VerticalStatusSlider;
