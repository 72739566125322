import React from 'react'
import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import LoginInput from 'Components/Elements/LoginInput'
import RestaurantFIlterPopup from 'Pages/Concierges/Restaurants/RestaurantFIlterPopup'
import useRestaurantFilter from './helper'
import Popup from 'Components/Elements/Popup'
import NotFound from 'Utility/NotFound'
import RadioBtn from 'Components/Elements/RadioBtn'

const RestaurantFilter = ({ open, close, openBooking }) => {
    const { filterPopup, setFilterPopup, filterData, setFilterData, getRestaurants, Cuisines, loader, onChangeHandler, restaurants, navigate,
        searchText, setSearchText, categories
    } = useRestaurantFilter()

    return (
        <Popup
            popupColor={'bg-darkGrey'}
            open={open}
            close={close}
            size={'sm'}
            customPadding={'!p-0'}
            heading={'Choose a business'}
        >
            <div className="flex w-full gap-4 py-4 px-5 items-end justify-between">
                <LoginInput
                    inputPadding={'pl-12'}
                    bgIconTransparent={'!bg-transparent'}
                    leftIcon={<Svgs.SearchIcon />}
                    title={''}
                    placeholder={'Select Business'}
                    required={true}
                    type="text"
                    name={'search_text'}
                    parentClass={'flex-1 !text-base'}
                    onChange={(e) => {
                        setSearchText(e?.target?.value)
                    }}
                    value={searchText}
                />
                <Button
                    onClick={() => setFilterPopup(true)}
                    customPadding={'bg-lightGrey !size-10 flex items-center justify-center !rounded-[10px]'}
                    text={<Svgs.FilterIcon fill={'white'} />}
                />
            </div>
            <div className="flex flex-col gap-3 max-h-80 overflow-auto mt-1 py-3 p-5">
                {loader
                    ? [0, 1, 2, 3, 4, 5]?.map(itm => {
                        return <div className='animate-pulse flex w-full flex-col gap-3' >
                            <div className="bg-gray-500 w-full flex items-center justify-center h-11 rounded-lg" />
                        </div>
                    })
                    : restaurants?.length > 0
                        ? restaurants?.map(itm => {
                            return (
                                <Button
                                    customThemeBtn={'view-edit-btn text-blueGrey'}
                                    className="flex !text-left !justify-start hover:bg-[--lightGrey-color] bg-transparent"
                                    title={''}
                                    onClick={() => {
                                        open == "promotion"
                                            ? navigate(`/concierge/promotions?restaurant_id=${itm?.id}`)
                                            : openBooking(itm?.id)
                                        close && close(false)
                                        setSearchText("")
                                    }}
                                >
                                    <div className='flex gap-4 items-center justify-between w-full'>
                                        <p className="flex-1 text-white font-normal">
                                            {itm?.name}
                                        </p>
                                        <RadioBtn className={'hidden'} />
                                        <Svgs.SmallArrowIcon />
                                    </div>
                                </Button>
                            )
                        })
                        : <NotFound />
                }

            </div>
            <RestaurantFIlterPopup
                open={filterPopup}
                close={setFilterPopup}
                Cuisines={Cuisines}
                categories={categories}
                onChange={onChangeHandler}
                filterData={filterData}
                setFilterData={setFilterData}
                onClearFilter={() => {
                    getRestaurants(1, 'clear')
                }}
                onSubmit={() => {
                    getRestaurants(1)
                }}
            />
        </Popup>
    )
}

export default RestaurantFilter