import { getRestaurantChat } from 'Adapters/APIs/Chat'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
let timeOutId = undefined

const useChatHelper = () => {
    const dispatch = useDispatch()

    // redux states
    const state = useSelector(state => state)
    const chat = state?.chat?.chat
    const pagination = state?.chat?.chat_pagination

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')

    // states
    const [loading, setLoading] = useState(true)
    const [searchText, setSearchText] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [phoneNumberPopup, setPhoneNumberPopop] = useState(false)
    const [secondLoader, setSecondLoader] = useState(false)

    // get chat data
    const getChat = () => {
        const success = () => {
            setLoading(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoading(false)
            setSecondLoader(false)
        }
        if (currentPage == 1) {
            setLoading(true)
        } else {
            setSecondLoader(true)
        }
        dispatch(getRestaurantChat(access_token, { page: currentPage, search_text: searchText, restaurant_id: user_data?.restaurant?.id }, success, fail))
    }

    useEffect(() => {
        if (searchText === null) {
            getChat()
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                getChat()
            }, 500);
        }
    }, [searchText, currentPage])

    useEffect(() => {
        setCurrentPage(1)
    }, [searchText])

    return {
        chat, pagination, loading, searchText, setSearchText, currentPage, setCurrentPage, phoneNumberPopup, setPhoneNumberPopop,
        secondLoader
    }
}

export default useChatHelper