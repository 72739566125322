import React from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import DummyImage from "../../../../Assets/images/error2.png"

export default function RestaurantSlider({ data }) {
    return (
        <>
            {data?.length > 1 &&
                <Swiper
                    modules={[Autoplay]}
                    spaceBetween={10}
                    autoplay={true}
                    breakpoints={{
                        200: {
                            slidesPerView: 3,
                        }
                    }}
                >
                    {data?.slice(1)?.map((itm, index) => (
                        <SwiperSlide key={index}>
                            <div className='xl:h-20 h-28 w-full'>
                                <img className='h-full w-full object-cover rounded-lg' src={itm?.url} alt={index} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            }
        </>
    );
}