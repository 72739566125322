import { BASE_URL, book_available_slot, check_available_slot, get_all_available_slots, get_boosting_history_data, get_boosting_history_detail_data, get_setting_data } from "Adapters/variables";
import axios from "axios";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { getAllSlots, getBoostingHistory } from "../../../Redux/Restaurant/ProfileBoosting/Actions";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";
import { toast } from "react-toastify";

// get profile boosting setting data
export const getProfileBoostigSettingData = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const query = buildQueryString(payload)
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_setting_data}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data?.versions)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
}

// get all available slots
export const getAllAvailableSlots = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const query = buildQueryString(payload)
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_all_available_slots}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data?.slots
            success && success(data)
            dispatch(getAllSlots(data))
        } else {
            fail && fail(response)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
}

// get all available slots
export const getCheckAvailableSlots = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const query = buildQueryString(payload)
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${check_available_slot}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data?.estimated_bill
            if (payload?.slot_id) {
                toast.success(response?.data?.message, { toastId: 'toast' })
            }
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        if (payload?.slot_id) {
            toast.error(error?.response?.data?.error, { toastId: 'toast' })
        } else if (error?.response?.data?.status == 400) {
            toast.error(error?.response?.data?.error, { toastId: 'toast' })
        }
        fail && fail(error)
        return error;
    }
}

// post slot booking data
export const bookAvailableSlotBoosting = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const formData = convertDataToFormData(payload)
        const response = await axios.post(`${BASE_URL}${book_available_slot}`, formData, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            success && success()
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
}

// get profile boosting history data
export const getProfileBoostingHistoryData = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const query = buildQueryString(payload)
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_boosting_history_data}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            dispatch(getBoostingHistory(data))
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
}

// get profile boosting history data
export const getProfileBoostingHistoryDetailData = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_boosting_history_detail_data}${payload?.slot_id}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        if (error?.response?.data?.status == 400) {
            toast.error(error?.response?.data?.error, { toastId: 'toast' })
        }
        fail && fail(error)
        return error;
    }
}