import Button from 'Components/Elements/Button'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import { objectKeyConvertToArray } from 'Hooks/useObjectKeys'
import useValidations from 'Hooks/useValidations'
import React, { useEffect, useState } from 'react'
import NotFound from 'Utility/NotFound'

const AddBusinessNamePopup = ({ open, close, handleChange, data, setAddBusinessName }) => {
    const { errors, setErrors, validation } = useValidations()
    // states
    const [name, setName] = useState("")
    const [buinessNames, setBusinessNames] = useState(data)

    useEffect(() => {
        if (data?.length > 0 && name) {
            // Use filter to find items where the name includes the search term
            const filteredBusinessNames = data.filter(itm => itm?.name?.toLowerCase().includes(name.toLowerCase()));
            setBusinessNames(filteredBusinessNames);
        } else if (data?.length > 0) {
            setBusinessNames(data);
        } else {
            setBusinessNames([]);
        }
    }, [data, name]);

    // handle submit data
    const handleSubmit = () => {
        const validationErrors = validation({ name: name });
        setErrors(validationErrors);

        if (objectKeyConvertToArray(validationErrors)?.length === 0) {
            handleChange({
                target: {
                    name: 'business_name',
                    value: name
                }
            });
            setName("")
            setAddBusinessName(false);
        }
    }

    return (
        <Popup
            open={open}
            close={close}
            size={'xs'}
            customPadding={'!p-0'}
            heading={'Select Business Name'}
        >
            <div className={`p-4 flex flex-col ${errors?.name ? "space-y-6" : "space-y-4"}`}>
                <div className='flex gap-2 justify-between items-center'>
                    <div className='flex-1'>
                        <LoginInput
                            title={''}
                            placeholder={'Search or enter business name'}
                            required={true}
                            type="text"
                            name={'name'}
                            parentClass={'flex-1 !text-base'}
                            onChange={(e) => {
                                setName(e?.target?.value)
                                setErrors((prev) => ({
                                    ...prev,
                                    name: ""
                                }))
                            }}
                            value={name}
                            error={errors?.name}
                        />
                    </div>
                    <Button
                        text={"Add"}
                        onClick={handleSubmit}
                        className="w-fit !py-2.5"
                    />
                </div>
                <div className='flex flex-col space-y-4 text-white px-1 max-h-72 overflow-y-auto'>
                    {buinessNames?.length > 0 ? buinessNames?.map((itm) => (
                        <div key={itm?.id} className='cursor-pointer' onClick={() => {
                            handleChange({
                                target: {
                                    name: 'business_name',
                                    value: itm?.name
                                }
                            })
                            setName("")
                            setAddBusinessName(false);
                        }}>
                            {itm?.name}
                        </div>
                    )) : <NotFound />}
                </div>
            </div>
        </Popup>
    )
}

export default AddBusinessNamePopup