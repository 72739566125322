import React from 'react';
import RadioBtn from './RadioBtn';

const StatusInput = ({
    title,
    required = false,
    value,
    onChange,
    name,
    parentClass
}) => {
    const handleClick = (selectedValue) => {
        if (onChange) {
            onChange({
                target: {
                    name: name ? name : "status",
                    value: selectedValue
                }
            });
        }
    };

    return (
        <div className={`${parentClass ? parentClass : ""} relative flex flex-col gap-2 `}>
            {title && (
                <div className={`${title == '' ? 'hidden' : ''} text-sm text-blueGrey flex items-center gap-1`}>
                    <div>{title}</div> {required && <span className="leading-[1] text-red-600"> *</span>}
                </div>
            )}

            <div className="flex items-center gap-4 text-blueGrey">
                <RadioBtn name={'activeTab'} text={"Active"} checked={value === 1} id={'Active'} onClick={() => { handleClick(1) }} />
                <RadioBtn name={'activeTab'} text={"Inactive"} checked={value === 0} id={'Inactive'} onClick={() => { handleClick(0) }} />
            </div>
        </div>
    );
};

export default StatusInput;
