import React from 'react'

const SingleAdminRestaurantDetailShimmer = ({ hideSliderShimmer, restaurantDetail }) => {
    return (
        <div className="pt-4 space-y-5 animate-pulse">
            {!restaurantDetail ?
                <>
                    <div className='flex justify-between items-center'>
                        <div className="h-6 w-60 bg-gray-300 rounded-md"></div>
                        <div className="h-6 justify-end w-[36px] bg-gray-300 rounded-full"></div>
                    </div>
                    <div className='flex flex-col space-y-2 justify-between'>
                        <div className="h-6 md:w-96 w-full bg-gray-200 rounded-md"></div>
                        <div className="h-6 w-60 bg-gray-200 rounded-md"></div>
                        <div className="h-6 w-52 bg-gray-200 rounded-md"></div>
                    </div>
                </>
                : <div className='flex items-center gap-5'>
                    <div className="size-10 rounded-full bg-gray-200"></div>
                    <div className="h-6 w-20 bg-gray-200 rounded-md"></div>
                </div>}
            {
                !hideSliderShimmer &&
                <>
                    <div className='grid sm:grid-cols-2 grid-cols-1 justify-between items-center gap-4'>
                        <div className='flex flex-col space-y-2'>
                            <div className="h-6 w-40 bg-gray-300 rounded-md"></div>
                            <div className="h-[200px] w-full bg-gray-200 rounded-md"></div>
                        </div>
                        <div className='flex flex-col space-y-2'>
                            <div className="h-6 w-40 bg-gray-300 rounded-md"></div>
                            <div className="h-[200px] w-full bg-gray-200 rounded-md"></div>
                        </div>
                    </div>
                    <div className='flex md:flex-row flex-col justify-between gap-4 items-center'>
                        <div className='flex flex-1 gap-4 items-center'>
                            <div className="h-12 w-40 bg-gray-200 rounded-full flex flex-col gap-3 items-center justify-between py-3 px-6 cursor-pointer">
                                <div className="flex gap-2 items-center"></div>
                            </div>
                            <div className="h-12 w-40 bg-gray-200 rounded-full flex flex-col gap-3 items-center justify-between py-3 px-6 cursor-pointer">
                            </div>
                        </div>
                        <div className='h-10 w-20 bg-gray-200 text-white max-h-[30px] flex gap-2 items-center rounded-md cursor-pointer'></div>
                    </div>
                </>
            }

            {
                restaurantDetail &&
                <div className="flex items-start gap-5 lg:flex-row flex-col">
                    <div className="flex-1 w-full">
                        <div className="w-full max-h-[400px] bg-gray-200 aspect-video object-cover rounded-[20px]"></div>
                    </div>
                    <div className="xl:w-64 lg:w-52 w-full flex lg:flex-col gap-3 overflow-x-auto ">
                        <div className="lg:w-full max-w-40 max-h-24 bg-gray-200 aspect-video object-cover rounded-[10px]"></div>
                        <div className="lg:w-full max-w-40 max-h-24 bg-gray-200 aspect-video object-cover rounded-[10px]"></div>
                        <div className="lg:w-full max-w-40 max-h-24 bg-gray-200 aspect-video object-cover rounded-[10px]"></div>
                    </div>
                </div>
            }



            <div className='grid lg:grid-cols-4 xs:grid-cols-2 grid-cols-1 gap-4'>
                <div className='rounded-lg min-h-28 flex flex-col items-center justify-center space-y-2 py-5 bg-gray-200'></div>
                <div className='rounded-lg min-h-28 flex flex-col items-center justify-center space-y-2 py-5 bg-gray-200'></div>
                <div className='rounded-lg min-h-28 flex flex-col items-center justify-center space-y-2 py-5 bg-gray-200'></div>
                <div className='rounded-lg min-h-28 flex flex-col items-center justify-center space-y-2 py-5 bg-gray-200'></div>
            </div>
            {
                !restaurantDetail &&
                <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
                    {hideSliderShimmer ?
                        <div className={`bg-gray-200 rounded-lg flex flex-col space-y-2 items-start justify-between p-5 min-h-44`}>
                        </div> :
                        <div className='flex flex-col space-y-2'>
                            <div className="h-6 w-40 bg-gray-300 rounded-md"></div>
                            <div className="h-6 w-3/4 bg-gray-200 rounded-md"></div>
                            <div className="h-6 w-40 bg-gray-300 rounded-md"></div>
                            <div className='max-w-[14rem] h-20 bg-gray-200 rounded'></div>
                        </div>
                    }
                    <div className={`bg-gray-200 rounded-lg flex flex-col space-y-2 items-start justify-between p-5 min-h-44`}>
                    </div>
                </div>
            }
        </div>
    )
}

export default SingleAdminRestaurantDetailShimmer