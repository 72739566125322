
import Pagination from 'Components/Pagination'
import DashboardLayout from 'Components/DashboardLayout'
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer'
import CommissionAnalyticTable from 'Utility/Tables/CommissionAnalyticTable'
import PaymentCard from './Element/PaymentCard'
import PaymentDetailPopup from './Element/PaymentDetailPopup'
import useConceirgeCommission from './helper'
import React from 'react'
import Back from 'Components/Elements/Back'
import Svgs from 'Assets/svgs'
import CommissionFilter from './CommissionFilter'
import ConceirgeCardShimmer from 'Utility/Shimmers/ConceirgeCardShimmer'
import DashboardCard from 'Components/ConceirgeCards/DashboardCard'
import moment from 'moment'
import { convertObjectToArray } from 'Hooks/useObjectKeys'

const ConciergeCommission = ({ active, fromWallet }) => {
    const { commissionloading, commission_stats, recent_transfers, setOpenDetail, commissions, currentPage, pagination_data, setCurrentPage,
        openDetail, userData, loading, onRecveiveClick, filterPopup, setFilterPopup, onApplyFilter, onClearFilter, setFilterData, filterData,
        handleChangeFilter, appliedFilter
    } = useConceirgeCommission()

    return (
        <>
            <DashboardLayout active={active ? active : 'commission'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen max-h-screen overflow-y-auto">
                    <div className="space-y-4">
                        <div className='flex gap-2 justify-between items-center'>
                            <Back title={'Commission Analytics'} />
                            <div className='cursor-pointer' onClick={() => { setFilterPopup(true) }}>
                                <Svgs.Filter2 width={40} height={40} />
                            </div>
                        </div>

                        {convertObjectToArray(appliedFilter)?.length > 0 ?
                            <div className="flex gap-5 my-3 min-w-full overflow-x-auto whitespace-nowrap px-4 py-2">
                                {appliedFilter?.type &&
                                    <div className="flex gap-1 text-white capitalize"><span className='font-semibold text-secondary'>Status:</span>{appliedFilter?.type}</div>
                                }
                                {appliedFilter?.filter && appliedFilter?.filter != 'date' &&
                                    <div className="flex gap-1 text-white capitalize"><span className='font-semibold text-secondary'>Time limit:</span>
                                        {appliedFilter?.filter && appliedFilter?.filter?.split('_').join(' ')}
                                    </div>
                                }
                                {appliedFilter?.start_date &&
                                    <div className="flex gap-1 text-white capitalize">
                                        <span className='font-semibold text-secondary'>Start Date:</span>
                                        {moment(appliedFilter?.start_date).format("DD MMMM YYYY")}
                                    </div>
                                }
                                {appliedFilter?.end_date &&
                                    <div className="flex gap-1 text-white capitalize">
                                        <span className='font-semibold text-secondary'>End Date:</span>
                                        {moment(appliedFilter?.end_date).format("DD MMMM YYYY")}
                                    </div>
                                }
                            </div> : ''
                        }
                        {commissionloading
                            ?
                            <>
                                <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 rounded-xl">
                                    {[1, 2, 3, 4]?.map(itm => {
                                        return <ConceirgeCardShimmer icon showViewDetail={false} customPadding={'!p-0 !py-2 min-w-40'} shimmerStyle={'!py-0'} />
                                    })}
                                </div>
                            </>
                            : <>

                                <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 rounded-xl">
                                    {!fromWallet &&
                                        <DashboardCard
                                            className={'!p-0'}
                                            customPadding={'p-0'}
                                            icon1={<Svgs.TotalEarned width={22} height={22} />}
                                            title1={'Total Earned Since Joining'}
                                            amount1={commission_stats?.total_earning}
                                            showViewDetail={false}
                                        />
                                    }
                                    <DashboardCard
                                        className={'!p-0'}
                                        customPadding={'p-0'}
                                        icon1={<Svgs.TotalEarned width={22} height={22} />}
                                        title1={'Total Bill'}
                                        amount1={commission_stats?.total_bill}
                                        showViewDetail={false}
                                    />
                                    <DashboardCard
                                        className={'!p-0'}
                                        customPadding={'p-0'}
                                        icon1={<Svgs.TotalEarned width={22} height={22} />}
                                        title1={'Total Earning'}
                                        amount1={commission_stats?.my_earning}
                                        showViewDetail={false}
                                    />
                                    <DashboardCard
                                        className={'!p-0'}
                                        customPadding={'p-0'}
                                        icon1={<Svgs.TotalEarned width={22} height={22} />}
                                        title1={'Total Guest'}
                                        amount1={commission_stats?.total_pax}
                                        hideCurrency
                                        showViewDetail={false}
                                    />
                                </div>

                                {(!fromWallet && recent_transfers?.length > 0) &&
                                    <div className="grid grid-cols-1 lg:grid-cols-2 items-center col-span-3 gap-4">
                                        <div className="font-semibold text-xl lg:col-span-2 text-secondary">Recent Transactions</div>
                                        {recent_transfers?.map(itm => {
                                            return (
                                                <PaymentCard
                                                    className='col-span-1 w-full max-w-none'
                                                    date={itm?.date}
                                                    number={itm?.number}
                                                    id={itm?.id}
                                                    amount={itm?.amount}
                                                    onCardClick={() => { setOpenDetail(true) }}
                                                />
                                            )
                                        })}
                                    </div>
                                }
                            </>
                        }
                    </div>
                    <div className="py-6">
                        <div className="lg:col-span-8 space-y-5">
                            <div className="flex justify-between">
                                <h2 className="font-bold text-2xl text-secondary">Billed Bookings</h2>
                            </div>
                            <div className="space-y-3">
                                <div className="relative sm:rounded-lg border border-table">
                                    {commissionloading
                                        ? <BookingTableShimer columns={8} />
                                        : <CommissionAnalyticTable
                                            data={commissions}
                                        />
                                    }
                                    {commissions?.length > 0 &&
                                        <Pagination
                                            loading={commissionloading}
                                            currentPage={currentPage}
                                            pageSize={pagination_data?.per_page}
                                            totalCount={pagination_data?.total_pages}
                                            onPageChange={(page) => setCurrentPage(page)}
                                            totalDataCount={pagination_data?.total}
                                            currentPageCount={commissions?.length}
                                            showing={pagination_data?.showing}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <PaymentDetailPopup
                    open={openDetail}
                    close={setOpenDetail}
                    name={userData?.first_name}
                    date={recent_transfers[0]?.date}
                    number={recent_transfers[0]?.number}
                    proof={recent_transfers[0]?.proof}
                    amount={recent_transfers[0]?.amount}
                    loading={loading}
                    onSubmit={onRecveiveClick}
                />

                <CommissionFilter
                    open={filterPopup}
                    close={setFilterPopup}
                    onClearFilter={onClearFilter}
                    onApplyFilter={onApplyFilter}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    handleChangeFilter={handleChangeFilter}
                />
            </DashboardLayout >
        </>
    )
}

export default ConciergeCommission