import { BASE_URL, get_restaurant_business, add_restaurant_business, resend_email_otp, business_email_otp_verification, switch_business_profile, update_restaurant_business } from "Adapters/variables";
import axios from "axios";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { getAllBusinessData, getAllBusinessDataTopbar } from "../../../Redux/Restaurant/Business/Actions";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";
import { convertDataToFormData, convertObjectToForm } from "Constants/Functions/convertDataToFormData";

// Function to show a single error message
const showSingleErrorMessage = (error) => {
    if (error && typeof error === 'object') {
        // Get the first key in the error object
        const firstKey = Object.keys(error)[0];

        if (firstKey && Array.isArray(error[firstKey]) && error[firstKey].length > 0) {
            // Get the first message for the first key
            const message = error[firstKey][0];

            // Show the error message
            toast.error(message, {
                toastId: 'toast' // Using a single toastId to ensure only one toast is shown
            });
        } else {
            // Handle case where error[firstKey] is not an array or is empty
            console.warn('No valid error message found.');
        }
    } else {
        // Handle case where error is not an object or is undefined
        console.warn('Error object is invalid.');
    }
};

// get all business
export const getRestaurantBusiness = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const quer = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_restaurant_business}${quer}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            if (payload?.topbar) {
                dispatch(getAllBusinessDataTopbar(data))
            } else {
                dispatch(getAllBusinessData(data))
            }
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}

// create new business
export const createNewBusinessAccount = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const formData = convertDataToFormData(payload)
        const response = await axios.post(`${BASE_URL}${add_restaurant_business}`, formData, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            success && success()
        }
    } catch (error) {
        if (error?.response?.data?.status == 422) {
            showSingleErrorMessage(error?.response?.data?.error)
            fail && fail(error)
        } else {
            toast.error(error?.response?.data?.message || apiErrorMessage, { toastId: 'toast' })
            fail && fail(error)
        }
        return error;
    }
}

// resend OTP
export const resendOTPBusiness = (access_token, data, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${resend_email_otp}`, data, headers);
        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success();
                toast.success(response?.data?.message, {
                    toastId: 'toast'
                })
                return
            }
        } else {
            toast.error(apiErrorMessage, { toastId: 'toast' })
            fail && fail();
        }
    } catch (error) {
        if (error?.response?.data?.status == 404) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
            fail && fail();
            return
        }
    }
};

// OTP verification
export const OtpVerificationBusiness = (access_token, data, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${business_email_otp_verification}`, data, headers);

        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response);
                toast.success(response?.data?.message, {
                    toastId: 'toast'
                })
                return
            }
        } else {
            toast.error(apiErrorMessage, { toastId: 'toast' })
            fail && fail();
        }
    } catch (error) {
        if (error?.response?.data?.status == 404) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
            fail && fail();
            return
        }
        if (error?.response?.data?.status == 403) {
            toast.success(error?.response?.data?.message, { toastId: 'toast' })
            fail && fail(error);
            return
        }
    }
};


// switch business profile
export const switchBusinessProfile = (access_token, data, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${switch_business_profile}`, data, headers);
        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response?.data);
            }
        }
    } catch (error) {
        if (error?.response?.data?.status == 403) {
            toast.success(error?.response?.data?.error, {
                toastId: 'toast'
            })
        } else if (error?.response?.data?.status == 404) {
            toast.success(error?.response?.data?.error, {
                toastId: 'toast'
            })
        }
        else if (error?.response?.status == 302) {
            toast.error(error?.response?.data?.error, { toastId: 'toast' })
            fail && fail();
        }
        fail && fail(error);
        return
    }
};

// update business profile data
export const updateBusinessProfile = (access_token, data, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const form_data = convertObjectToForm(data)
        const response = await axios.post(`${BASE_URL}${update_restaurant_business}`, form_data, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            success && success(response?.data?.user);
        }
    } catch (error) {
        if (error?.response?.data?.status == 403) {
            toast.error(error?.response?.data?.message, {
                toastId: 'toast'
            })
            fail && fail(error)
        } if (error?.response?.data?.status == 422) {
            showSingleErrorMessage(error?.response?.data?.error)
            fail && fail(error)
        } else {
            toast.error(apiErrorMessage, {
                toastId: 'toast'
            })
            fail && fail(error)
        }
        return error;
    }
};