import Svgs from 'Assets/svgs'
import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useBookingAnalytics from './helper'
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer'
import BookingAnalyticsFilter from 'Pages/Admin/Analytics/Elements/BookingAnalyticsFilter'
import RestaurantAnalyticsTable from 'Utility/Tables/RestaurantAnalyticsTable'
import { RESTAURANT_BOOKING_STATUSES } from 'Constants/Data/Statuses'
import Back from 'Components/Elements/Back'
import DashboardCard from 'Components/ConceirgeCards/DashboardCard'
import ConceirgeCardShimmer from 'Utility/Shimmers/ConceirgeCardShimmer'
import Pagination from 'Components/Pagination'
import CSVExportPopup from 'Pages/Concierges/BookingAnalytics/Elements/CSVExportPopup'
import Button from 'Components/Elements/Button'

const RestaurantBookingAnalytics = () => {
    const { activeTab, setActiveTab, dropdownOptions, paginationData, currentPage, setCurrentPage, filterData, setFilterData,
        loader, data, addFilter, setAddFilter, handleChangeFilter, onClearFilter, onApplyFilter, downloadCSV, setDownloadCSV,
        downloadCSVData, setDownloadCSVData, CSVLoader, getBookingAnalyticsCSVData
    } = useBookingAnalytics()

    return (
        <DashboardLayout active={'analytics'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                <div className='flex gap-2 justify-between items-center py-4 px-2'>
                    <Back
                        title={'Booking Analytics'}
                    />
                    <div className='flex gap-2 items-center'>
                        <div className='w-fit'>
                            <Button
                                onClick={() => {
                                    setDownloadCSV(!downloadCSV)
                                }}
                            >
                                <div className='flex gap-2 items-center'>
                                    <Svgs.ExportIcon width={"20"} height={"20"} />
                                    Download CSV
                                </div>
                            </Button>
                        </div>
                        <div className='cursor-pointer' onClick={() => { setAddFilter(true) }}><Svgs.Filter2 width={40} height={40} /></div>
                    </div>
                </div>

                <div className="grid sm:grid-cols-3 grid-cols-2 gap-3 sm:gap-4 mb-5">
                    <div className="grid lg:grid-cols-4 grid-cols-2 sm:col-span-3 col-span-2 gap-3">
                        {loader
                            ? Array.from({ length: 4 }).map(itm => {
                                return <ConceirgeCardShimmer icon showViewDetail={false} customPadding={'!p-0 min-w-40'} shimmerStyle={'!p-0'} />
                            })
                            : <>
                                <DashboardCard
                                    icon1={<Svgs.TotalEarned width={22} height={22} />}
                                    className={'!p-0'}
                                    customPadding={'p-0'}
                                    hideCurrency={true}
                                    headingClassName={'!flex-col-reverse'}
                                    title1={'Total bookings'}
                                    amount1={data?.total_bookings}
                                    showViewDetail={false}
                                />
                                <DashboardCard
                                    icon1={<Svgs.TotalSales width={22} height={22} />}
                                    className={'!p-0'}
                                    customPadding={'p-0'}
                                    hideCurrency={true}
                                    headingClassName={'!flex-col-reverse'}
                                    title1={'Action Pending'}
                                    amount1={data?.placed_bookings}
                                    showViewDetail={false}
                                />
                                <DashboardCard
                                    icon1={<Svgs.TotalReceived width={22} height={22} />}
                                    className={'!p-0'}
                                    customPadding={'p-0'}
                                    hideCurrency={true}
                                    headingClassName={'!flex-col-reverse'}
                                    title1={'Accepted'}
                                    amount1={data?.accepted_bookings}
                                    showViewDetail={false}
                                />
                                <DashboardCard
                                    icon1={<Svgs.TotalEarned width={22} height={22} />}
                                    className={'!p-0'}
                                    customPadding={'p-0'}
                                    hideCurrency={true}
                                    headingClassName={'!flex-col-reverse'}
                                    text1Class={'text-wrap break-words'}
                                    title1={'Cancelled / Rejected'}
                                    amount1={data?.cancelled_bookings}
                                    showViewDetail={false}
                                />
                            </>
                        }
                    </div>
                    <hr className='border-lightGrey sm:col-span-3 col-span-2' />
                    {loader
                        ? Array.from({ length: 6 }).map(itm => {
                            return <ConceirgeCardShimmer icon showViewDetail={false} customPadding={'!p-0 min-w-40'} shimmerStyle={'!p-0'} />
                        })
                        : <>
                            <DashboardCard
                                icon1={<Svgs.TotalEarned width={22} height={22} />}
                                className={'!p-0'}
                                customPadding={'p-0'}
                                hideCurrency={true}
                                headingClassName={'!flex-col-reverse'}
                                title1={'No Show'}
                                amount1={data?.no_show_bookings}
                                showViewDetail={false}
                            />
                            <DashboardCard
                                icon1={<Svgs.PendingPayments width={22} height={22} />}
                                className={'!p-0'}
                                customPadding={'p-0'}
                                hideCurrency={true}
                                headingClassName={'!flex-col-reverse'}
                                title1={'Billing Pending'}
                                amount1={data?.billing_pending}
                                showViewDetail={false}
                            />
                            <DashboardCard
                                icon1={<Svgs.Billed width={22} height={22} />}
                                className={'!p-0'}
                                customPadding={'p-0'}
                                hideCurrency={true}
                                headingClassName={'!flex-col-reverse'}
                                title1={'Billed'}
                                amount1={data?.billed_bookings}
                                showViewDetail={false}
                            />
                            <DashboardCard
                                icon1={<Svgs.PlatformCancelled width={22} height={22} />}
                                className={'!p-0'}
                                customPadding={'p-0'}
                                hideCurrency={true}
                                headingClassName={'!flex-col-reverse'}
                                title1={'Platform Cancelled'}
                                amount1={data?.platform_cancelled_bookings}
                                showViewDetail={false}
                            />
                            <DashboardCard
                                icon1={<Svgs.CancelPayment width={22} height={22} />}
                                className={'!p-0'}
                                customPadding={'p-0'}
                                hideCurrency={true}
                                headingClassName={'!flex-col-reverse'}
                                title1={'Concierge Cancelled'}
                                amount1={data?.concierge_cancelled_bookings}
                                showViewDetail={false}
                            />
                            <DashboardCard
                                icon1={<Svgs.RestaurantRejected width={22} height={22} />}
                                className={'!p-0'}
                                customPadding={'p-0'}
                                hideCurrency={true}
                                headingClassName={'!flex-col-reverse'}
                                title1={'Business Rejected'}
                                amount1={data?.restaurant_cancelled_bookings}
                                showViewDetail={false}
                            />
                        </>
                    }
                </div>

                <div className="py-3">
                    <div className="lg:col-span-8 space-y-5">
                        <div className="space-y-3">
                            <div className="relative sm:rounded-lg border border-lightGrey">
                                {loader
                                    ? <BookingTableShimer columns={7} />
                                    : <>
                                        <RestaurantAnalyticsTable data={data?.all_bookings} />
                                        {data?.all_bookings?.length > 0 &&
                                            <Pagination
                                                currentPage={currentPage}
                                                pageSize={paginationData?.per_page}
                                                totalCount={paginationData?.total_pages}
                                                onPageChange={(page) => setCurrentPage(page)}
                                                totalDataCount={paginationData?.total}
                                                currentPageCount={data?.length}
                                            />
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <BookingAnalyticsFilter
                bookingStatuses={RESTAURANT_BOOKING_STATUSES}
                open={addFilter}
                close={setAddFilter}
                hideSelection={true}
                onClearFilter={onClearFilter}
                onApplyFilter={onApplyFilter}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                filterData={filterData}
                setFilterData={setFilterData}
                dropdownOptions={dropdownOptions}
                handleChangeFilter={handleChangeFilter}
            />

            <CSVExportPopup
                open={downloadCSV}
                close={setDownloadCSV}
                onSubmit={getBookingAnalyticsCSVData}
                setData={setDownloadCSVData}
                data={downloadCSVData}
                loading={CSVLoader}
            />
        </DashboardLayout>
    )
}

export default RestaurantBookingAnalytics