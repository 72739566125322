import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import ProgressBar from 'Components/Elements/ProgressBar'
import React from 'react'

const EarningHistoryCard = ({ name, doneValue, earning, type, onClick, className }) => {
    return (
        <div className={`bg-lightGrey rounded-[20px] relative sm:px-4 px-2 sm:py-5 py-3 pt-11 ${className}`} onClick={onClick && onClick}>
            <div className='flex gap-2 justify-between items-center sm:p-4 p-2 bg-darkGrey rounded-[10px]'>
                <div className='flex gap-2 justify-between items-center w-full'>
                    <Svgs.UserIcon2 width={'50'} height={'50'} className={'min-w-10'} />
                    <div className="flex items-end sm:gap-10 gap-3 justify-between flex-1">
                        <div className='flex flex-col space-y-1 pl-2'>
                            <p className='text-white'>{name}</p>
                            <ProgressBar total={10} customWidth={'w-32 sm:w-44'} doneValue={doneValue} />
                        </div>
                        <div className='text-primary font-semibold min-w-20 rounded-[10px] bg-secondary flex items-center justify-center text-xs py-2 px-1'>
                            {earning} {currency}
                        </div>
                    </div>
                </div>
            </div>
            <div className='absolute right-0 top-0 px-5 py-2 text-sm font-bold rounded-tr-[20px] rounded-bl-[20px]  bg-secondary'>
                {type}
            </div>
        </div>
    )
}

export default EarningHistoryCard