import DashboardLayout from 'Components/DashboardLayout'
import Back from 'Components/Elements/Back'
import React from 'react'
import useTotalReferralCommissionHelper from './helper'
import EarningHistoryCard from '../InviteFriend/Elements/EarningHistoryCard'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import InfiniteScroll from 'react-infinite-scroll-component'
import NotFound from 'Utility/NotFound'
import DateRangePicker from 'Components/Elements/ReactDateRange'
import BookingsPopup from './Elements/BookingsPopup'

const TotalReferralCommission = () => {
    const { loading, history, setCurrentPage, currentPage, pagination_data, secondLoader, startDate, endDate, setStartDate, setEndDate,
        openBookings, setOpenBookings
    } = useTotalReferralCommissionHelper()
    return (
        <DashboardLayout active={'TotalReferralCommission'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen max-h-screen overflow-y-auto">
                <div className="pt-4 flex justify-between items-center">
                    <Back
                        title={'Total Referral Commission'}
                    />
                    <DateRangePicker
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        maxDate={new Date()}
                        className={'!w-full'}
                    />
                </div>
                <div className="py-6">
                    {loading ?
                        <div className="flex flex-col space-y-4">
                            <div className="!m-0 grid lg:grid-cols-2 grid-cols-1 gap-4">
                                {[0, 1, 2, 3, 4]?.map(itm => {
                                    return <div className='bg-gray-500 animate-pulse rounded-md h-32'></div>
                                })}
                            </div>
                        </div>
                        :
                        <div className='flex flex-col space-y-4'>
                            {history?.length > 0 ?
                                <InfiniteScroll
                                    dataLength={history?.length ? history?.length : 10} //This is important field to render the next data
                                    next={() => {
                                        setCurrentPage(currentPage + 1)
                                    }}
                                    hasMore={pagination_data && pagination_data?.current_page !== pagination_data?.total_pages}
                                    loader={secondLoader
                                        ? <div className="flex justify-center items-center my-4">
                                            <PreloaderSm />
                                        </div> : ""
                                    }
                                >
                                    <div className="!m-0 grid lg:grid-cols-2 grid-cols-1 gap-4">
                                        {history?.map((itm, index) =>
                                            <EarningHistoryCard
                                                name={`${itm?.user?.first_name ? itm?.user?.first_name : ""} ${itm?.user?.last_name ? itm?.user?.last_name : ""}`}
                                                doneValue={itm?.reservation_count}
                                                earning={itm?.earning}
                                                type={itm?.user?.restaurant ? 'Business' : "Concierge"}
                                                onClick={() => { setOpenBookings(itm) }}
                                                className={"cursor-pointer"}
                                            />
                                        )}
                                    </div>
                                </InfiniteScroll>
                                : <NotFound />
                            }
                        </div>
                    }
                </div>
            </main>

            <BookingsPopup
                open={openBookings && openBookings?.id}
                close={setOpenBookings}
                data={openBookings?.reservations}
            // onBookingClick={(data) => { navigate(`/concierge/booking?reservation=${data?.number}`) }}
            />

        </DashboardLayout>
    )
}

export default TotalReferralCommission