import React from 'react'
import Popup from 'Components/Elements/Popup'
import LoginInput from 'Components/Elements/LoginInput'
import { copyToClipBoard } from 'Constants/Functions/CopyToClipBoard'

const PhoneNumberPopup = ({ open, close, data }) => {
    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            heading={'Mobile Number'}
        >
            <div className='flex gap-2 items-center py-4'>
                <LoginInput parentClass={'flex-1'} value={data ? data : ""} disabled={true} title={''} placeholder={'Mobile Number'} />
                <div className='px-4 py-2 bg-secondary text-primary font-semibold flex gap-2 items-center rounded-md cursor-pointer' onClick={() => { copyToClipBoard(data, 'Copied to clipboard successfully') }}>
                    Copy
                </div>
            </div>
        </Popup>
    )
}

export default PhoneNumberPopup