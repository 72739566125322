import Cookies from "js-cookie"
import { clearStore } from "Utility/indexedDBUtils";

// import React from "react";
export const logoutfunction = async () => {
    // Store userLocation temporarily
    const userLocation = localStorage.getItem('userLocation');

    localStorage.removeItem('user_data')
    localStorage.removeItem('access_token')
    localStorage.clear()

    // Set userLocation back in localStorage
    if (userLocation) {
        localStorage.setItem('userLocation', userLocation);
    }

    // Remove all cookies
    const allCookies = Cookies.get(); // Get all cookies
    Object.keys(allCookies).forEach((cookieName) => {
        Cookies.remove(cookieName); // Remove each cookie
    });

    // Optionally clear all IndexedDB data (if needed)
    await clearStore();

    window.location.href = "/login"
}
