
import { getRestaurantPayments, getSubScriptionDetails } from "Adapters/APIs/Restaurant/Payment";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const usePaymentsHistory = (subscription, data) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage("user_data")

    // states
    const [loading, setLoading] = useState(true)
    const [payment, setPaymentDetail] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [pagination, setPagination] = useState()
    const [endDate, setEndDate] = useState();
    const [startDate, setStartDate] = useState();
    const [showProof, setShowProof] = useState(false);
    const [openDetails, setOpenDetails] = useState(false)
    const [bookingDetails, setBookingDetails] = useState(false)
    const [cookieValue, setCookieValue] = useState(Cookies.get('paymentApiCall'));

    // get payments  data
    const getpayment = (noLoader) => {
        const success = (data) => {
            let update_request;
            if (data?.pagination_data?.meta?.current_page !== 1) {
                if (data?.transactions?.length > 0) {
                    update_request = [...payment, ...data?.transactions];
                }
                else {
                    update_request = []
                }
            } else {
                update_request = data?.transactions;
            }
            setPaymentDetail(update_request)
            setPagination(data?.pagination_data?.meta)
            setLoading(false)
        }
        const fail = () => {
            setPaymentDetail([])
            setLoading(false)
        }
        const payload = {
            page: currentPage,
            end_date: endDate,
            start_date: startDate,
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : ""
        }

        subscription
            ? dispatch(getSubScriptionDetails(access_token, payload, success, fail))
            : dispatch(getRestaurantPayments(access_token, payload, success, fail))
    }
    useEffect(() => {
        if (currentPage == 1) {
            setLoading(true)
        }
        getpayment()
    }, [currentPage, endDate, startDate])

    useEffect(() => {
        if (data) {
            setPaymentDetail(data)
        }
    }, [data])

    useEffect(() => {
        setCurrentPage(1)
    }, [endDate, startDate])

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newCookieValue = Cookies.get('paymentApiCall');
            if (newCookieValue !== cookieValue) {
                setCookieValue(newCookieValue);
            }
        }, 1000); // Check every second

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [cookieValue]);

    useEffect(() => {
        if (cookieValue) {
            setLoading(true)
            getpayment();
            setCurrentPage(1)
            Cookies.remove('paymentApiCall');
        }
    }, [cookieValue]);

    return {
        navigate, loading, payment, setCurrentPage, currentPage,
        endDate, startDate, setEndDate, setStartDate, openDetails, setOpenDetails,
        showProof, setShowProof, pagination, bookingDetails, setBookingDetails,
    }
}

export default usePaymentsHistory