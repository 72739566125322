import MobileVerifyInput from 'Components/Elements/MobileVerifyInput';
import React from 'react'
import useOTPInputHelper from './helper';
import moment from 'moment';
import Button from 'Components/Elements/Button';
import Svgs from 'Assets/svgs';
// import { maskEmail } from 'Constants/Functions/maskEmail';

const OTPInput = ({ email, onResendClick, resendPassswordOTP, onVerifyOtp, loading, showBackBtn, onBackClick, descriptionText, addBusiness,
    isReset }) => {
    const { userEmail, setOtp, onChangeHandler, inp_1, inp_2, inp_3, inp_4, count, isFinished, otp, startTimer, initialState }
        = useOTPInputHelper({ addBusiness, email, isReset })
    return (
        <div className="px-2 flex  w-full">
            <div className="gap-5 m-auto w-fit flex flex-col">
                <div className="gap-1 flex flex-col">
                    {showBackBtn &&
                        <div className="py-2 flex items-center gap-3">
                            <Svgs.BackIcon className={'cursor-pointer'} fill={'var(--secondary-color)'} stroke={'var(--secondary-color)'} onClick={() => {
                                onBackClick && onBackClick()
                            }} />
                            <h1 className="font-bold text-[1.5rem] text-white">
                                OTP Verification
                            </h1>
                        </div>
                    }
                    <p className="text-white text-sm">
                        {descriptionText ? descriptionText : "If you can't find the verfication email in your inbox, please check your span or junk folder"}
                        <br />
                        <a
                            href={`mailto:${userEmail}`}
                            className="text-white text-sm mx-auto w-fit text-center mt-6 flex items-center justify-center"
                        >
                            {/* {maskEmail(userEmail || email)} */}
                            {userEmail || email}
                        </a>
                    </p>
                </div>
                <div className="flex items-center justify-center gap-3 mt-0">
                    <MobileVerifyInput
                        name={"num1"}
                        value={otp.num1}
                        inp_ref={inp_1}
                        onChange={onChangeHandler}
                    />
                    <MobileVerifyInput
                        name={"num2"}
                        inp_ref={inp_2}
                        value={otp.num2}
                        onBackSpace={() => {
                            if (otp.num2 == "" && inp_1 && inp_1.current) {
                                inp_1.current.focus();
                            }
                        }}
                        onChange={(e) => {
                            if (e.target.value == "") {
                                if (inp_1 && inp_1.current) {
                                    inp_1.current.focus();
                                }
                            }
                            onChangeHandler(e);
                        }}
                    />
                    <MobileVerifyInput
                        name={"num3"}
                        inp_ref={inp_3}
                        value={otp.num3}
                        onBackSpace={() => {
                            if (otp.num3 == "" && inp_2 && inp_2.current) {
                                inp_2.current.focus();
                            }
                        }}
                        onChange={(e) => {
                            if (e.target.value == "") {
                                if (inp_2 && inp_2.current) {
                                    inp_2.current.focus();
                                }
                            }
                            onChangeHandler(e);
                        }}
                    />
                    <MobileVerifyInput
                        name={"num4"}
                        inp_ref={inp_4}
                        value={otp.num4}
                        onBackSpace={() => {
                            if (otp.num4 == "" && inp_3 && inp_3.current) {
                                inp_3.current.focus();
                            }
                        }}
                        onChange={(e) => {
                            if (e.target.value == "") {
                                if (inp_3 && inp_3.current) {
                                    inp_3.current.focus();
                                }
                            }
                            onChangeHandler(e);
                        }}
                    />
                </div>
                {count > 0 && !isFinished ? (
                    <p className="text-white text-xs text-center">
                        You can resend code in
                        {" "}
                        <span className="text-secondary font-semibold">{moment.utc(count * 1000).format("mm:ss")}</span>{" "}
                    </p>
                ) : (
                    <p className="text-center text-xs text-white">
                        Didn't received the code?{" "}
                        <span
                            onClick={() => {
                                startTimer()
                                setOtp(initialState)
                                if (onResendClick) {
                                    onResendClick()
                                    resendPassswordOTP(1)
                                } else {
                                    resendPassswordOTP()
                                }
                            }}
                            className="underline cursor-pointer text-secondary"
                        >
                            Resend OTP
                        </span>{" "}
                    </p>
                )}
                <Button
                    disabled={
                        !Object.values(otp).every((x) => x !== "") ? true : false
                    }
                    onClick={() => {
                        inp_4.current.focus();
                        onVerifyOtp && onVerifyOtp(otp);
                    }}
                    loading={loading}
                    className={"mt-0 lg:mt-2"}
                >
                    Continue
                </Button>
            </div>
        </div>
    )
}

export default OTPInput