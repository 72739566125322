import React from 'react'
import NotFound from 'Utility/NotFound'
import AdminPromotionCard from 'Components/AdminPromotionCard'
import AdminPromotionCardShimmer from 'Utility/Shimmers/AdminPromotionCardShimmer'
import useRestaurantPromotions from './helper'
import PromotionDetailPopup from './Popups/PromotionDetail'
import DashboardLayout from 'Components/DashboardLayout'
import Back from 'Components/Elements/Back'
import ComingSoon from 'Components/Elements/ComingSoon'

const RestaurantPackagesPromotions = ({ promotionType, promotion_id, onSuccessCallBack }) => {
    const {
        navigate, loader, packages, type, setType, detail, setDetail,
        onChangeHandler, promotion, setPromotion, errors, submitLoading, onPayClick
    } = useRestaurantPromotions(promotionType, promotion_id, onSuccessCallBack)
    return (
        <DashboardLayout active={"boost"}>
            <main className={`p-4 ${!promotionType && 'lg:ml-72 md:ml-64 h-auto'} pt-20 min-h-screen overflow-y-auto`}>

                <div className="flex items-center gap-3 w-full justify-between">
                    <div className="pt-4 space-y-5 w-full">
                        <Back
                            title={'Boost Your Business'}
                        />
                        <div className="border-b flex w-full items-baseline gap-5 text-blueGrey">
                            <p onClick={() => setType('all')} className={`${type == 'all' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>All Packages  &nbsp;
                            </p>
                            <p onClick={() => setType('active')} className={`${type == 'active' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>Active Packages &nbsp;
                            </p>
                        </div>
                    </div>
                </div>
                <div className="py-6">
                    <div className="lg:col-span-8 space-y-5">
                        <div className="space-y-3">
                            {loader
                                ? <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 justify-between items-center">
                                    {[0, 1, 2, 3, 4]?.map(itm => {
                                        return <AdminPromotionCardShimmer />
                                    })}
                                </div>
                                : packages?.length > 0 ?
                                    <div className='grid grid-cols-1 xl:grid-cols-2 gap-4 justify-between items-center'>
                                        {packages?.map((itm, index) => {
                                            return <AdminPromotionCard
                                                index={index}
                                                id={itm?.id}
                                                name={itm?.name}
                                                duration={`${itm?.duration} Days`}
                                                status={itm?.status == 1 ? true : false}
                                                type={itm?.type}
                                                description={itm?.description}
                                                price={itm?.price}
                                                hideToggle={true}
                                                onCardClick={() => {
                                                    setDetail(itm)
                                                    setPromotion()
                                                }}
                                            />
                                        })}
                                    </div>
                                    : <NotFound />}
                        </div>
                    </div>
                </div>
                {detail &&
                    <PromotionDetailPopup
                        open={detail}
                        errors={errors}
                        close={setDetail}
                        title={detail?.name}
                        price={detail?.price}
                        description={detail?.description}
                        image={detail?.image}
                        duration={`${detail?.duration} Days`}
                        onChange={onChangeHandler}
                        promotion={promotion}
                        setPromotion={setPromotion}
                        submitLoading={submitLoading}
                        type={type}
                        onBtnClick={onPayClick}

                    />
                }
            </main>
        </DashboardLayout>
    )
}

export default RestaurantPackagesPromotions