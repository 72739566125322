import { getDashboardDataAPI } from "Adapters/APIs/Concierge/Details"
import { getBookingDetails, getDuePaymentDetails, payToSuperAdmin } from "Adapters/APIs/Restaurant/Payment"
import { objectKeyConvertToArray } from "Hooks/useObjectKeys"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import useValidations from "Hooks/useValidations"
import Cookies from "js-cookie"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const useRestaurantPayments = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const state = useSelector(state => state)
    const commissionDetails = state?.concierge?.dashbaord_data

    const { setErrors, errors, validation } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage("user_data")
    const defaultRoute = getDataFromLocalStorage("defaultRoute")

    // states
    const [paymentDetails, setPaymentDetails] = useState(false)
    const [payClicked, setPayClicked] = useState(false)
    const [bookingLoading, setBookingLoading] = useState(false)
    const [commissionLoading, setCommissionLoading] = useState(false)
    const [duePaymentLoader, setDuePaymentLoader] = useState(false)
    const [dueDetails, setDueDetails] = useState()
    const [openPopup, setOpenPopup] = useState(false)
    const [bookingData, setBookingData] = useState()
    const [paidProof, setPaidProof] = useState()
    const [payLoader, setPayLoader] = useState(false)
    const [endDate, setEndDate] = useState();
    const [startDate, setStartDate] = useState();

    // on proof change
    const onProofChangeHandler = (e) => {
        const { name, files } = e?.target
        setPaidProof(files[0])
        setErrors({ ...errors, [name]: '' })
    }

    // get commission data
    const getCommissionData = () => {
        const success = () => { setCommissionLoading(false) }
        const fail = () => { setCommissionLoading(false) }
        setCommissionLoading(true)
        dispatch(getDashboardDataAPI(access_token, { restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : "" }, success, fail))
    }

    // get due payments
    const getDuePayments = () => {
        const success = (data) => {
            setDuePaymentLoader(false)
            setDueDetails(data)
        }
        const fail = () => {
            setDuePaymentLoader(false)
        }
        setDuePaymentLoader(true)
        dispatch(getDuePaymentDetails(access_token, { restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : "" }, success, fail))
    }

    // get booking detail
    const getBookingDetail = (status) => {
        const success = (data) => {
            setBookingLoading(false)
            setBookingData(data)
        }
        const fail = () => {
            setBookingLoading(false)
        }
        setBookingLoading(true)
        dispatch(getBookingDetails(access_token, { type: status ? 1 : '', restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : "" }, success, fail))
    }

    // submit payment
    const onSubmitPayment = (successCallback) => {
        let payload = {
            paid_proof: paidProof,
            paid_amount: dueDetails?.vat_added_commission,
            reservationIds: dueDetails?.bookings?.map(itm => itm?.id),
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : ""
        }
        const success = (res) => {
            setPayClicked(false)
            setPayLoader(false)
            setPaymentDetails(false)
            getDuePayments()
            Cookies.set("paymentApiCall", true)
            successCallback && successCallback()
        }
        const fail = () => {
            setPayLoader(false)
        }
        let requiredFields = { paidProof: paidProof }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setPayLoader(true)
            dispatch(payToSuperAdmin(access_token, payload, success, fail))
        }
    }

    useEffect(() => {
        getCommissionData()
    }, [])

    useEffect(() => {
        getDuePayments()
    }, [endDate, startDate])


    return {
        commissionDetails, commissionLoading, duePaymentLoader, dueDetails,
        payClicked, setPayClicked, paidProof, payLoader, onProofChangeHandler,
        setPayLoader, onSubmitPayment, navigate, paymentDetails, setPaymentDetails,
        openPopup, setOpenPopup, bookingData, bookingLoading, getBookingDetail, errors,
        setPaidProof, endDate, setEndDate, startDate, setStartDate, defaultRoute
    }

}

export default useRestaurantPayments