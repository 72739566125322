import { postSignatureData, UpdateFCMToken, userLogin } from "Adapters/APIs/Authentication"
import { objectKeyConvertToArray } from "Hooks/useObjectKeys"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import useValidations from "Hooks/useValidations"
import { saveDefaultRoute } from "Utility/indexedDBUtils"
import { requestForToken } from "firebase.config"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useLocation } from "react-router"

const intialState = {
    email: '',
    password: '',
}
const useLogin = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { setErrors, errors, validation, emailValidation } = useValidations()
    const { storeDataInLocalStorage, storeDataInSessionStorage, removeDataFromSessionStorage, getDataFromLocalStorage } = useSetDataInLocalStorage()

    // states
    const [userData, setUserData] = useState(intialState)
    const [loading, setLoading] = useState(false)
    const [recoverPopup, setRecoverPopup] = useState(false)
    const [userProfileData, setUserProfileData] = useState()
    const [termsSignaturePopup, setTermsSignaturePopup] = useState(false)
    const [termStep, setTermStep] = useState(1)
    const [signatureValue, setSignatureValue] = useState("")
    const [signatureLoading, setSignatureLoading] = useState(false)

    // on change handle function
    const onChangeHandler = (e) => {
        const { name, value } = e?.target
        if (name == 'email') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            emailValidation(name, value);
            setUserData({
                ...userData,
                [name]: value
            })
        }

        if (name == 'password') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            setUserData({
                ...userData,
                [name]: value
            })
        }
    }

    // user login function
    const userLoginFunction = (data) => {
        storeDataInLocalStorage('access_token', data?.token ? data?.token : getDataFromLocalStorage('access_token'))
        storeDataInLocalStorage('user_data', data?.user)

        switch (data?.user?.role) {
            case 'Super Admin':
                storeDataInLocalStorage('defaultRoute', '/admin/dashboard')
                storeDataInLocalStorage('defaultRouteParent', '/admin')
                saveDefaultRoute('admin');
                if (!location.pathname.startsWith('/admin/')) {
                    navigate('/admin/dashboard');
                }
                break;
            case 'User':
                if (data?.user?.is_approved == 2) {
                    storeDataInLocalStorage('defaultRoute', '/concierge/edit-profile')
                    storeDataInLocalStorage('defaultRouteParent', '/concierge')
                    navigate('/concierge/edit-profile')
                } else {
                    storeDataInLocalStorage('defaultRoute', '/concierge/dashboard')
                    storeDataInLocalStorage('defaultRouteParent', '/concierge')
                    saveDefaultRoute('concierge');
                    if (!location.pathname.startsWith('/concierge/')) {
                        navigate('/concierge/dashboard');
                    }
                }
                break;
            case 'Admin':
                if (data?.user?.restaurant?.is_approved == 2) {
                    storeDataInLocalStorage('defaultRoute', '/restaurant/edit-profile')
                    storeDataInLocalStorage('defaultRouteParent', '/restaurant')
                    navigate('/restaurant/edit-profile')
                } else {
                    storeDataInLocalStorage('defaultRoute', '/restaurant/dashboard')
                    storeDataInLocalStorage('defaultRouteParent', '/restaurant')
                    saveDefaultRoute('restaurant');
                    if (!location.pathname.startsWith('/restaurant/')) {
                        if (data?.user?.restaurant?.is_new == 1) {
                            navigate('/restaurant/profile?activeTab=profile');
                        } else {
                            navigate('/restaurant/dashboard');
                        }
                    }
                }
                break;

            default:
                break;
        }
    }

    // login success callback function
    const successCallback = (data, noCallForToken) => {
        setUserProfileData(data)
        if (noCallForToken) {
            userLoginFunction(data)
        } else {
            if (data?.user?.restaurant?.is_approved == 2) {
                storeDataInLocalStorage('access_token', data?.token ? data?.token : getDataFromLocalStorage('access_token'))
                storeDataInLocalStorage('user_data', data?.user)
                storeDataInLocalStorage('defaultRoute', '/restaurant/edit-profile')
                storeDataInLocalStorage('defaultRouteParent', '/restaurant')
                navigate('/restaurant/edit-profile')
            }
            // else if (data?.user?.restaurant && (!data?.user?.restaurant?.terms_accepted || !data?.user?.restaurant?.signature)) {
            //     setTermsSignaturePopup(true)
            // } 
            else {
                userLoginFunction(data)
                !noCallForToken && requestFireBaseToken(userData?.token)
            }
        }

        setLoading(false)
    }
    // login fail callback function
    const failCallback = (error) => {
        setLoading(false)
        if (error?.response?.status == 404 && error?.response?.data?.error != "User doesn't exists with this account.") {
            storeDataInSessionStorage('signupData', { email: userData?.email })
            navigate('/otp-verification')
        }
        if (error?.response?.status == 405) {
            setRecoverPopup(error?.response?.data?.message)
        }
    }

    // on submit function
    const onHandleSubmit = (otherData) => {
        const { email, password } = userData
        setErrors(validation({ email, password }));
        let result = validation({ email, password });
        if (objectKeyConvertToArray(result)?.length === 0) {
            setLoading(true)

            let payload = {
                ...userData,
                is_web: true
            }
            if (otherData && objectKeyConvertToArray(otherData)?.length > 0) {
                payload = {
                    ...payload,
                    ...otherData
                }
            }
            dispatch(userLogin(payload, successCallback, failCallback))
        }
    }

    // Add FCM Token
    const add_FCM_Tokens = (token, access_token) => {
        dispatch(UpdateFCMToken(access_token, {
            fcm_token: token,
            is_web: true
        }))
    }

    // Get FCM Token
    const requestFireBaseToken = async (access_token) => {
        const success = (token) => {
            storeDataInLocalStorage('fcm_token', token)
            add_FCM_Tokens(token, access_token)
        }
        requestForToken(success)
    }

    // handle submit signature data
    const handleSubmitSigntureData = () => {
        const success = () => {
            setSignatureLoading(false)
            userLoginFunction(userProfileData)
        }
        const fail = () => {
            setSignatureLoading(false)
        }
        setErrors(validation({ signatureValue }));
        let result = validation({ signatureValue });
        if (objectKeyConvertToArray(result)?.length === 0) {
            setSignatureLoading(true)
            dispatch(postSignatureData(userProfileData?.token, {
                user_signature: signatureValue,
                //  restaurant_id: userProfileData?.user?.restaurant?.id 
            }, success, fail))
        }
    }

    useEffect(() => {
        // remove signup data if there is any in session
        removeDataFromSessionStorage('signupData')
    }, [])
    return {
        errors, userData, loading, onChangeHandler, navigate, onHandleSubmit, recoverPopup, setRecoverPopup, setLoading, successCallback,
        failCallback, termsSignaturePopup, setTermsSignaturePopup, termStep, setTermStep, signatureValue, setSignatureValue,
        handleSubmitSigntureData, signatureLoading
    }
}

export default useLogin