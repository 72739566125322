import React from 'react'
import Svgs from '../../Assets/svgs'
import Button from 'Components/Elements/Button'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'

const ConceirgeCards = ({ startDate, endDate, title1, hideSeprater, title2, btnText, hideBtn, showDetails,className, themeBtn, onDetailClick, unpaid, showDetailBtn, amount1, amount2, heading, headingAmount, bgColor, btnColor, svgIcon, currency, onAllClick }) => {
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const user_data = getDataFromLocalStorage('user_data')
    return (
        <>
            <div className={`bg-darkGrey relative rounded-lg md:rounded-[10px] flex items-center justify-between p-5 ${className ? className : ''}`}>
                {!showDetailBtn && !hideBtn &&
                    <div className={`bg-lightGrey absolute -top-5 left-1/2 transform -translate-x-1/2 rounded-full h-10 w-10 min-w-10 flex justify-center items-center`}>
                        {svgIcon ? svgIcon : <Svgs.WalletIcon />}
                    </div>
                }

                <div className="flex flex-col gap-3 items-center justify-between h-full w-full">
                    {showDetails &&
                        <div className="flex flex-col w-full max-w-xl gap-3">
                            <h3 className='text-secondary font-semibold'>Due Payment Details:</h3>
                            <div className="flex px-5 py-2 justify-between items-center bg-lightGrey rounded-[10px]">
                                <h3 className='text-[#F1F1F1] font-semibold sm:text-lg'>Unpaid Booking</h3>
                                <b className='text-secondary bg-black rounded-full min-w-9 min-h-9 flex items-center justify-center p-1'>{unpaid ? unpaid : 0}</b>
                            </div>
                        </div>
                    }
                    <div className='flex flex-col text-blueGrey items-center w-11/12 mx-auto gap-1 py-4'>
                        <h4 className="font-semibold text-lg text-secondary">{headingAmount} {headingAmount && currency}</h4>
                        <p className="text-[#F1F1F1] font-semibold sm:text-lg">{heading}</p>
                    </div>

                    <div className="flex justify-between w-full text-blueGrey gap-2 border-y border-table py-5">
                        <div className='flex flex-col items-center w-11/12 mx-auto gap-1'>
                            <h4 className="font-semibold text-xl text-secondary">{amount1} {currency}</h4>
                            <p className="text-base text-center">{title1}</p>
                        </div>
                        {!hideSeprater && <div className='bg-lightGrey w-0.5' />}
                        {(title2 || amount2) &&
                            <div className='flex flex-col items-center w-11/12 mx-auto gap-1'>
                                <h4 className="font-semibold text-xl text-secondary">{amount2} {currency}</h4>
                                <p className="text-base text-center">{title2}</p>
                            </div>
                        }
                    </div>

                    {showDetailBtn
                        ?
                        unpaid != 0 &&
                    <div className="flex xl:flex-row lg:flex-col md:flex-row flex-col mt-5 items-center justify-between max-w-xl w-full lg:gap-2 gap-5 xl:gap-5">
                        <Button customThemeBtn={'view-edit-btn'} className={'w-full text-secondary font-bold text-lg bg-black border-secondary border'}
                            onClick={() => {
                                if (user_data?.restaurant?.id) {
                                    window.open(`${process.env.REACT_APP_BACKEND_BASEURL}pdf/payment/?restaurant_id=${user_data?.restaurant?.id}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`, '_blank')
                                }
                            }
                            }
                        >
                            View Invoice
                        </Button>
                        <Button onClick={onDetailClick && onDetailClick} className={'w-full'} >
                            Pay commission to platform
                        </Button>
                    </div>
                        :
                        <Button
                            onClick={onAllClick && onAllClick}
                            title={btnText
                                ? btnText
                                : "View all"
                            }
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default ConceirgeCards