import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import MainRoutes from './Routes';
import NoInternetPage from 'Components/NoInternetPage';
import useAppHelper from 'useAppHelper';
import ConfirmationPopup from 'Components/ConfirmationPopup/ConfirmationPopup';
import NotificationFCMHandler from 'Utility/NotifcationFCMHandler';

function App() {
  const { isInternetWorking, isOnline, screenWidth, switchProfile, switchLoader, handleYesClick,
    handleNoClick
  } = useAppHelper()

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
        theme='colored'
        style={{
          width: screenWidth >= 629 ? "600" : screenWidth >= 481 ? '95%' : "auto"
        }}
      />
      {(isOnline && isInternetWorking) ? <MainRoutes /> : <NoInternetPage />}
      <NotificationFCMHandler />

      <ConfirmationPopup
        open={switchProfile}
        close={handleNoClick}
        title={<p>Are you sure you want to switch <br /> business?</p>}
        onYesClick={handleYesClick}
        yesLoader={switchLoader}
        removeHeader={true}
      />
    </>
  );
}

export default App;