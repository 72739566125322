import Button from 'Components/Elements/Button'
import FilePicker from 'Components/Elements/FilePicker'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import React from 'react'
import PhoneInput from 'Components/Elements/PhoneInput'
import useCreateWishlist from './helper'

const CreateWishList = ({ open, close, onSuccess, isUpdate, setIsUpdate }) => {
    const {
        onChangeHandler, request, loading, onSubmit, errors, onClose
    } = useCreateWishlist(close, onSuccess, isUpdate, setIsUpdate)
    return (
        <>
            <Popup
                open={open}
                close={() => {
                    onClose()
                }}
                heading={isUpdate ? "Update Wishlist" : 'Add Wishlist'}
            >
                <div className="flex flex-col gap-5">
                    <LoginInput
                        error={errors?.name}
                        value={request?.name}
                        title={'Business Name'}
                        name={'name'}
                        onChange={onChangeHandler}
                        placeholder={'Enter business name'}
                        required={true}
                    />
                    <LoginInput
                        error={errors?.city}
                        value={request?.city}
                        title={'City'}
                        name={'city'}
                        onChange={onChangeHandler}
                        placeholder={'Enter City'}
                        required={true}
                    />
                    <LoginInput
                        error={errors?.address}
                        value={request?.address}
                        title={'Address'}
                        name={'address'}
                        required={false}
                        onChange={onChangeHandler}
                        placeholder={'Enter address'}
                    />
                    <LoginInput
                        error={errors?.website_link}
                        value={request?.website_link}
                        title={'Website'}
                        required={false}
                        name={'website_link'}
                        onChange={onChangeHandler}
                        placeholder={'Enter website'}
                    />
                    <PhoneInput
                        title={'Phone Number'}
                        value={request?.phone_number}
                        error={errors?.phone_number}
                        onChange={onChangeHandler}
                        name={'phone_number'}
                        required={false}
                        edit={isUpdate?.id}
                        placeholder={"Enter your Phone Number"}
                    />
                    <LoginInput
                        error={errors?.map_link}
                        value={request?.map_link}
                        title={'Map Link'}
                        name={'map_link'}
                        required={false}
                        onChange={onChangeHandler}
                        placeholder={'Enter map link'}
                    />
                    <FilePicker
                        error={errors?.image}
                        value={request?.image}
                        name={'image'}
                        onChange={onChangeHandler}
                        title={"Upload Image"}
                        required={false}
                        accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG"}
                    />
                    <Button
                        onClick={onSubmit}
                        loading={loading}
                    >
                        Submit
                    </Button>
                </div>
            </Popup>
        </>
    )
}

export default CreateWishList