import Svgs from 'Assets/svgs'
import ActivitLogsCard from 'Components/ActivitLogsCard'
import Button from 'Components/Elements/Button'
import Dropdown from 'Components/Elements/Dropdown'
import Image from 'Components/Elements/Image'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import Textarea from 'Components/Elements/TextArea'
import ToggleWithText from 'Components/Elements/ToggleWithText'
import { SupportRequestUpdateStatus } from 'Constants/Data/Categories'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import NotFound from 'Utility/NotFound'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

const RequestCard = (props) => {
    const { id, number, logs, status, title, category, date, user, note, isDetail, onCardClick, image, threads, btnLoading, onSubmit, loadingThread,
        adminWishlist, address, assignedUser, isConcierge, is_active, onToggleClick, onEditClick, updateStatus, updateStatusValue,
        setUpdateStatusValue, supportRequestCard, onClickUpdateStatus, updateStatusLoader, addedBy, allowEdit = true, phoneNumber
    } = props

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const userData = getDataFromLocalStorage('user_data')

    const [comment, setComment] = useState('')
    const [error, setError] = useState('')
    const [type, setType] = useState('chat')

    useEffect(() => {
        if (!btnLoading)
            setComment('')
    }, [btnLoading])

    return (
        <div className='flex flex-col gap-4'>
            <div
                className={`${onCardClick && 'cursor-pointer'} flex flex-col sm:rounded-[20px] rounded-lg p-3 sm:p-5 h-full gap-3 ${supportRequestCard ? 'bg-lightGrey' : 'bg-darkGrey'}`}
                onClick={() => {
                    onCardClick && onCardClick()
                }}
            >
                {isConcierge &&
                    <div className="flex">
                        <Image src={image} className='h-48 object-cover w-full sm:rounded-[20px] rounded-lg' showDummy />
                    </div>
                }
                <div className="flex justify-between items-center">
                    <div class={`font-semibold text-secondary mb-1 ${supportRequestCard && 'text-lg'}`}><span className='text-white'>{supportRequestCard ? 'Request' : ''} ID:</span> {number}</div>
                    {!adminWishlist && !isConcierge &&
                        <button className={`${status?.toLowerCase()} flex items-center gap-1 px-4 py-1.5 rounded-lg `}>
                            {status}
                        </button>
                    }
                    {(isConcierge && allowEdit) &&
                        <div className="flex gap-3">
                            <ToggleWithText checked={is_active} onChange={(e) => {
                                e.stopPropagation();
                                onToggleClick(id)
                            }} />
                            <div className='flex border p-1.5 rounded-full border-[#4E4E4E]'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onEditClick && onEditClick()
                                }}
                            >
                                <Svgs.EditIconV2 fill={'var(--secondary-color)'} height={14} width={14} />
                            </div>
                        </div>
                    }
                </div>
                <div className={`flex flex-col gap-3  ${supportRequestCard && 'bg-darkGrey sm:p-5 p-2 rounded-[10px]'}`}>
                    <p class="text-white font-semibold sm:text-lg">{title}</p>
                    <div className="flex gap-3 text-blueGrey lg:flex-row flex-col justify-between lg:items-center">
                        {!adminWishlist &&
                            <div className={`flex gap-1 items-center ${supportRequestCard && 'bg-primary px-4 py-2 rounded-[10px] gap-2'}`}>
                                <div><Svgs.ReportIconV2 stroke={'var(--secondary-color)'} /> </div>
                                {isDetail && <span>Suggestions:</span>}
                                <span className={`${supportRequestCard ? 'text-secondary font-bold' : 'text-blueGrey'} ${isDetail ? 'font-semibold' : ''}`} >{category}</span>
                            </div>
                        }

                        <div className={`flex gap-2 items-center whitespace-nowrap ${supportRequestCard && 'bg-primary px-4 py-2 rounded-[10px] gap-2'}`}>
                            <div><Svgs.CalendarV2 fill={'var(--secondary-color)'} /> </div>
                            {isDetail && <span> Date:</span>}
                            <span className={`${supportRequestCard ? 'text-secondary font-bold' : 'text-blueGrey text-sm'} ${isDetail ? 'font-semibold' : ''}`} >{moment(date).format('MMM, DD YYYY')}</span>
                        </div>
                    </div>
                    {adminWishlist && address ?
                        <>
                            <div className="flex gap-2 items-center">
                                <div><Svgs.LocationIconV2 stroke={'var(--secondary-color)'} width={20} height={22} /></div>
                                <span className='text-blueGrey text-sm'>{address}</span>
                            </div>

                        </> : ""}
                    {adminWishlist && phoneNumber ?
                        <div className="flex gap-2 items-center text-sm text-blueGrey">
                            <span><Svgs.PhoneIcon stroke={'var(--secondary-color)'} /></span>
                            <span className="text-sm">{phoneNumber}</span>
                        </div> : ""}
                    {adminWishlist && addedBy ?
                        <>
                            <div className="flex gap-2 items-center">
                                <div><Svgs.ProfileIconV2 stroke={'var(--secondary-color)'} /></div>
                                <span className='text-blueGrey text-sm'>Added By: {addedBy}</span>
                            </div>

                        </> : ""}
                    {adminWishlist && assignedUser ?
                        <>
                            <div className="flex gap-2 items-center">
                                <div><Svgs.ProfileIconV2 stroke={'var(--secondary-color)'} /></div>
                                <span className='text-blueGrey text-sm'>Assigned: {assignedUser}</span>
                            </div>

                        </> : ""}
                    {!adminWishlist ? isDetail ?
                        <div className='flex flex-col gap-4 text-blueGrey mt-2'>
                            <div className='flex flex-col gap-2 text-blueGrey'>
                                <h1 className='font-semibold' >Attachment</h1>
                                <Image src={image} className='h-40 object-cover w-full rounded-lg' showDummy={true} />
                                <div className='flex gap-2 bg-lightGrey items-center mt-2 p-3 rounded-xl shadow justify-between'>
                                    Requested By :
                                    <span className={`space-y-3 text-base font-semibold ${supportRequestCard && '!text-white'}`}>
                                        {user?.name
                                            ? user?.name
                                            : `
                                        ${user?.first_name ? user?.first_name : ""}
                                        ${user?.last_name ? user?.last_name : ""}
                                        `
                                        }
                                        {/* {`${user?.first_name ? user?.first_name : ""} ${user?.last_name ? user?.last_name : ""}`} */}
                                    </span>
                                </div>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <h1 className='font-semibold' >Notes</h1>
                                <p className='text-blueGrey'>{note}</p>
                            </div>
                            {(updateStatus && status != 'Resolved') &&
                                <div className='grid xs:grid-cols-3 grid-cols-1 gap-4'>
                                    <div className='col-span-2'>
                                        <Dropdown
                                            title={''}
                                            name={'status'}
                                            placeholder={'Select status'}
                                            onChange={(e) => { setUpdateStatusValue(e.target.value) }}
                                            value={updateStatusValue ? updateStatusValue : status?.toLowerCase()}
                                            option={SupportRequestUpdateStatus}
                                            bgColor={'var(--lightGrey-color)'}
                                        />
                                    </div>
                                    <Button
                                        title={'Update Status'}
                                        onClick={() => { onClickUpdateStatus && onClickUpdateStatus(id, updateStatusValue ? updateStatusValue : status?.toLowerCase()) }}
                                        loading={updateStatusLoader}
                                        disabled={updateStatusLoader}
                                        customPadding={'!p-2'}
                                    />
                                </div>
                            }
                        </div>
                        : <>
                            <hr className='border-lightGrey' />
                            <div className="text-blueGrey flex gap-2 items-center">
                                Requested By:
                                <span className={`space-y-3 text-base font-semibold ${supportRequestCard && '!text-white'}`}>
                                    {user?.restaurant?.name
                                        ? user?.restaurant?.name
                                        : `
                                        ${user?.first_name ? user?.first_name : ""}
                                        ${user?.last_name ? user?.last_name : ""}
                                        `
                                    }
                                </span>
                            </div>
                        </>
                        : ""
                    }
                </div>
            </div>
            {isDetail &&
                <>
                    <div className={`rounded-xl text-blueGrey ${supportRequestCard ? 'bg-lightGrey px-5 py-2 gap-5 flex items-center' : 'bg-darkGrey grid grid-cols-2 items-baseline'}`}>
                        <div
                            onClick={() => setType('chat')}
                            className={`${type == 'chat' ? 'bg-secondary text-primary rounded-l-xl' : ''} cursor-pointer font-semibold flex items-center justify-center ${supportRequestCard && type == 'chat' ? 'text-lg font-bold rounded-[10px] p-2 flex-1' : supportRequestCard ? 'text-lg font-bold rounded-[10px] p-2 flex-1 bg-black text-secondary border-secondary !border-x-[1.5px] border' : 'py-3 px-2'}`}>
                            <h1>Chat</h1>
                        </div>
                        {supportRequestCard ?
                            <div className="w-[0.5px] bg-darkGrey h-10"></div>
                            : ''}
                        <div
                            onClick={() => setType('logs')}
                            className={`${type == 'logs' ? (supportRequestCard ? 'bg-secondary rounded-r-xl text-black' : 'bg-secondary text-primary rounded-r-xl') : ''} cursor-pointer font-semibold flex items-center justify-center ${supportRequestCard && type == 'logs' ? 'text-lg font-bold rounded-[10px] p-2 flex-1 bg-black' : supportRequestCard ? 'text-lg font-bold rounded-[10px] p-2 flex-1 bg-black text-secondary border-secondary !border-x-[1.5px] border' : 'py-3 px-2'}`}>
                            <h1>Activity Logs</h1>
                        </div>
                    </div>

                    <div className="flex flex-col gap-4 text-blueGrey">
                        {/* Threads */}
                        {type == "chat"
                            ?
                            <>
                                {threads?.length > 0 ?
                                    <>
                                        <div className="flex flex-col gap-3">
                                            {threads?.map(itm => {
                                                const isActive = itm?.sender_id == userData?.id
                                                return (
                                                    <div className={`flex flex-col gap-1 rounded-b shadow justify-between ${!isActive ? "self-end " : "self-start"} `}>
                                                        <div className={`flex items-start ${!isActive ? "flex-row-reverse" : ""}`}>
                                                            <div className="bg-lightGrey rounded-full p-2 relative z-20">
                                                                <Svgs.ProfileAvitar className={''} />
                                                            </div>
                                                            <div className={`before:block before:absolute before:top-0 before:w-5 before:h-full before:bg-[#0E0E0E] relative bg-darkGrey rounded-lg p-2 text-base px-3 space-y-1 max-w-80 text-white ${!isActive ? "rounded-tr-none pr-7 before:right-[-8px] before:rounded-tl-full" : "self-start pl-7  before:left-[-8px] before:rounded-tr-full rounded-tl-none"}`}>
                                                                <p>{itm?.message}</p>
                                                                <p className='text-xs text-right'>
                                                                    By: {
                                                                        isActive
                                                                            ? itm?.restaurant?.name || itm?.sender?.first_name
                                                                            : `${itm?.sender?.first_name} ${itm?.sender?.last_name || ""}`
                                                                    }
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            {loadingThread &&
                                                <div className="flex justify-start my-5">
                                                    <PreloaderSm />
                                                </div>
                                            }
                                        </div>
                                    </>
                                    : <NotFound />
                                }
                                <div className="flex items-center justify-between gap-2 bg-darkGrey p-2 pr-4 rounded-md">
                                    {status == 'Resolved'
                                        ? ""
                                        : <div className="flex flex-col gap-3 flex-1">
                                            <Textarea
                                                placeholder={'Type a Message here...'}
                                                rows={1}
                                                textAreaClass={'bg-darkGrey !border-none'}
                                                value={comment}
                                                error={error}
                                                onChange={(e) => {
                                                    setError('')
                                                    setComment(e?.target?.value)
                                                }}
                                                counter={false}
                                            />
                                        </div>
                                    }

                                    <Button
                                        loading={btnLoading}
                                        onClick={() => {
                                            if (status == 'Resolved') {
                                                onSubmit && onSubmit(id, comment, status)
                                            } else {
                                                if (comment)
                                                    onSubmit && onSubmit(id, comment)
                                                else
                                                    setError("This field is required")
                                            }
                                        }}
                                        customThemeBtn={`${status == 'Resolved' ? "w-full" : "bg-lightGrey"} theme-btn bg-flex justify-center items-center `} customPadding={'p-3  gap-3'}>
                                        {status == 'Resolved'
                                            ? "Re-open"
                                            : <Svgs.SendMessage />
                                        }
                                    </Button>
                                </div>
                            </>
                            // Activity logs
                            : type == "logs"
                                ? logs?.length > 0
                                    ? <>
                                        <div className="flex flex-col gap-3">
                                            {logs?.map(itm => {
                                                return (
                                                    <ActivitLogsCard
                                                        data={itm}
                                                    />
                                                )
                                            })}
                                            {loadingThread &&
                                                <div className="flex justify-end my-5">
                                                    <PreloaderSm />
                                                </div>
                                            }
                                        </div>
                                    </>
                                    : <NotFound />
                                : ""
                        }
                    </div>
                </>
            }
        </div>
    )
}

export default RequestCard