import { getAdminSingleRestaurantFeedbackData } from "Adapters/APIs/Admin/restaurant";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useSingleRestaurantFeedback = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')

    // states
    const [loader, setLoader] = useState(true)
    const [secondLoader, setSecondLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [feedbackData, setFeedbackData] = useState([])
    const [paginationData, setPaginationData] = useState({})

    // get restaurant feedback
    const getRestaurantFeedbacks = () => {
        let payload = {
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : "",
            page: currentPage
        }
        if (currentPage == 1) {
            setLoader(true)
        } else {
            setSecondLoader(true)
        }
        const success = (res) => {
            let updatedData = []
            if (res?.feedbacks?.length == 0) {
                updatedData = []
            } else {
                if (res?.pagination_data?.meta?.current_page !== 1) {
                    updatedData = [...feedbackData || [], ...res?.feedbacks];
                } else {
                    updatedData = res?.feedbacks;
                }
            }
            setPaginationData(res?.pagination_data?.meta)
            setFeedbackData(updatedData)
            setLoader(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoader(false)
            setSecondLoader(false)
        }
        dispatch(getAdminSingleRestaurantFeedbackData(access_token, payload, success, fail))
    }

    useEffect(() => {
        setLoader(true)
        getRestaurantFeedbacks()
    }, [currentPage])

    return {
        navigate, feedbackData, paginationData, loader, secondLoader, currentPage, setCurrentPage
    }
}

export default useSingleRestaurantFeedback