import { bookAvailableSlotBoosting, getAllAvailableSlots, getCheckAvailableSlots, getProfileBoostigSettingData } from "Adapters/APIs/Restaurant/ProfileBoosting";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSlots } from "../../../Redux/Restaurant/ProfileBoosting/Actions";
import useValidations from "Hooks/useValidations";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import { useNavigate } from "react-router-dom";

const useProfileBoosting = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { setErrors, errors, validation } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')
    const defaultRoute = getDataFromLocalStorage('defaultRoute')

    // redux states
    const state = useSelector(state => state)
    const slotsData = state?.restaurantProfileBoosting?.slots

    // states
    const [endDate, setEndDate] = useState();
    const [startDate, setStartDate] = useState();
    const [loader, setLoader] = useState(true)
    const [slotsLoader, setSlotsLoader] = useState(true)
    const [slotsAvailabilityLoader, setSlotsAvailabilityLoader] = useState(true)
    const [postSlotLoader, setPostSlotLoader] = useState(false)
    const [settingData, setSettingData] = useState()
    const [selectedSlot, setSelectedSlot] = useState()
    const [estimatedBill, setEstimatedBill] = useState()
    const [proof, setProof] = useState()
    const [onPayClick, setOnPayClick] = useState(false)

    useEffect(() => {
        getSettingData()
    }, [])

    useEffect(() => {
        setSelectedSlot()
        if (startDate && endDate) {
            getAvailableSlots()
        } else {
            setTimeout(() => {
                setSlotsLoader(false)
            }, 500)
            dispatch(getAllSlots([]))
        }
    }, [startDate, endDate])


    useEffect(() => {
        if (startDate && endDate) {
            checkSlotsAvailability()
        } else {
            setTimeout(() => {
                setSlotsAvailabilityLoader(false)
            }, 500)
            setEstimatedBill()
        }
    }, [startDate, endDate, selectedSlot])

    // get available slots data
    const getAvailableSlots = () => {
        const success = (data) => {
            setSlotsLoader(false)
        }
        const fail = () => {
            setSlotsLoader(false)
        }
        let payload = {
            start_date: startDate,
            end_date: endDate,
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : ""
        }
        setSlotsLoader(true)
        dispatch(getAllAvailableSlots(access_token, payload, success, fail))
    }

    // get check slot availability
    const checkSlotsAvailability = () => {
        const success = (data) => {
            setEstimatedBill(data)
            setSlotsAvailabilityLoader(false)
        }
        const fail = () => {
            setSlotsAvailabilityLoader(false)
        }
        let payload = {
            start_date: startDate,
            end_date: endDate,
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : "",
            slot_id: selectedSlot ? selectedSlot : ""
        }
        setSlotsAvailabilityLoader(true)
        dispatch(getCheckAvailableSlots(access_token, payload, success, fail))
    }

    // get settings data
    const getSettingData = () => {
        const success = (data) => {
            setSettingData(data)
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        setLoader(true)
        dispatch(getProfileBoostigSettingData(access_token, '', success, fail))
    }

    // post available slot
    const postAvailableSlotData = () => {
        const success = () => {
            setPostSlotLoader(false)
            setOnPayClick(false)
            setProof()
            navigate(defaultRoute)
        }
        const fail = () => {
            setPostSlotLoader(false)
        }
        let payload = {
            start_date: startDate,
            end_date: endDate,
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : "",
            slot_id: selectedSlot ? selectedSlot : "",
            proof: proof
        }
        let requiredFields = { proof: proof }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setPostSlotLoader(true)
            dispatch(bookAvailableSlotBoosting(access_token, payload, success, fail))
        }
    }


    return {
        endDate, setEndDate, startDate, setStartDate, loader, settingData, slotsData, slotsLoader, selectedSlot, setSelectedSlot,
        slotsAvailabilityLoader, estimatedBill, proof, setProof, postAvailableSlotData, onPayClick, setOnPayClick, errors, setErrors,
        postSlotLoader
    }
}

export default useProfileBoosting