import Button from 'Components/Elements/Button'
import DateInput from 'Components/Elements/DateInput'
import Dropdown from 'Components/Elements/Dropdown'
import Popup from 'Components/Elements/Popup'
import { BookingAnalyticsTimePeriod, PaidUnpaidStatus } from 'Constants/Data/Categories'
import React, { useEffect, useState } from 'react'

const CommissionFilter = ({ open, close, onClearFilter, onApplyFilter, filterData, setFilterData, handleChangeFilter,
}) => {
    // states
    const [startDate, setStartDate] = useState(filterData?.start_date)
    const [endDate, setEndDate] = useState(filterData?.end_date)

    useEffect(() => {
        if (startDate) {
            setEndDate()
            setFilterData((prev) => ({
                ...prev,
                start_date: startDate,
                end_date: ''
            }))
        }
    }, [startDate])

    useEffect(() => {
        setFilterData((prev) => ({
            ...prev,
            end_date: endDate
        }))
    }, [endDate])

    // handle on clear filters
    const handleOnClearFilters = () => {
        onClearFilter && onClearFilter()
        setStartDate()
        setEndDate()
    }

    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            heading={'Filter by'}
        >
            <div className='flex flex-col space-y-4'>
                <div>
                    <Dropdown
                        title={'Select Time period'}
                        placeholder={'Select Time period'}
                        name={'filter'}
                        option={BookingAnalyticsTimePeriod}
                        onChange={handleChangeFilter}
                        value={filterData?.filter}
                    />
                </div>
                {filterData?.filter == 'date' &&
                    <div className='flex gap-2 items-center justify-between'>
                        <DateInput
                            required={false}
                            title={'Select Start Date'}
                            name={'startDate'}
                            value={startDate}
                            onChange={(e) => { setStartDate(e.target.value) }}
                            placeholder={'Select Start Date'}
                            inputPadding={"px-4 py-[9px]"}
                            inputClass={"flex-1"}
                            parentClass={"flex-1"}
                        />
                        <DateInput
                            required={false}
                            title={'Select End Date'}
                            name={'endDate'}
                            value={endDate}
                            onChange={(e) => { setEndDate(e.target.value) }}
                            placeholder={'Select End Date'}
                            inputPadding={"px-4 py-[9px]"}
                            inputClass={"flex-1"}
                            parentClass={"flex-1"}
                            min={startDate}
                        />
                    </div>
                }
                <div>
                    <Dropdown
                        title={'Select Status'}
                        placeholder={'Select Time period'}
                        name={'type'}
                        option={PaidUnpaidStatus}
                        onChange={handleChangeFilter}
                        value={filterData?.type}
                    />
                </div>
                <div className="flex items-center justify-between gap-2 mt-4">
                    <Button text={'Clear'} className={'w-full'} customThemeBtn={'view-edit-btn'} onClick={handleOnClearFilters} />
                    <Button text={'Submit'} className={'w-full'} onClick={onApplyFilter && onApplyFilter} />
                </div>
            </div>
        </Popup>
    )
}

export default CommissionFilter