import { GET_CONCEIRGE_ANNOUNCEMENT, GET_RESTAURANT_ANNOUNCEMENT } from "./ActionType";

const initialState = {
    announcements: [],
    announcement_pagination: {},
}

const AnnouncementReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_CONCEIRGE_ANNOUNCEMENT:
            let concierge_announcement;
            if (payload?.announcements?.length == 0) {
                concierge_announcement = [];
            } else if (payload?.pagination_data?.meta?.current_page !== 1) {
                concierge_announcement = [...state?.announcements, ...payload?.announcements];
            } else {
                concierge_announcement = payload?.announcements;
            }
            return {
                ...state,
                announcements: concierge_announcement,
                announcement_pagination: payload?.pagination_data?.meta
            }
        case GET_RESTAURANT_ANNOUNCEMENT:
            let restaurant_announcement;
            if (payload?.announcements?.length == 0) {
                restaurant_announcement = [];
            } if (payload?.pagination_data?.meta?.current_page !== 1) {
                restaurant_announcement = [...state?.announcements, ...payload?.announcements];
            } else {
                restaurant_announcement = payload?.announcements;
            }
            return {
                ...state,
                announcements: restaurant_announcement,
                announcement_pagination: payload?.pagination_data?.meta
            }
        default:
            return {
                ...state,
            };
    }
}

export default AnnouncementReducer