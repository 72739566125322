import { getConciergeAnnouncement } from 'Adapters/APIs/Announcement'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useAnnouncementHelper = () => {
    const dispatch = useDispatch()

    // redux states
    const state = useSelector(state => state)
    const announcement = state?.announcement?.announcements
    const pagination = state?.announcement?.announcement_pagination

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')

    // states
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [secondLoader, setSecondLoader] = useState(false)

    // get conceirge announcement data
    const getConciergeAnnouncementData = () => {
        const success = () => {
            setLoading(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoading(false)
            setSecondLoader(false)
        }
        if (currentPage == 1) {
            setLoading(true)
        } else {
            setSecondLoader(true)
        }
        dispatch(getConciergeAnnouncement(access_token, { page: currentPage, user_id: user_data?.id }, success, fail))
    }

    useEffect(() => {
        getConciergeAnnouncementData()
    }, [currentPage])

    return {
        announcement, pagination, loading, currentPage, setCurrentPage, secondLoader
    }
}

export default useAnnouncementHelper