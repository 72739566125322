import { GET_ALL_SLOTS, GET_BOOSTING_HISTORY } from "./ActionType";

export const getAllSlots = (data) => ({
    type: GET_ALL_SLOTS,
    payload: data
});

export const getBoostingHistory = (data) => ({
    type: GET_BOOSTING_HISTORY,
    payload: data
});