import Svgs from 'Assets/svgs'
import DeletePopup from 'Pages/Restaurants/Menu/DeleteVerificationPopup'
import React, { useState } from 'react'

const InviteHistoryCard = ({ id, status, email, onResendSubmit, resendLoader, deleteLoader, onDeleteSubmit }) => {
    // states
    const [deleteClick, setDeleteClick] = useState(false)
    const [editClick, setEditClick] = useState(false)

    return (
        <>
            <div className='bg-darkGrey rounded-[20px]'>
                <div className='flex gap-2 justify-between items-center py-4 px-4'>
                    <div className='flex gap-2 justify-between items-center'>
                        <Svgs.UserIcon2 width={'50'} height={'50'} />
                        <p className='text-white'>{email}</p>
                    </div>
                    <span className={`${status == 'Accepted' ? 'placed' : "pending"} px-5 py-[10px] w-fit rounded-lg text-sm font-normal flex justify-center items-center`}>
                        {status}
                    </span>
                </div>
                {status?.toLowerCase() != "accepted" &&
                    <div className='border-t border-white py-4 flex items-center justify-end gap-8 px-4'>
                        <div className='flex gap-2 justify-between items-center cursor-pointer' onClick={() => {
                            setDeleteClick(!deleteClick)
                        }}>
                            <Svgs.DustbinIcon />
                            <p className='text-blueGrey'>Delete</p>
                        </div>
                        <div className='flex gap-2 justify-between items-center cursor-pointer' onClick={() => {
                            setEditClick(!editClick)
                        }}>
                            <Svgs.ResendIcon />
                            <p className='text-blueGrey'>Resend</p>
                        </div>
                    </div>
                }
            </div>

            <DeletePopup
                open={deleteClick}
                close={setDeleteClick}
                title={<div className='flex flex-col space-y-4 mx-auto items-center justify-center pb-4 text-center'>
                    <h2 className='text-white'>Do you really want to <br /> delete this invitation</h2>
                    <Svgs.DeleteInvitationIcon />
                </div>}
                loading={deleteLoader}
                onDelete={() => {
                    onDeleteSubmit(id, () => {
                        setDeleteClick(false)
                    })
                }}
            />

            <DeletePopup
                open={editClick}
                close={setEditClick}
                title={<div className='flex flex-col space-y-4 mx-auto items-center justify-center pb-4 text-center'>
                    <h2 className='text-white'>Are you sure you want to resend the <br /> invitation?</h2>
                </div>}
                loading={resendLoader}
                onDelete={() => {
                    onResendSubmit(id, () => {
                        setEditClick(false)
                    })
                }}
            />
        </>
    )
}

export default InviteHistoryCard