import React from 'react';

const ProgressBar = ({ customWidth, doneValue, total, customHeight }) => {
    const percentage = Math.round((doneValue / total) * 100); // Calculate percentage

    return (
        <div className="flex items-center">
            {/* Progress Bar */}
            <div className={`${customWidth ? customWidth : "w-[18.75rem]"} bg-white ${customHeight ? customHeight : "h-[4.5px]"} relative mb-0.5`}>
                <div className="bg-secondary absolute inset-0" style={{ width: `${percentage}%` }}></div>
            </div>

            {/* Done / Total */}
            <span className="ml-3 text-sm font-medium text-white">
                {doneValue}/{total}
            </span>
        </div>
    );
};

export default ProgressBar;
