import React from 'react'

const PaymentCardShimmer = ({className}) => {
    return (
        <div className={`bg-white animate-pulse rounded-md shadow-md p-4 border border-primary ${className ? className : ''}`}>
            <div className="flex flex-col xs:flex-row justify-between gap-10 items-center">
                <div className="flex w-full flex-col gap-3">
                    <div className="h-6 bg-gray-200 rounded w-3/4"></div>
                    <div className="flex items-center gap-3 flex-1">
                        <div className="h-10 bg-gray-200 rounded w-20"></div>
                        <div className="h-10 w-10 bg-gray-200 rounded-full"></div>
                        <div className="h-10 w-10 bg-gray-200 rounded-full"></div>
                    </div>
                </div>
                <div className="flex flex-row xs:flex-col justify-between gap-3 w-full xs:max-w-32 ">
                    <div className="h-10 w-12 xs:h-7 bg-gray-200 rounded xs:w-1/2"></div>
                    <div className="h-10 w-24 xs:h-7 bg-gray-200 rounded xs:w-4/5"></div>
                </div>
            </div>
        </div>
    )
}

export default PaymentCardShimmer