import { addMenuCategory, addPdfMenu, addSubMenu, updateMenuCategory, updateSubMenu } from 'Adapters/APIs/Restaurant/menu'
import { objectKeyConvertToArray } from 'Hooks/useObjectKeys'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import useValidations from 'Hooks/useValidations'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'


const inistailSubMenu = {
    name: '',
    price: '',
    discount: '',
    description: '',
}
const useAddMenu = (close, onSuccessCallback, open, type) => {
    const { errors, setErrors, validation } = useValidations()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const user_data = getDataFromLocalStorage('user_data')
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [manuItem, setMenuItem] = useState({ type: type ? type : 'main_menu', name: '', })
    const [loading, setLoading] = useState(false)
    const [menuType, setMenuType] = useState()
    const [openItems, setOpenItems] = useState(false)
    const [subMenuItems, setSubMenuItems] = useState(inistailSubMenu)
    const [file, setFile] = useState()

    // On Menu Submit
    const onAddManualManu = () => {
        const success = () => {
            close && close(false)
            setMenuItem({ type: type ? type : 'main_menu', name: '', })
            setMenuType()
            setFile()
            onSuccessCallback && onSuccessCallback()
            setLoading(false)
        }
        const fail = () => { setLoading(false) }

        if (menuType == 'pdf') {
            const require_field = {
                upload_menu: file,
            }
            const result = validation(require_field)
            setErrors(result)
            if (objectKeyConvertToArray(result)?.length == 0) {
                setLoading(true)
                dispatch(addPdfMenu(access_token, {
                    [manuItem?.type?.replace('_menu', '') === 'main' ? 'pdf' : manuItem?.type?.replace('_menu', '')]: file,
                     restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : "",
                    category: manuItem?.type?.replace('_menu', '')
                }, success, fail));
            }
        } else {
            const require_field = {
                type: manuItem?.type,
                name: manuItem?.name
            }
            const result = validation(require_field)
            setErrors(result)
            if (objectKeyConvertToArray(result)?.length == 0) {
                const payload = {
                    ...manuItem,
                     restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : ""
                }
                setLoading(true)
                open?.type
                    ? dispatch(updateMenuCategory(access_token, payload, success, fail))
                    : dispatch(addMenuCategory(access_token, payload, success, fail))
            }
        }
    }

    // on Menu Item Submit
    const onAddSubMenu = (successCallback) => {
        const require_field = {
            name: subMenuItems?.name,
            price: subMenuItems?.price,
            description: subMenuItems?.description,
        }
        const result = validation(require_field)
        setErrors(result)
        if (objectKeyConvertToArray(result)?.length == 0) {
            const success = () => {
                close && close(false)
                onSuccessCallback && onSuccessCallback()
                successCallback && successCallback()
                setLoading(false)
            }
            const fail = () => { setLoading(false) }
            const payload = {
                ...subMenuItems,
                menu_category_id: openItems?.id,
                discount: subMenuItems?.discount || null
            }
            setLoading(true)
            subMenuItems?.id
                ? dispatch(updateSubMenu(access_token, payload, success, fail))
                : dispatch(addSubMenu(access_token, payload, success, fail))
        }
    }

    // onSubMenu change
    const onChange = (e) => {
        const { name, value } = e?.target;
        setSubMenuItems({ ...subMenuItems, [name]: value })
        setErrors({ ...errors, [name]: '' })
    }

    // Update Menu Category for Edit
    useEffect(() => {
        if (open?.id) {
            setMenuItem({
                id: open?.id,
                type: open?.type,
                name: open?.title
            })
            setMenuType('manual')
        }
        if (open?.type == 'edit') {
            setMenuType('pdf')
        }
    }, [open])

    // Update Sub menu for edit
    useEffect(() => {
        if (openItems?.submenu?.id) {
            setSubMenuItems({
                id: openItems?.submenu?.id,
                name: openItems?.submenu?.name,
                price: openItems?.submenu?.price,
                discount: openItems?.submenu?.discount,
                description: openItems?.submenu?.description
            })
        }
    }, [openItems])

    useEffect(() => {
        setMenuItem((prev) => ({
            ...prev,
            type: type
        }))
    }, [type])


    return {
        menuType, setMenuType, manuItem, setMenuItem, errors, onAddManualManu, loading, setSubMenuItems,
        setErrors, openItems, setOpenItems, onAddSubMenu, onChange, subMenuItems, setLoading, file, setFile,
    }

}

export default useAddMenu