import DashboardLayout from 'Components/DashboardLayout'
import Button from 'Components/Elements/Button'
import React from 'react'
import AddEditTeamMember from './AddEditTeamMember'
import useManageTeamMembersHelper from './helper'
import SupportRequestCardShimmer from 'Utility/Shimmers/SupportRequestCardShimmer'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import NotFound from 'Utility/NotFound'
import Back from 'Components/Elements/Back'
import LoginInput from 'Components/Elements/LoginInput'
import TeamMemberCard from './Elements/TeamMemberCard'

const ManageTeamMembers = () => {
    const { currentPage, setCurrentPage, addMember, setAddMember, editData, setEditData, team_members, pagination, loading, searchText,
        setSearchText, secondLoader, successCallback
    } = useManageTeamMembersHelper()
    console.log(team_members, "team_members")
    return (
        <>
            <DashboardLayout active={'team-members'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                    <div className="pt-4 flex lg:flex-row flex-col justify-between lg:items-center">
                        <Back title={'Manage Team Members'} />
                        <div className='flex gap-2 justify-end'>
                            <div className='w-fit'>
                                <LoginInput
                                    name={'searchText'}
                                    value={searchText}
                                    onChange={(e) => { setSearchText(e.target.value) }}
                                    placeholder={'Search team member'}
                                    inputPadding={"px-4 py-[9px]"}
                                    inputClass={"flex-1"}
                                    parentClass={"w-fit"}
                                />
                            </div>
                            <Button onClick={() => setAddMember(true)}>Add Team Member</Button>
                        </div>
                    </div>
                    <div className="py-6">
                        <div className="lg:col-span-8 space-y-5">
                            <div className="space-y-3">
                                <div className="relative sm:rounded-lg border-t border-lightGrey hide__child__scroll">
                                    {loading
                                        ? <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 p-4">
                                            {[0, 1, 2, 3, 4, 5]?.map(itm => {
                                                return <div className='flex justify-between items-center gap-2 p-4 bg-darkGrey rounded-md animate-pulse h-[88px]'></div>
                                            })}
                                        </div>
                                        :
                                        team_members?.length > 0 ?
                                            <div className="h-[600px] overflow-y-auto hide__child__scroll">
                                                <InfiniteScroll
                                                    dataLength={team_members?.length || 10}
                                                    next={() => setCurrentPage(currentPage + 1)}
                                                    hasMore={pagination && pagination?.current_page !== pagination?.total_pages}
                                                    loader={secondLoader && <div className="flex justify-center items-center my-4"><PreloaderSm /></div>}
                                                >
                                                    <div className="grid grid-cols-1 sm:grid-cols-2 p-4 gap-5">
                                                        {team_members?.map(itm => (
                                                            <TeamMemberCard
                                                                data={itm}
                                                                key={itm?.id}
                                                                name={itm?.name}
                                                                phoneNumber={`${itm?.phone_code} ${itm?.phone_number}`}
                                                                onEditClick={() => setEditData(itm)}
                                                            />
                                                        ))}
                                                    </div>
                                                </InfiniteScroll>
                                            </div>

                                            : <NotFound />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {(addMember || editData?.id) &&
                        <AddEditTeamMember
                            open={addMember || editData?.id}
                            close={editData?.id ? setEditData : setAddMember}
                            onSuccess={successCallback}
                            editData={editData}
                            title={editData?.id ? "Update Team Member" : "Add Team Member"}
                        />
                    }
                </main>
            </DashboardLayout>
        </>
    )
}

export default ManageTeamMembers