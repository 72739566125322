import Button from 'Components/Elements/Button'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import React from 'react'

const ShareInvoicePopup = ({ open, close, errors, downloadLoading, onApplyFilter, data, onChange }) => {

    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            heading={'Send Email'}
        >
            <div className='flex flex-col space-y-4 gap-6'>
                <LoginInput
                    title={'Email Address'}
                    name={'email'}
                    error={errors.email}
                    value={data}
                    onChange={onChange}
                    placeholder={'Enter Email Address'}
                />
                <div className="flex items-center justify-between gap-2 mt-4">
                    <Button loading={downloadLoading} text={'Submit'} className={'w-full'} onClick={onApplyFilter && onApplyFilter} />
                </div>
            </div>
        </Popup>
    )
}

export default ShareInvoicePopup