import { GET_REFERRAL_COMMISSION_HISTORY, GET_REFERRAL_HISTORY } from "./ActionType";

export const getReferralHistoryAction = (data) => {
    return {
        type: GET_REFERRAL_HISTORY,
        payload: data
    }
};

export const getReferralCommissionHistoryAction = (data) => {
    return {
        type: GET_REFERRAL_COMMISSION_HISTORY,
        payload: data
    }
};