import { toast } from "react-toastify";

export const copyToClipBoard = (data, toastMsg) => {
    let toastMessage = toastMsg ? toastMsg : 'Copied to clipboard successfully'
    navigator.clipboard.writeText(data)
        .then(() => {
            toast.success(toastMessage, { toastId: "toast" })
        })
        .catch((error) => {
        });
}