import React from 'react'
import Svgs from '../../Assets/svgs'
import useSidebar from './helper'
import DeletePopup from 'Pages/Restaurants/Menu/DeleteVerificationPopup'

const SideBar = ({ openSidebar, active }) => {
    const { navigate, sidebarRoutes, defaultRoute, logoutUser, logoutLoader, logoutConfirmation, setLogoutConfirmation } = useSidebar(active)

    return (
        <>
            <aside id="asidebar" className={`fixed top-0 left-0 z-50 h-screen md:w-64 transition-transform ${openSidebar ? "" : '-translate-x-full'}  md:translate-x-0 inset-0 backdrop-blur-[2px] md:backdrop-blur-0`}>
                <div className="bg-primary lg:w-72 w-64 h-screen pt-14 md:pt-0 border-r border-lightGrey">
                    <div className="overflow-y-auto py-5 px-3 h-full bg-primary ">
                        <div onClick={() => { navigate(defaultRoute) }} className="hidden md:flex items-center justify-start mr-4 mb-6 pl-2 cursor-pointer">
                            {/* <img src="/img/logo2.svg" className="mr-3 h-12" alt="Hotel Logo" /> */}
                            <Svgs.AppLogo height={'60'} />
                        </div>
                        <ul className="space-y-2 ">
                            {sidebarRoutes?.map(itm => {
                                return (
                                    <li>
                                        <button
                                            onClick={() => navigate(itm?.url)}
                                            type="button"
                                            className={`flex side-nav items-center p-1 text-blueGrey md:p-3 w-full text-sm font-medium rounded-lg transition duration-75 group hover:bg-lightGrey ${itm?.active ? 'activeLink' : ''}`}
                                        >
                                            <p> {itm?.icon}</p>
                                            <span className="flex-1 ml-3 font-medium text-left whitespace-wrap">{itm.heading}</span>
                                            <p>
                                                <Svgs.ArrowNextIcon />
                                            </p>
                                        </button>
                                    </li>
                                )
                            })}
                            <li>
                                <button
                                    onClick={()=>{
                                        setLogoutConfirmation(!logoutConfirmation)
                                    }}
                                    type="button"
                                    className={`flex side-nav items-center ml-1 text-blueGrey md:p-3 p-2 md:py-4 w-full text-sm font-medium rounded-lg transition duration-75 group hover:bg-lightGrey`}
                                >
                                    <Svgs.LogoutIcon className={'icon w-[1rem] h-[1rem]'} color={'var(--error-color)'} />
                                    <span className="flex-1 ml-3 font-medium text-left whitespace-wrap text-error">Logout</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </aside>

            <DeletePopup
                open={logoutConfirmation}
                close={setLogoutConfirmation}
                title={<div className='flex flex-col space-y-4 mx-auto items-center justify-center pb-4 text-center'>
                    <h2 className='text-white'>Are you sure you want to Logout?</h2>
                </div>}
                loading={logoutLoader}
                onDelete={logoutUser}
                customSize={'md:w-[35vw] xl:w-[30vw]'}
            /> 
        </>
    )
}

export default SideBar