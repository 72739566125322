import { createWishlist, updateWishlist } from "Adapters/APIs/Concierge/Wishlist"
import { objectKeyConvertToArray } from "Hooks/useObjectKeys"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import useValidations from "Hooks/useValidations"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

const useCreateWishlist = (close, onSuccess, isUpdate, setIsUpdate) => {
    const dispatch = useDispatch()
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const [loading, setLoading] = useState(false)
    const access_token = getDataFromLocalStorage('access_token')
    const { errors, setErrors, validation, validateMoblieNumber, emailValidation, confirmPasswordValidation, passwordValidation } = useValidations()


    const [request, setRequest] = useState({
        name: '', city: '', address: '', website_link: '', map_link: '', phone_number: '',
    })

    const onChangeHandler = (e) => {
        const { name, value, files } = e?.target
        if (name == 'phone_number') {
            validateMoblieNumber(name, value);
            setRequest({
                ...request,
                phone_number: value,
                // phone_code: value?.split('-')[0]
            })
        } else if (name == 'image') {
            setRequest({
                ...request,
                [name]: files?.length > 0 ? files[0] : {}
            })
            setErrors({
                ...errors,
                [name]: ''
            })
        } else if (name == 'remove') {
            setRequest({
                ...request,
                'image': {}
            })
            setErrors({
                ...errors,
                [name]: ''
            })
        } else {
            setRequest({
                ...request,
                [name]: value
            })
            setErrors({
                ...errors,
                [name]: ''
            })
        }
    }

    // on Wishlist submit
    const onSubmit = () => {
        const err = validation({
            name: request?.name,
            city: request?.city
        })
        setErrors(err)
        if (objectKeyConvertToArray(err)?.length == 0) {
            setLoading(true)
            const success = () => {
                close && close(false)
                onSuccess && onSuccess(false)
                setIsUpdate()
                setLoading(false)
                setRequest({
                    title: '',
                    type: '',
                    notes: '',
                    image: '',
                })
            }
            const fail = () => {
                setLoading(false)
            }
            const payload = {
                ...request,
                phone_number: request?.phone_number?.split('-')[1],
                phone_code: request?.phone_number?.split('-')[0]?.replace('+', '')
            }
            if (payload?.id)
                dispatch(updateWishlist(access_token, payload, success, fail))
            else
                dispatch(createWishlist(access_token, payload, success, fail))
        }
    }

    // On Popup Close
    const onClose = () => {
        close && close(false)
        setIsUpdate()
        setRequest()
        setErrors()
    }

    // check if its for update 
    useEffect(() => {
        if (isUpdate?.id)
            setRequest({
                id: isUpdate?.id,
                name: isUpdate?.name,
                city: isUpdate?.city,
                address: isUpdate?.address,
                website_link: isUpdate?.website_link,
                map_link: isUpdate?.map_link,
                phone_number: isUpdate?.phone_code + '-' + isUpdate?.phone_number,

            })
    }, [isUpdate])


    return {
        onChangeHandler, request, loading, onSubmit, errors, onClose
    }

}

export default useCreateWishlist