import React from 'react'
import DashboardLayout from '../../../Components/DashboardLayout'
import useRewards from './helper'
import NotFound from 'Utility/NotFound'
import Back from 'Components/Elements/Back'
import ViewAllBtn from 'Components/Elements/ViewAllBtn'
import RewardCard from './Elements/RewardCard'
import moment from 'moment'
import WinnerDetailPopup from './Elements/WinnerDetailPopup'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import EarningHistoryCard from '../InviteFriend/Elements/EarningHistoryCard'

const Rewards = () => {
    const {
        type, setType, loading, announced_rewards, upcomming_rewards, upcomingLoading, navigate, winnerDetail, setWinnerDetail,
        history, pagination_data, secondLoader, currentPage, setCurrentPage
    } = useRewards()
    return (
        <>
            <DashboardLayout active={'reward'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen max-h-screen overflow-y-auto">
                    <div className="space-y-4">
                        <div className="flex items-center gap-3 justify-between">
                            <Back title={'Rewards'} />
                        </div>
                        <div>
                            <div className="border-b flex border-lightGrey items-baseline gap-5 text-blueGrey">
                                <div className="flex items-baseline gap-5 text-blueGrey">
                                    <p onClick={() => setType('conciergesOfTheMonth')} className={`${type == 'conciergesOfTheMonth' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>Concierge of the month &nbsp;
                                    </p>
                                    <p onClick={() => setType('myReferralrewards')} className={`${type == 'myReferralrewards' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>My referral rewards &nbsp;
                                    </p>
                                </div>
                            </div>
                            {type == 'conciergesOfTheMonth' ?
                                <>
                                    <div className="pt-6">
                                        <div className="flex items-center gap-3 justify-between">
                                            <p className='text-xl text-secondary'>Announce Reward</p>
                                            <ViewAllBtn
                                                onClick={() => { navigate('/concierge/announce-reward') }}
                                                className={'!px-4'}
                                                title={'View All'}
                                            />
                                        </div>
                                        <div className="rounded-lg md:rounded-[10px]">
                                            {loading
                                                ? <div className={`grid grid-cols-1 w-full gap-5`}>
                                                    <div className="flex flex-col w-full gap-2 py-2 rounded-lg">
                                                        <div className="bg-gray-500 w-full h-52 rounded-lg" alt="promo" />
                                                    </div>
                                                </div>
                                                : announced_rewards?.length > 0
                                                    ?
                                                    <div className={`py-4 grid grid-cols-1 w-full gap-5`}>
                                                        {announced_rewards?.[0] && (
                                                            <RewardCard
                                                                image={announced_rewards[0]?.reward}
                                                                data={announced_rewards[0]}
                                                                date={announced_rewards[0]?.date}
                                                                className={'cursor-pointer'}
                                                                text={`Winner for ${moment(announced_rewards[0]?.date).format('MMMM YYYY')} is: ${announced_rewards[0]?.winner?.user?.first_name ? announced_rewards[0]?.winner?.user?.first_name : ""} ${announced_rewards[0]?.winner?.user?.last_name ? announced_rewards[0]?.winner?.user?.last_name : ""}`}
                                                                onClick={() => {
                                                                    // setWinnerDetail(announced_rewards[0])
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    : <NotFound />
                                            }
                                        </div>
                                    </div>

                                    <div className="pb-6 pt-2">
                                        <div className="flex items-center gap-3 justify-between">
                                            <p className='text-xl text-secondary'>Upcoming Reward</p>
                                        </div>
                                        <div className="rounded-lg md:rounded-[10px]">
                                            {upcomingLoading
                                                ?
                                                <>
                                                    <div className={`grid grid-cols-1 w-full md:grid-cols-2 gap-5 pt-4`}>
                                                        {[0, 1, 2, 3]?.map(itm => {
                                                            return <div className="flex flex-col w-full gap-2 rounded-lg">
                                                                <div className="bg-gray-500 w-full h-52 rounded-lg" alt="promo" />
                                                            </div>
                                                        })}
                                                    </div>
                                                </>
                                                : upcomming_rewards?.length > 0
                                                    ?
                                                    <div className={`py-4 grid grid-cols-1 md:grid-cols-2 w-full gap-5`}>
                                                        {upcomming_rewards?.map(itm => {
                                                            return <RewardCard
                                                                image={itm?.reward}
                                                                data={itm}
                                                                date={itm?.date}
                                                                winner={itm?.winner?.user}
                                                            />
                                                        })
                                                        }
                                                    </div>
                                                    : <NotFound />
                                            }
                                        </div>
                                    </div>
                                </>
                                :
                                <div className='flex flex-col space-y-4 py-6'>
                                    {history?.length > 0 ?
                                        <InfiniteScroll
                                            dataLength={history?.length ? history?.length : 10} //This is important field to render the next data
                                            next={() => {
                                                setCurrentPage(currentPage + 1)
                                            }}
                                            hasMore={pagination_data && pagination_data?.current_page !== pagination_data?.total_pages}
                                            loader={secondLoader
                                                ? <div className="flex justify-center items-center my-4">
                                                    <PreloaderSm />
                                                </div> : ""
                                            }
                                        // height={'40rem'}
                                        >
                                            <div className="!m-0 grid lg:grid-cols-2 grid-cols-1 gap-4">
                                                {history?.map((itm, index) =>
                                                    <EarningHistoryCard
                                                        name={`${itm?.user?.first_name ? itm?.user?.first_name : ""} ${itm?.user?.last_name ? itm?.user?.last_name : ""}`}
                                                        doneValue={itm?.reservation_count}
                                                        earning={itm?.earning}
                                                        type={itm?.user?.restaurant ? 'Business' : "Concierge"}
                                                    />
                                                )}
                                            </div>
                                        </InfiniteScroll>
                                        : <NotFound />
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </main>
            </DashboardLayout>

            {winnerDetail &&
                <WinnerDetailPopup
                    open={winnerDetail}
                    close={setWinnerDetail}
                    data={winnerDetail}
                />
            }
        </>
    )
}

export default Rewards