import Button from 'Components/Elements/Button'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import React from 'react'
import useAddEditTeamMemberHelper from './helper'
import PhoneInput from 'Components/Elements/PhoneInput'
import StatusInput from 'Components/Elements/StatusInput'

const AddEditTeamMember = ({ open, close, onSuccess, title, editData }) => {
    const { onChangeHandler, formData, loading, onSubmit, errors, handleClose } = useAddEditTeamMemberHelper(close, onSuccess, editData)
    return (
        <>
            <Popup
                open={open}
                close={handleClose}
                heading={title}
            >
                <div className="flex flex-col gap-5">
                    <LoginInput
                        error={errors?.name}
                        value={formData?.name}
                        title={'Staff Name'}
                        name={'name'}
                        onChange={onChangeHandler}
                        placeholder={'Enter Staff Name'}
                        required={true}
                    />
                    <PhoneInput
                        value={`${formData?.phone_code}-${formData?.phone_number}`}
                        error={errors?.phone_number}
                        name={'phone_number'}
                        onChange={onChangeHandler}
                        required={true}
                        title={'Mobile Number'}
                        placeholder={'Enter Phone Number'}
                        inputPadding={"px-4 py-[9px]"}
                    />
                    <StatusInput
                        title={"Status"}
                        required
                        name={"is_active"}
                        value={formData?.is_active}
                        onChange={onChangeHandler}
                    />
                    <Button
                        onClick={onSubmit}
                        loading={loading}
                        text={title}
                    />
                </div>
            </Popup>
        </>
    )
}

export default AddEditTeamMember