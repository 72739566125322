import React, { useState } from 'react';
import Button from 'Components/Elements/Button';
import Popup from 'Components/Elements/Popup';
import Timer from 'Components/Timer';
import { toast } from 'react-toastify';

const ReminderManager = ({ showPopup, setShowPopup }) => {
    const [selectedTime, setSelectedTime] = useState({ minutes: 0, seconds: 0 });

    // Callback function to store selected time
    const handleTimeUpdate = (minutes, seconds) => {
        setSelectedTime({ minutes, seconds });
    };

    // Function to handle "Update" button click
    const updateReminder = () => {
        const now = new Date();
        const futureTime = new Date(now.getTime() + selectedTime.minutes * 60000 + selectedTime.seconds * 1000);

        // Retrieve any existing timers from localStorage
        const existingTimers = JSON.parse(localStorage.getItem('timers')) || [];

        // Add the new timer to the list
        const newTimer = {
            id: Date.now(),  // unique identifier for this timer
            alertTime: futureTime.getTime(),
        };
        const updatedTimers = [...existingTimers, newTimer];

        // Store updated timers array in localStorage
        localStorage.setItem('timers', JSON.stringify(updatedTimers));

        toast.success(`Reminder set for ${selectedTime.minutes} minute(s) and ${selectedTime.seconds} second(s).`, { toastId: 'toast' });
        setShowPopup(false);  // Close the popup after updating
    };

    // Function to handle "Cancel All" button click
    const cancelAllReminder = ()=>{
        localStorage.removeItem('timers')
        setShowPopup(false);  // Close the popup after cancel all
    }

    return (
        <Popup open={showPopup} close={() => setShowPopup(false)} heading={'Set Reminder'} customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}>
            <div className="py-3 flex items-center justify-center gap-14 text-white">
                <Timer onTimeUpdate={handleTimeUpdate} />
            </div>
            <div className='pt-4 grid grid-cols-2 gap-2 items-center'>
                <Button
                    title={"Cancel All"}
                    customThemeBtn={'view-edit-btn'}
                    onClick={cancelAllReminder}  // Clear all reminders
                />
                <Button
                    title={"Update"}
                    onClick={updateReminder}  // Call the updateReminder function on click
                />
            </div>
        </Popup>
    );
};

export default ReminderManager;
