export const GET_TRANSACTION = 'GET_TRANSACTION'
export const GET_SUPPORT_REQUEST = 'GET_SUPPORT_REQUEST'
export const GET_WISHLIST = 'GET_WISHLIST'
export const GET_WISHLIST_DETAIL = 'GET_WISHLIST_DETAIL'
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA'
export const GET_ALL_PROMTIONS = 'GET_ALL_PROMTIONS'
export const GET_ALL_ANNOUNCED_REWARDS = 'GET_ALL_ANNOUNCED_REWARDS'
export const GET_ALL_UPCOMMING_REWARDS = 'GET_ALL_UPCOMMING_REWARDS'
export const GET_COMMISSION_ANALYTICS = 'GET_COMMISSION_ANALYTICS'
export const GET_TEAM_MEMBERS = "GET_TEAM_MEMBERS"
export const GET_TEAM_MEMBERS_DROPDOWN = "GET_TEAM_MEMBERS_DROPDOWN"