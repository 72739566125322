import React from 'react'
import Button from 'Components/Elements/Button'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'

const FinancialsCards = ({ title1, hideSeprater, title2, btnText, amount1, amount2, heading, headingAmount, currency, onAllClick }) => {
    return (
        <>
            <div
                className={`bg-darkGrey cursor-pointer relative rounded-lg md:rounded-[20px] flex flex-col border border-secondary items-center justify-between overflow-hidden`}
                onClick={onAllClick && onAllClick}
            >
                <div className='flex flex-col border-b border-lightGrey text-blueGrey  w-full p-3 gap-1'>
                    <h4 className="font-semibold text-lg text-lightGrey">{headingAmount} {headingAmount && currency}</h4>
                    <p className="text-sm font-semibold text-blueGrey">{heading}</p>
                </div>
                <div className="flex flex-col relative gap-3 items-center justify-between h-full w-full py-8 px-5">

                    <div className="flex justify-between w-full text-blueGrey gap-2">
                        <div className='flex flex-col items-center w-11/12 mx-auto gap-1'>
                            <p className="font-semibold text-sm text-secondary text-center text-white">{title1}</p>
                            <h4 className="text-xl text-white text-center">{amount1} {currency}</h4>
                        </div>
                        {!hideSeprater && <div className='bg-lightGrey w-0.5' />}
                        {(title2 || amount2) &&
                            <div className='flex flex-col items-center w-11/12 mx-auto gap-1'>
                                <p className="font-semibold text-sm text-secondary text-center text-white">{title2}</p>
                                <h4 className="text-xl text-white text-center">{amount2} {currency}</h4>
                            </div>
                        }
                    </div>

                </div>
                <Button
                    onClick={onAllClick && onAllClick}
                    className={'absolute top-0 right-0 rounded-lg text-xs md:rounded-[20px] !py-2 md:rounded-tl-none rounded-tl-none md:rounded-br-none rounded-br-none'}
                    title={btnText ? btnText : "View all"}
                />
            </div>
        </>
    )
}

export default FinancialsCards