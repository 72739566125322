import { BASE_URL, get_invoice_report_url, send_invoice_by_email_url } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import axios from "axios";
import { toast } from "react-toastify";

export const getInvoiceReportAPI = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_invoice_report_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}

export const sendInvoceByEmailAPI = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${send_invoice_by_email_url}`, payload, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
            toast.success(response?.data?.message)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || apiErrorMessage)
        fail && fail()
        return error;
    }
}