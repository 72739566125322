import React from 'react'
import useProfile from './helper'
import DashboardLayout from 'Components/DashboardLayout'
import EditRestaurantProfile from './EditProfile'
import UpdatePassword from './ChangePassword'
import ProfileSideBar from './Elements/ProfileSideBar'
import ProfileDetails from './Elements/ProfileDetails'
import RestaurantRatingPupup from './RatingPopup'
import Back from 'Components/Elements/Back'
import Svgs from 'Assets/svgs'
import ConfirmationPopup from 'Components/ConfirmationPopup/ConfirmationPopup'
import BusinessHour from './BusinessHour'

const RestaurantProfile = () => {
    const {
        navigate, user, isEditProfile, setIsEditProfile, activeTab, setActiveTab, openDropDownMenu, setopenDropDownMenu,
        openRatingPopup, setOpenRatingPopup, loading, ratingData, setRatingData, handleSubmit, errors, setErrors, validateWebsiteUrl,
        deletePopup, setDeletePopup, handleDeleteAccount, deleteLoader, postBusinessHours, businessHourLoader
    } = useProfile()

    return (
        <>
            <DashboardLayout active={'profile'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen max-h-screen overflow-y-auto topscroll">
                    <div className="space-y-5 edittopscroll">
                        <Back
                            title={'Profile'}
                            onClick={() => {
                                navigate(-1);
                            }}
                        />
                        <div className="block sm:hidden overflow-hidden">
                            <div onClick={() => setopenDropDownMenu(!openDropDownMenu)} className="sm:hidden w-full rounded-lg border border-lightGrey p-3 flex items-center justify-between">
                                <p className='text-blueGrey'>Edit Profile </p>
                                <Svgs.ArrowDown fill={"var(--blueGrey-color)"} />
                            </div>

                            <div className={`${openDropDownMenu ? "" : 'hidden'} overflow-hidden`}>
                                <ProfileSideBar
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    isParent={user?.is_parent}
                                />
                            </div>
                        </div>
                        <hr className='border-lightGrey' />
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-9 min-h-[77vh] pt-4 sm:pt-0">
                        <div className="hidden sm:block sm:col-span-3 xl:col-span-2 border-r border-lightGrey pr-4 py-4">
                            <ProfileSideBar
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                isParent={user?.is_parent}
                            />
                        </div>

                        {/* Edit Profile */}
                        {/* User Profile Details */}
                        <div className="sm:col-span-6 xl:col-span-7">
                            {activeTab == 'profile'
                                ? isEditProfile
                                    ? <div className="sm:p-4">
                                        <EditRestaurantProfile setIsEdit={setIsEditProfile} />
                                    </div>
                                    : <>
                                        <div className="sm:p-4">
                                            <ProfileDetails
                                                setDeletePopup={setDeletePopup}
                                                deletePopup={deletePopup}
                                                user={user}
                                                setOpen={setOpenRatingPopup}
                                                setIsEditProfile={setIsEditProfile}
                                            />
                                        </div>
                                    </>
                                : activeTab == 'change_password'
                                    ? <div className="sm:p-4">
                                        <UpdatePassword setActiveTab={setActiveTab} />
                                    </div>
                                    : activeTab == 'business-hours'
                                        ? <div className="sm:p-4">
                                            <BusinessHour setActiveTab={setActiveTab} postBusinessHours={postBusinessHours} businessHourLoader={businessHourLoader} />
                                        </div>
                                        : ''
                            }
                        </div>
                    </div>

                </main>
                {openRatingPopup &&
                    <RestaurantRatingPupup
                        loading={loading}
                        handleSubmit={handleSubmit}
                        open={openRatingPopup}
                        ratingData={ratingData}
                        close={() => {
                            setOpenRatingPopup(false)
                            setRatingData([
                                {
                                    type: "tripadvisor",
                                    rating: "",
                                    link: ""
                                }, {
                                    type: "google",
                                    rating: "",
                                    link: ""
                                }
                            ])
                            setErrors()
                        }}
                        setRatingData={setRatingData}
                        errors={errors}
                        setErrors={setErrors}
                        validateWebsiteUrl={validateWebsiteUrl}
                    />
                }

                <ConfirmationPopup
                    open={deletePopup}
                    close={setDeletePopup}
                    title={'For account deletion,we require your confirmation. Please select an option below to confirm'}
                    onYesClick={handleDeleteAccount}
                    yesLoader={deleteLoader}
                    removeHeader={true}
                />

            </DashboardLayout>

        </>
    )
}

export default RestaurantProfile