import React, { useState } from 'react'
import { error_message } from 'Constants/Functions/Variables'
import Svgs from 'Assets/svgs'


const PasswordInput = ({ hideError, onEnterSubmit, title, placeholder, name, value, onChange, onBlur, error, required, showBorder, customColor, isCapitalized }) => {
    const [show, setshow] = useState(false)

    const parsedPlaceholder = isCapitalized ? placeholder : placeholder && placeholder.charAt(0).toUpperCase() + placeholder.slice(1).toLowerCase();

    const HandleKeyPress = (e) => {
        if (e.key.toLowerCase() == 'enter') {
            onEnterSubmit()
        }
    }
    return (
        <div className='flex flex-col gap-2 relative'>
            <p className='text-sm text-blueGrey'>{title} {required && <span className='text-red-500'> *</span>}</p>
            <div className={`${error ? "focus-within:border-error" : "focus-within:border-secondary"} rounded-[10px] relative flex overflow-hidden transition`}>
                <input
                    onKeyDown={HandleKeyPress}
                    type={show ? 'text' : 'password'}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    className={`${error ? " border-[1.5px] border-error" : showBorder ? "border-[1.5px] border-lightGrey" : ""} min-h-10 rounded-[10px] w-full ${customColor ? customColor : "bg-darkGrey"} px-4 py-2 text-base`}
                    placeholder={parsedPlaceholder}
                    autoComplete="off"
                />
                {!show
                    ? <div onClick={() => { setshow(!show) }} className='absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer'>
                        <Svgs.EyeHide />
                    </div>
                    : <div onClick={() => { setshow(!show) }} className='absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer'>
                        <Svgs.EyeShow fill={'var(--secondary-color)'} />
                    </div>
                }
            </div>
            {
                !hideError && (error) && (typeof error == "string") ?
                    <p className='text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end'>
                        <Svgs.I fill='#eb3b3b' />{error}</p>
                    :
                    (typeof error == "boolean" && error == true)
                        ?
                        <p className='text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end'>
                            <Svgs.I fill='#eb3b3b' />{error_message}</p>
                        :
                        ""
            }

        </div>
    )
}

PasswordInput.defaultProps = {
    title: 'Enter "title" prop',
    placeholder: 'Enter "placeholder" prop',
    onEnterSubmit: () => { },
    hideError: false,
    required: true,
    showBorder: true
}

export default PasswordInput