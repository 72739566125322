import { getConciergeReferralData } from "Adapters/APIs/Concierge/InviteFriend";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useTotalReferralCommissionHelper = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')

    // redux states
    const state = useSelector(state => state)
    const history = state?.inviteFriend?.referralHistoryData
    const pagination_data = state?.inviteFriend?.referral_pagination_data

    // states
    const [loading, setLoading] = useState(true)
    const [secondLoader, setSecondLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [openBookings, setOpenBookings] = useState(false)

    useEffect(() => {
        getRedeemHistoryData()
    }, [currentPage, startDate, endDate])

    // get redeem history data
    const getRedeemHistoryData = () => {
        let payload = {
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : "",
            start_date: startDate ? startDate : "",
            end_date: endDate ? endDate : "",
            type: 'referral_commission',
            status: "accepted"
        }
        const success = () => {
            setLoading(false)
        }
        const fail = () => {
            setLoading(false)
        }
        if (currentPage == 1) {
            setLoading(true)
            setSecondLoader(false)
        } else {
            setSecondLoader(true)
        }
        dispatch(getConciergeReferralData(access_token, payload, success, fail))
    }

    return {
        loading, history, setCurrentPage, currentPage, pagination_data, secondLoader, startDate, endDate, setStartDate, setEndDate,
        openBookings, setOpenBookings, navigate
    }
}

export default useTotalReferralCommissionHelper