import { deleteAccount } from 'Adapters/APIs/Authentication'
import useLogoutHelper from 'Hooks/useLogoutHelper'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

const useProfile = (onSuccessCallback) => {
    const { logoutUser } = useLogoutHelper()

    const [searchParams, setSearchParams] = useSearchParams();

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const user = getDataFromLocalStorage('user_data')
    const access_token = getDataFromLocalStorage('access_token')

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [isEditProfile, setIsEditProfile] = useState(false)
    const [activeTab, setActiveTab] = useState(searchParams.get('activeTab') || 'profile')
    const [deleteProfile, setDeleteProfile] = useState(false)
    const [deleteLoader, setDeleteLoader] = useState(false)

    // Delete Account
    const handleDeleteAccount = () => {
        const onSuccessCallback = () => {
            setDeleteProfile(false)
            setDeleteLoader(false)
        }
        const success = (data) => {
            logoutUser(onSuccessCallback);
        }
        const fail = () => {
            setDeleteLoader(false)
        }
        setDeleteLoader(true)
        dispatch(deleteAccount(access_token, {}, success, fail))
    };

    useEffect(() => {
        // Fetch data only if we're on the profile page and have an active tab
        if (activeTab && window.location.pathname === "/concierge/profile") {
            // Get the current URL params
            const params = new URLSearchParams(window.location.search);

            // Only push new state if the activeTab has actually changed
            if (params.get('activeTab') !== activeTab) {
                // Update the URL with activeTab and push to history
                const newUrl = `/concierge/profile?activeTab=${activeTab}`;
                window.history.pushState(null, '', newUrl);  // Push the URL with the activeTab
            }
        }

        const handlePopState = () => {
            const params = new URLSearchParams(window.location.search);
            const tabFromUrl = params.get('activeTab');

            if (!tabFromUrl) {
                navigate(-1);  // Navigate to the previous page if no activeTab
            } else {
                setActiveTab(tabFromUrl);  // Update the activeTab state based on the URL
            }
        };

        // Listen for popstate (back button)
        window.addEventListener('popstate', handlePopState);

        // Clean up the listener on component unmount
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [activeTab, access_token, user?.id, dispatch, navigate]);

    return {
        user, navigate, isEditProfile, setIsEditProfile, activeTab, setActiveTab, deleteProfile, setDeleteProfile, deleteLoader,
        handleDeleteAccount
    }
}

export default useProfile