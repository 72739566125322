import React from 'react'
import useEditProfile from './helper'
import LoginInput from 'Components/Elements/LoginInput'
import PhoneInput from 'Components/Elements/PhoneInput'
import PaymentCheckboxCard from 'Components/Elements/PaymentCheckboxCard'
import Button from 'Components/Elements/Button'
import Svgs from 'Assets/svgs'
import { convertImgToUrl } from 'Hooks/useObjectKeys'
import ChangeEmailPopup from './ChangeEmailPopup'
import ImageUploader from 'Components/Elements/ImageUploader'
import Back from 'Components/Elements/Back'
import { PAYMENT_METHODS } from 'Constants/Data/Categories'
import moment from 'moment'
import AddBusinessNamePopup from 'OnBaording/Signup/ConciergeForm/AddBusinessNamePopup'

const EditConciergeProfile = ({ setIsEdit, editData, successCallback, loader, hideUpdateEmail, hideBackBtn, showRejectionReasons }) => {
    const {
        navigate, formData, errors, handleChange, handleSubmit, loading, setOTPLoading, updateEmail, setUpdateEmail, OTPLoading, requestOTP,
        emailValidation, user, addBusinessName, setAddBusinessName, getBusinessNameLoader, businessData
    } = useEditProfile(setIsEdit, editData, successCallback)

    return (
        <>
            <div className="py-6">
                <div>
                    {hideBackBtn
                        ? ""
                        : <Back
                            onClick={() => { setIsEdit ? setIsEdit(false) : navigate(-1) }}
                            title={'Profile'}
                        />
                    }
                    <div className="pt-5 pb-5 gap-3 flex flex-col">
                        {(user?.rejection_thread?.length > 0 && showRejectionReasons) &&
                            <div className="flex flex-col gap-3 ">
                                <h1 className='text-base text-white font-semibold'>Rejection Reason</h1>
                                {user?.rejection_thread?.map((itm, ind) => {
                                    return (
                                        <div className='flex justify-between p-2 gap-4 bg-darkGrey rounded-lg'>
                                            <h1 className='text-sm text-secondary'>{itm?.message}</h1>
                                            <h1 className='text-sm text-blueGrey'>{moment(itm?.created_at).format('DD MMM YYYY')}</h1>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        <div className="flex items-center gap-5 sm:gap-8">
                            <div className="relative h-fit w-fit min-w-36 min-h-36">
                                {formData?.profile_picture ?
                                    <img className="rounded-full object-cover border-2 w-36 h-36"
                                        src={typeof formData?.profile_picture == 'object' ? convertImgToUrl(formData?.profile_picture) : formData?.profile_picture} alt='' />
                                    : <Svgs.UserIcon2 width={'9rem'} height={'9rem'} />
                                }
                                <input
                                    hidden
                                    type="file"
                                    name={'profile_picture'}
                                    onChange={handleChange}
                                    id="edit"
                                    accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG"}
                                    autoComplete="off"
                                />
                                <label htmlFor="edit"
                                    className='absolute z-10 cursor-pointer bg-darkGrey !p-2 bottom-0 right-0 gap-2 rounded-full border flex items-center capitalize'>
                                    <Svgs.EditIcon fill={'var(--secondary-color)'} />
                                </label>

                            </div>
                        </div>
                        <div className="flex flex-col gap-5">
                            <div className="flex sm:flex-row flex-col w-full justify-between items-center gap-x-3 gap-y-5 ">
                                <div className='flex-1 w-full'>
                                    <LoginInput
                                        required={true}
                                        title={'First Name'}
                                        placeholder={"Enter First Name"}
                                        value={formData?.first_name}
                                        error={errors?.first_name}
                                        name={'first_name'}
                                        onChange={handleChange}
                                        inputPadding={"px-4 py-[9px]"}
                                    />
                                </div>
                                <div className='flex-1 w-full'>
                                    <LoginInput
                                        required={true}
                                        title={'Family Name'}
                                        placeholder={"Enter Family Name"}
                                        value={formData?.last_name}
                                        error={errors?.last_name}
                                        name={'last_name'}
                                        onChange={handleChange}
                                        inputPadding={"px-4 py-[9px]"}
                                    />
                                </div>
                            </div>
                            <div className="flex sm:flex-row flex-col w-full justify-between items-center gap-x-3 gap-y-5 ">
                                <div className='flex-1 w-full'>
                                    <LoginInput
                                        required={true}
                                        title={'Business/Hotel Name'}
                                        value={
                                            businessData?.length > 0
                                                ? (businessData.find(itm => itm?.id === formData?.business_name)?.name || formData?.business_name)
                                                : formData?.business_name
                                        }
                                        error={errors?.business_name}
                                        name={'business_name'}
                                        onChange={handleChange}
                                        placeholder={"Enter Business/Hotel Name"}
                                        inputPadding={"px-4 py-[9px]"}
                                    />
                                </div>
                                {/* <div className='flex gap-2 justify-between items-center flex-1 w-full'>
                                // add business name component here if want to add popup
                                    <div className='pt-7'>
                                        <Button
                                            text={<Svgs.BoldPlusIcon width={"45"} height={'40'} />}
                                            onClick={() => {
                                                setAddBusinessName(true)
                                            }}
                                            className="w-full !py-0.5 !pl-2 !pr-2.5 bg-[#282828] border-[1.5px] border-lightGrey"
                                        />
                                    </div>
                                </div> */}
                                <div className='flex-1 w-full'>
                                    <LoginInput
                                        title={'Email Address'}
                                        required={true}
                                        readOnly={true}
                                        value={formData?.email}
                                        error={errors?.email}
                                        name={'email'}
                                        onChange={handleChange}
                                        rightIconClassName={!hideUpdateEmail && '-right-[1.2px] !bottom- !top-none'}
                                        rightIcon={!hideUpdateEmail && <>
                                            <Button customPadding="!p-2" customThemeBtn={"flex items-center justify-center bg-lightGrey text-blueGrey !py-2.5 h-full"}
                                                onClick={() => {
                                                    setUpdateEmail(true)
                                                    setOTPLoading(false)
                                                }}
                                            >
                                                Update
                                            </Button>
                                        </>}
                                        placeholder={"Enter your Email Address"}
                                        inputPadding={"px-4 py-[9px]"}
                                    />
                                </div>
                            </div>
                            <div className="flex sm:flex-row flex-col w-full justify-between items-center gap-x-3 gap-y-5 ">
                                <div className='flex-1 w-full'>
                                    <PhoneInput
                                        value={formData?.mobile_number}
                                        error={errors?.mobile_number}
                                        name={'mobile_number'}
                                        required={true}
                                        title={'Mobile Number'}
                                        placeholder={'Enter Mobile Number'}
                                        inputPadding={"px-4 py-[9px]"}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='flex-1 w-full'>
                                    <PhoneInput
                                        edit={true}
                                        value={formData?.wa_number}
                                        error={errors?.wa_number}
                                        name={'wa_number'}
                                        title={'WhatsApp Number'}
                                        placeholder={'Enter WhatsApp Number'}
                                        onChange={handleChange}
                                        inputPadding={"px-4 py-[9px]"}
                                    />
                                </div>
                            </div>
                            <LoginInput
                                title={'Date of Birth'}
                                required={false}
                                value={formData?.dob}
                                error={errors?.dob}
                                name={'dob'}
                                onChange={handleChange}
                                type={'date'}
                                inputPadding={"px-4 py-[9px]"}
                            />
                        </div>
                        <div className="flex flex-col flex-1 w-full">
                            <div className={`text-sm col-span-2 text-blueGrey flex items-center gap-1`}>
                                Payment Preference
                            </div>
                            <div className='flex xs:flex-row flex-col gap-5 py-2 '>
                                {PAYMENT_METHODS?.length > 0 ? (
                                    PAYMENT_METHODS?.map((item, index) => {
                                        return (
                                            <div className='w-full flex-1'>
                                                <PaymentCheckboxCard
                                                    key={index}
                                                    leftIcon={item?.icon ? item?.icon : <img className="h-[1.25rem] w-[1.25rem] object-contain" src={`${process.env.PUBLIC_URL}/images/payment_icon.jpeg`} alt='' />}
                                                    checked={item?.method_type == formData?.payment_method}
                                                    onClick={() => {
                                                        handleChange({
                                                            target: {
                                                                name: 'payment_method',
                                                                value: item?.method_type
                                                            }
                                                        })
                                                    }}
                                                    id={item.method_type}
                                                    title={
                                                        <div className='flex items-center gap-2'>
                                                            <p className='font-semibold text-sm'>{item.label}</p>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        )
                                    }))
                                    :
                                    ""
                                }
                            </div>
                            {
                                (formData?.payment_method && formData?.payment_method == 'bank') && (
                                    <div className='grid grid-cols-2 gap-5 col-span-2 '>
                                        <LoginInput
                                            value={formData?.bank_name}
                                            error={errors?.bank_name}
                                            name={'bank_name'}
                                            onChange={handleChange}
                                            required={true}
                                            title={'Bank Name'}
                                            placeholder={"Enter bank name"}
                                            inputPadding={"px-4 py-[9px]"}
                                        />
                                        <LoginInput
                                            value={formData?.card_holder_name}
                                            error={errors?.card_holder_name}
                                            name={'card_holder_name'}
                                            onChange={handleChange}
                                            title={'Account Title'}
                                            required={true}
                                            placeholder={"Enter account title"}
                                            inputPadding={"px-4 py-[9px]"}
                                        />
                                        <LoginInput
                                            required={true}
                                            name={'iban_number'}
                                            title={'Account IBAN'}
                                            onChange={handleChange}
                                            error={errors?.iban_number}
                                            value={formData?.iban_number}
                                            placeholder={"Enter Account IBAN"}
                                            inputPadding={"px-4 py-[9px]"}
                                            charactersMaxLength={16}
                                        />
                                    </div>
                                )
                            }
                        </div>
                        <div className={`max-h-[22rem] w-full`}>
                            <ImageUploader
                                customHeight={"!h-40"}
                                id={'agreement'}
                                onChange={handleChange}
                                name={'agreement'}
                                error={errors?.agreement}
                                value={formData?.agreement}
                                accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.pdf,.PDF,.doc,.DOC,.docx,.DOCX"}
                                title={'Upload partnership agreement'}
                                heading={'Partnership Agreement'}
                                hideEdit={(editData?.agreement || user?.agreement) ? true : false}
                            />
                        </div>


                        {/* <div className="w-full">
                            <CheckBox
                                checked={formData?.edit_access}
                                error={errors?.edit_access}
                                name={'edit_access'}
                                onChange={handleChange}
                                required={true}
                                label={'Authorize admin to edit your profile'}
                            />
                        </div> */}
                        <div className="md:pt-5 pt-10 flex justify-end">
                            <Button
                                loading={loading}
                                onClick={handleSubmit}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <ChangeEmailPopup
                open={updateEmail}
                close={setUpdateEmail}
                email={formData?.email}
                onSuccess={requestOTP}
                btnLoading={OTPLoading}
                errors={errors}
                setIsEdit={setIsEdit}
                emailValidation={emailValidation}
            />

            <AddBusinessNamePopup
                open={addBusinessName}
                close={setAddBusinessName}
                setAddBusinessName={setAddBusinessName}
                getCuisineLoader={getBusinessNameLoader}
                handleChange={handleChange}
                data={businessData}
            />

        </>
    )
}

export default EditConciergeProfile