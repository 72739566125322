import Svgs from 'Assets/svgs'
import Image from 'Components/Elements/Image';
import React from 'react'

const ChatCard = ({ restaurant_name, profile, user_name, phone_code, phone_number, setPhoneNumberPopop, phoneNumber, whatsappNumber }) => {
    const handleWhatsAppRedirect = (phoneNumber) => {
        const url = `https://wa.me/${phoneNumber}`;
        window.open(url, '_blank');
    };

    return (
        <>
            <div className="border border-lightGrey bg-darkGrey flex items-center justify-between px-2 xl:px-4 py-2 xl:py-4 rounded-lg md:rounded-[20px]">
                <div className="pr-4">
                    <Image
                        customLoaderHeight={'!w-16 !h-16 lg:!h-24 lg:!w-24 !rounded-full'}
                        className="w-16 h-16 lg:h-24 lg:w-24 !bg-lightGrey rounded-full object-cover" src={profile} alt="user" showDummy={true}
                    />
                </div>
                <div className="flex sm:flex-col xl:flex-row items-start flex-1 xl:items-center gap-2 xl:gap-0 2xl:gap-2 w-full">
                    <div className="flex flex-col items-start w-full px-2 2xl:px-4 text-blueGrey pt-3">
                        <div>
                            {restaurant_name && <h4 className="font-semibold text-base 2xl:text-lg text-secondary">{restaurant_name}</h4>}
                            {user_name && <p className="text-white py-1">{user_name}</p>}
                        </div>
                        <p className="text-white">
                            {phoneNumber ? phoneNumber : `+${phone_code} ${phone_number}`}
                        </p>
                    </div>
                    <div className="flex flex-col items-center sm:flex-row xl:flex-col gap-4 sm:gap-2 xl:gap-4 w-10 2xl:w-14 xl:px-0 px-2">
                        <div className='cursor-pointer lg:min-w-11 min-w-8 lg:min-h-11 min-h-8 flex items-center justify-center bg-[#0E0E0E] rounded-full' onClick={() => handleWhatsAppRedirect(whatsappNumber)}>
                            <Svgs.WhatsAppIcon className={'lg:size-5 size-4 fill-[--secondary-color]'} fill={'var(--secondary-color)'} />
                        </div>
                        <div className='cursor-pointer lg:min-w-11 min-w-8 lg:min-h-11 min-h-8 flex items-center justify-center bg-[#0E0E0E] rounded-full' onClick={() => setPhoneNumberPopop(`${phoneNumber ? phoneNumber : +`${phone_code} ${phone_number}`}`)}>
                            <Svgs.ColoredPhoneIcon className={'lg:size-5 size-3 fill-[--secondary-color]'} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChatCard