import React from 'react'

const ProfileSideBar = ({ activeTab, setActiveTab, isParent }) => {
    return (
        <>
            <ul className="flex flex-col gap-3 text-blueGrey bg-darkGrey rounded-lg p-4 mt-2">
                <li
                    className={`${activeTab == 'profile' ? 'secondActiveLink' : 'secondLink'} px-4 py-2 rounded-lg md:rounded-[10px] cursor-pointer`}
                    onClick={() => { setActiveTab('profile') }}
                >
                    Edit Profile
                </li>
                <li
                    className={`${activeTab == 'business-hours' ? 'secondActiveLink' : 'secondLink'} px-4 py-2 rounded-lg md:rounded-[10px] cursor-pointer`}
                    onClick={() => { setActiveTab('business-hours') }}
                >
                    Business Hours
                </li>
                {isParent ?
                    <li
                        className={`${activeTab == 'change_password' ? 'secondActiveLink' : 'secondLink'} px-4 py-2 rounded-lg md:rounded-[10px] cursor-pointer`}
                        onClick={() => { setActiveTab('change_password') }}
                    >
                        Change Password
                    </li> : ""
                }
            </ul>
        </>
    )
}

export default ProfileSideBar