import { GET_ALL_ANNOUNCED_REWARDS, GET_ALL_PROMTIONS, GET_ALL_UPCOMMING_REWARDS, GET_COMMISSION_ANALYTICS, GET_DASHBOARD_DATA, GET_SUPPORT_REQUEST, GET_TEAM_MEMBERS, GET_TEAM_MEMBERS_DROPDOWN, GET_TRANSACTION, GET_WISHLIST, GET_WISHLIST_DETAIL } from "./ActionType";

export const getTransactionAction = (data) => {
    return {
        type: GET_TRANSACTION,
        payload: data
    }
};
export const getSupportReq = (data) => {
    return {
        type: GET_SUPPORT_REQUEST,
        payload: data
    }
};
export const getWishListActions = (data) => {
    return {
        type: GET_WISHLIST,
        payload: data
    }
};

export const getWishListDetailActions = (data) => {
    return {
        type: GET_WISHLIST_DETAIL,
        payload: data
    }
};

export const getAllPromotionAction = (data) => {
    return {
        type: GET_ALL_PROMTIONS,
        payload: data
    }
};
export const getAnnouncedRewards = (data) => {
    return {
        type: GET_ALL_ANNOUNCED_REWARDS,
        payload: data
    }
};
export const getUpcommingRewards = (data) => {
    return {
        type: GET_ALL_UPCOMMING_REWARDS,
        payload: data
    }
};
export const getAllDashboardData = (data) => {
    return {
        type: GET_DASHBOARD_DATA,
        payload: data
    }
};

export const getCommissionAnalyticAction = (data) => {
    return {
        type: GET_COMMISSION_ANALYTICS,
        payload: data
    }
};

export const getTeamMembers = (data) => {
    return {
        type: GET_TEAM_MEMBERS,
        payload: data
    }
};

export const getTeamMembersDropdown = (data) => {
    return {
        type: GET_TEAM_MEMBERS_DROPDOWN,
        payload: data
    }
};