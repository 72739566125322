import { GET_REFERRAL_COMMISSION_HISTORY, GET_REFERRAL_HISTORY } from "./ActionType";
const initialState = {
    history: [],
    pagination_data: {},
    referralHistoryData: [],
    referral_pagination_data: {},
}

const InviteFriendReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case GET_REFERRAL_HISTORY:
            let payloadHistoryData = payload?.history ? payload?.history : payload?.all_referral_transactions
            let update_data;
            if (payload?.pagination_data?.meta?.current_page !== 1) {
                update_data = [...state?.history, ...payloadHistoryData];
            } else {
                update_data = payloadHistoryData;
            }

            return {
                ...state,
                history: update_data,
                pagination_data: payload?.pagination_data?.meta
            }

        case GET_REFERRAL_COMMISSION_HISTORY:
            let payloadReferralHistoryData = payload?.history ? payload?.history : payload?.all_referral_transactions
            let update_data_referral;
            if (payload?.pagination_data?.meta?.current_page !== 1) {
                update_data_referral = [...state?.referralHistoryData, ...payloadReferralHistoryData];
            } else {
                update_data_referral = payloadReferralHistoryData;
            }

            return {
                ...state,
                referralHistoryData: update_data_referral,
                referral_pagination_data: payload?.pagination_data?.meta
            }

        default:
            return {
                ...state,
            };
    }
}

export default InviteFriendReducer