import Svgs from 'Assets/svgs'
import DashboardLayout from 'Components/DashboardLayout'
import DateRangePicker from 'Components/Elements/ReactDateRange'
import AdminDashBoardCards from 'Pages/Admin/MainDashbaord/Elements/AdminDashBoardCards'
import AdminDashboardCardShimmer from 'Utility/Shimmers/AdminDashboardCardShimmer'
import React from 'react'
import PaymentHistory from './PaymentHistory'
import ConceirgeCards from 'Components/ConceirgeCards'
import useRestaurantPayments from './helper'
import { currency } from 'Adapters/variables'
import BookingDetails from './BookingDetails'
import PayableOrderDetails from './PayableOrderDetails'
import ConceirgeCardShimmer from 'Utility/Shimmers/ConceirgeCardShimmer'
import Back from 'Components/Elements/Back'
import DashboardCard from 'Components/ConceirgeCards/DashboardCard'
import RestaurantPaymentCardShimmer from 'Utility/Shimmers/RestaurantPaymentCardShimmer'

const RestaurantPayments = () => {
    const {
        commissionDetails, commissionLoading, dueDetails, paymentDetails, setPaymentDetails, paidProof, errors, setPayLoader,
        openPopup, setOpenPopup, bookingData, bookingLoading, getBookingDetail, payClicked, setPayClicked, setPaidProof, navigate,
        payLoader, onProofChangeHandler, onSubmitPayment, endDate, setEndDate, startDate, setStartDate, duePaymentLoader
    } = useRestaurantPayments()

    return (
        <>
            <DashboardLayout active={'payment'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                    <div className="flex items-center gap-3 justify-between">
                        <Back
                            title={'Payment'} />
                        <div className="flex justify-end my-2 gap-4">
                            <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                maxDate={new Date()}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-4 mb-4 items-stretch">
                        <div className='col-span-1'>
                            <DashboardCard
                                title1={
                                    'Current Month Booking Stats'
                                }
                                customPadding={'pt-10 px-0 py-0'}
                                contentWrapperStyle={'flex flex-col-reverse justify-center items-center gap-3 py-0 pb-2 text-center'}
                                icon1={<Svgs.PercentageV2 />}
                                iconWrapperStyle={'size-12'}
                                className={"h-full bg-lightGrey"}
                                text1Class={"!text-base !text-blueGrey"}
                                onAllClick={() => {
                                    getBookingDetail('current')
                                    setOpenPopup({
                                        type: 'this month',
                                        title: "Current Month Booking Stats",
                                        status: 'pending'
                                    })
                                }}
                            />
                        </div>
                        <div className='col-span-1'>
                            <DashboardCard
                                title1={'Booking Stats Since Joining '}
                                contentWrapperStyle={'flex flex-col-reverse justify-center items-center gap-3 py-0 pb-2 text-center'}
                                customPadding={'pt-10 px-0 py-0'}
                                icon1={<Svgs.TotalEarned />}
                                iconWrapperStyle={'size-12'}
                                className={"h-full bg-lightGrey "}
                                text1Class={"!text-base !text-blueGrey"}
                                onAllClick={() => {
                                    getBookingDetail()
                                    setOpenPopup({
                                        type: 'so far',
                                        title: "Booking Stats Since Joining",
                                        status: 'partialy paid'
                                    })
                                }}
                            />
                        </div>

                        <div className='xs:col-span-2 col-span-1'>
                            {commissionLoading
                                ? <>
                                    <ConceirgeCardShimmer title2={true} showViewDetail={false} childWrapperStyle={'py-10 flex items-center justify-center flex-col mx-auto'} customPadding={"p-2 pt-10"} />
                                </>
                                : <>
                                    <DashboardCard
                                        customPadding={'pt-10 px-0 py-0 text-center'}
                                        amountStyle={'!text-xl !font-semibold mx-auto'}
                                        title1={"Total booking commission payable"}
                                        amount1={commissionDetails?.total_payable}
                                        className={"bg-lightGrey"}
                                        text1Class={"!text-base !text-blueGrey"}
                                        showViewDetail={false}
                                        title2={'Total booking commission paid'}
                                        amount2={commissionDetails?.total_paid}
                                    />
                                </>
                            }
                        </div>
                    </div>
                    <div className='grid lg:grid-cols-2 grid-cols-1 gap-4'>
                        <div className="flex flex-col gap-5">
                            <h3 className="text-white font-semibold lg:text-2xl sm:text-xl text-lg py-2">Commission Checkout</h3>
                            {duePaymentLoader ?
                                <RestaurantPaymentCardShimmer className={'!rounded-[20px]'} />
                                :
                                <>
                                    <ConceirgeCards
                                        className={'!rounded-[20px]'}
                                        showDetailBtn={true}
                                        title1={'Bill Amount'}
                                        title2={'Total payable commission'}
                                        heading={'Commission Percentage'}
                                        headingAmount={`${dueDetails?.restaurant?.admin_percentage}%`}
                                        amount1={dueDetails?.total_billed_amount ? dueDetails?.total_billed_amount + currency : 0}
                                        amount2={dueDetails?.commission_due && dueDetails?.commission_due + currency}
                                        unpaid={dueDetails?.order_count}
                                        showDetails={true}
                                        onDetailClick={() => {
                                            setPaymentDetails(true)
                                        }}
                                        bgColor={'border !w-full bg-[#fff]'}
                                        startDate={startDate}
                                        endDate={endDate}
                                    />
                                </>
                            }
                        </div>
                        <PaymentHistory
                            className={'!h-fit'}
                            InfiniteScrollStyle={'px-0'}
                            noPagination={true}
                            titleClassName={'text-white font-semibold lg:text-2xl sm:text-xl text-lg py-2'}
                            // viewAllIcon={<Svgs.EyeHide fill={'--darkGrey-color'} className={'!fill-[--darkGrey-color]'} />}
                            viewAllStyle={'bg-secondary text-darkGrey !px-4 !py-2'} customGrid={"grid"}
                        />
                    </div>

                </main>
                <BookingDetails
                    open={openPopup}
                    close={setOpenPopup}
                    data={bookingData}
                    loading={bookingLoading}
                />

                <PayableOrderDetails
                    open={paymentDetails}
                    close={setPaymentDetails}
                    data={dueDetails}
                    payClicked={payClicked}
                    onCardClick={(number) => {
                        navigate(`/restaurant/booking?reservation=${number}`)
                    }}
                    errors={errors}
                    setPayClicked={setPayClicked}
                    btnLoading={payLoader}
                    setPaidProof={setPaidProof}
                    paidProof={paidProof}
                    setPayLoader={setPayLoader}
                    onSubmitPayment={onSubmitPayment}
                    onChange={onProofChangeHandler}
                    heading={"Pay commission to platform"}
                />
            </DashboardLayout>

        </>
    )
}

export default RestaurantPayments