import { getAdminPromotionsData } from "Adapters/APIs/Admin/promotions";
import { boostPromotionPackage, buyPromotionPackage, getActivePackages } from "Adapters/APIs/Restaurant/Promotion";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useRestaurantPromotions = (promotionType, promotion_id, onSuccessCallBack) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage("user_data")

    const { errors, setErrors, validation } = useValidations()

    // states
    const [loader, setLoader] = useState(true)
    const [packages, setPackages] = useState([])
    const [detail, setDetail] = useState()
    const [type, setType] = useState('all')
    const [promotion, setPromotion] = useState()
    const [submitLoading, setSubmitLoading] = useState(false)

    // get admin  data
    const getPackages = (noLoader) => {
        !noLoader && setLoader(true)
        let payload = {
            type: promotionType ? promotionType : 'restaurant',
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : ""
        }
        const success = (data) => {
            type == 'all'
                ? setPackages(data?.packages)
                : setPackages(data?.packages?.map(itm => { return itm?.package }))
            setLoader(false)
        }
        const fail = () => {
            setPackages([])
            setLoader(false)
        }
        type == 'all'
            ? dispatch(getAdminPromotionsData(access_token, payload, success, fail))
            : dispatch(getActivePackages(access_token, "", success, fail))
    }

    // buy package change handler
    const onChangeHandler = (e) => {
        const { name, value, files } = e?.target
        if (name == 'proof') {
            setPromotion({ ...promotion, [name]: files[0] })
            setErrors({ ...errors, [name]: '' })
        } else {
            setErrors({ ...errors, [name]: '' })
            setPromotion({ ...promotion, [name]: value })
        }
    }

    // On package but click
    const onPayClick = () => {
        let req = {
            start_date: promotion?.start_date,
            package_id: detail?.id,
            proof: promotion?.proof?.name ? true : false,
        }

        const result = validation(req)
        setErrors(result)
        if (objectKeyConvertToArray(result)?.length === 0) {
            setSubmitLoading(true)
            const success = (data) => {
                setDetail()
                setPromotion()
                onSuccessCallBack && onSuccessCallBack()
                setSubmitLoading(false)
            }
            const fail = () => {
                setSubmitLoading(false)
            }

            const payload = {
                promotion_id: promotion_id,
                start_date: promotion?.start_date,
                end_date: moment(promotion?.start_date).add(detail?.duration, 'days').format('YYYY-MM-DD'),
                package_id: detail?.id,
                proof: promotion?.proof,
                 restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : ""
            }
            promotion_id
                ? dispatch(boostPromotionPackage(access_token, payload, success, fail))
                : dispatch(buyPromotionPackage(access_token, payload, success, fail))
        }
    }

    useEffect(() => {
        setLoader(true)
        getPackages()
    }, [type])

    return {
        navigate, loader, packages, type, setType, detail, setDetail,
        onChangeHandler, promotion, setPromotion, errors, submitLoading, onPayClick
    }
}

export default useRestaurantPromotions