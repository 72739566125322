import Popup from 'Components/Elements/Popup'
import React from 'react'
import useRestaurantRatingPupup from './helper'
import Svgs from 'Assets/svgs'
import ActiveCard from './ActiveCard'
import LoginInput from 'Components/Elements/LoginInput'
import StarRatings from './StarsRating'
import Button from 'Components/Elements/Button'

const RestaurantRatingPupup = ({ open, close, loading, handleSubmit, ratingData, setRatingData, errors, setErrors, validateWebsiteUrl }) => {
    const { currentTab, setCurrentTab } = useRestaurantRatingPupup(ratingData)

    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'md:w-[40vw] lg:w-[30vw] max-w-96'}
            heading={'Add Rating'}
        >
            <div className='flex flex-col gap-3'>
                <div className='flex gap-2 items-center justify-between flex-wrap'>
                    <ActiveCard
                        heading={'TripAdvisor'}
                        svgIcon={<Svgs.TripAdvisor />}
                        active={currentTab == 'tripadvisor'}
                        onClick={() => {
                            setCurrentTab('tripadvisor')
                            const matchedData = ratingData?.find(itm => itm?.type == 'tripadvisor')
                            if (!matchedData) {
                                ratingData?.push({
                                    rating: '0',
                                    type: 'tripadvisor',
                                    url: ''
                                })
                            }
                        }}
                    />
                    <ActiveCard
                        heading={'Google'}
                        svgIcon={<Svgs.GoogleIcon />}
                        active={currentTab == 'google'}
                        onClick={() => {
                            setCurrentTab('google')
                            const matchedData = ratingData?.find(itm => itm?.type == 'google')
                            if (!matchedData) {
                                ratingData?.push({
                                    rating: '0',
                                    type: 'google',
                                    url: ''
                                })
                            }
                        }}
                    />
                </div>
                <StarRatings
                    title={'Select Rating'}
                    value={ratingData?.find(itm => itm?.type == currentTab)?.rating}
                    name={'rating'}
                    onChange={(e) => {
                        const updated = ratingData?.map(itm => {
                            if (itm?.type == currentTab) {
                                return {
                                    ...itm,
                                    rating: e?.target?.value
                                }
                            } else {
                                return {
                                    ...itm
                                }
                            }
                        })
                        setRatingData(updated)
                        setErrors({ ...errors, [currentTab + '_rating']: "" })
                    }}
                />
                <div className='text-end'>
                    {errors && errors[currentTab + '_rating'] && (
                        <p className="text-red-500 text-sm mt-1">{errors[currentTab + '_rating']}</p>
                    )}
                </div>
                <div className="flex">
                    <LoginInput
                        parentClass={'flex-1'}
                        value={ratingData?.find(itm => itm?.type == currentTab)?.link}
                        error={errors?.[currentTab + '_link']}
                        onChange={(e) => {
                            if (validateWebsiteUrl(e?.target?.value)) {
                                setErrors({ ...errors, [currentTab + '_link']: "" })
                            } else {
                                setErrors({ ...errors, [currentTab + '_link']: "Please Enter a valid URL" })
                            }
                            const updated = ratingData?.map(itm => {
                                if (itm?.type == currentTab) {
                                    return {
                                        ...itm,
                                        link: e?.target?.value
                                    }
                                } else {
                                    return {
                                        ...itm
                                    }
                                }
                            })
                            setRatingData(updated)
                        }}
                        required={true}
                        title={'Review Link'}
                        placeholder={'Enter link here'} />
                </div>
                <div className='pt-3'>
                    <Button
                        loading={loading}
                        title={'Save'}
                        customPadding={'py-2 px-4 w-full'}
                        onClick={() => {
                            if (errors.tripadvisor_link) {
                                setCurrentTab('tripadvisor')
                            } else if (errors.google_link) {
                                setCurrentTab('google')

                            } else {
                                handleSubmit && handleSubmit()
                            }
                        }}
                    />
                </div>

            </div>
        </Popup>
    )
}

export default RestaurantRatingPupup