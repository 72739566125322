import React from 'react'
import Shimmer from './Shimmer'

const FinancialCardShimmer = () => {
    return (
        <Shimmer>
            <div
                className={`bg-darkGrey cursor-pointer overflow-hidden relative rounded-lg md:rounded-[20px] flex flex-col border border-secondary items-center justify-between animate-pulse`}
            >
                <div className='flex flex-col border-b border-lightGrey text-blueGrey w-full p-3 mx-auto gap-1'>
                    <div className="bg-gray-500 h-8 w-2/3 rounded-md"></div>
                </div>
                <div className="flex flex-col relative gap-3 items-center justify-between h-full w-full pt-8 pb-14 px-5">

                    <div className="flex justify-between w-full text-blueGrey gap-2">
                        <div className='flex flex-col items-center w-11/12 mx-auto gap-2'>
                            <div className="bg-gray-500 h-[1.75rem] w-full rounded-md"></div>
                            <div className="bg-gray-500 h-[1.5rem] w-full rounded-md"></div>
                        </div>
                        <div className='flex flex-col items-center w-11/12 mx-auto gap-2'>
                            <div className="bg-gray-500 h-[1.75rem] w-full rounded-md"></div>
                            <div className="bg-gray-500 h-[1.5rem] w-full rounded-md"></div>
                        </div>

                    </div>

                </div>
                    <div className='absolute top-0 right-0 bg-gray-500 py-[14px] px-[45px] rounded-lg lg:rounded-[20px] text-sm font-bold !rounded-tl-none !rounded-br-none lg:!rounded-tl-none lg:!rounded-br-none' />
            </div>
        </Shimmer>
    )
}

FinancialCardShimmer.defaultProps = {
    showViewDetail: true
}

export default FinancialCardShimmer