import Svgs from 'Assets/svgs';
import { error_message } from 'Constants/Functions/Variables';
import React from 'react';

const DateInput = ({
    required,
    title,
    name,
    placeholder,
    value,
    min,
    max,
    onChange,
    onBlur,
    error,
    inputPadding,
    readOnly,
    disabled,
    showBorder,
    inputClass,
    errorClass,
    parentClass
}) => {

    return (
        <div className={`${parentClass ? parentClass : ""} relative flex flex-col gap-2 `}>

            {title && (
                <div className={`${title === '' ? 'hidden' : ''} text-sm text-blueGrey flex items-center gap-1`}>
                    <div>{title}</div> {required && <span className="leading-[1] text-red-600"> *</span>}
                </div>
            )}
            <div className="w-full relative">
                <input
                    id="inputId"
                    type="date"
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={placeholder ? placeholder : "mm/dd/yyyy"}
                    min={min}
                    max={max}
                    onClick={(e) => {
                        if (typeof e.target.showPicker === 'function') {
                            e.target.showPicker();
                        }
                    }}
                    className={`${error ? " border-[1.5px] border-error" : showBorder ? "border-[1.5px] border-lightGrey" : ""} ${(readOnly || disabled) && 'cursor-not-allowed !bg-gray-800'} ${inputPadding ? inputPadding : "px-4 py-2"} min-h-10 rounded-[10px] w-full bg-darkGrey placeholder:text-blueGrey text-base focus:outline-none ${inputClass}`}
                    autoComplete="off"
                />
                {error && typeof error === "string" ? (
                    <p className={`text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end ${errorClass}`}>
                        <Svgs.I fill="#eb3b3b" />
                        {error}
                    </p>
                ) : typeof error === "boolean" && error === true ? (
                    <p className="text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                        <Svgs.I fill="#eb3b3b" /> {error_message}
                    </p>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

DateInput.defaultProps = {
    type: "text",
    required: true,
    value: "",
    onEnterSubmit: () => { },
    showBorder: true
};

export default DateInput;
