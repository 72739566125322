import { createSupportRequest } from "Adapters/APIs/Concierge/SupportRequest"
import { convertImgToUrl, objectKeyConvertToArray } from "Hooks/useObjectKeys"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import useValidations from "Hooks/useValidations"
import { useState } from "react"
import { useDispatch } from "react-redux"

const useAddRequest = (close, onSuccess) => {
    const dispatch = useDispatch()
    const { errors, setErrors, validation } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage("user_data")

    // states
    const [loading, setLoading] = useState(false)
    const [request, setRequest] = useState({
        title: '',
        notes: '',
        image: '',
        type: ''
    })


    // handle close
    const handleClose = () => {
        close && close(false)
        setRequest({
            title: '',
            notes: '',
            image: '',
            type: ''
        })
    }

    const onChangeHandler = (e) => {
        const { name, value, files } = e?.target
        if (name == 'image') {
            setRequest({
                ...request,
                [name]: files?.length > 0 ? files[0] : {}
            })
            setErrors({
                ...errors,
                [name]: ''
            })
        } else if (name == 'remove') {
            setRequest({
                ...request,
                'image': {}
            })
            setErrors({
                ...errors,
                [name]: ''
            })
        } else {
            setRequest({
                ...request,
                [name]: value
            })
            setErrors({
                ...errors,
                [name]: ''
            })
        }
    }

    // create support request
    const onSubmit = () => {
        const { title, notes, type } = request
        const image = request?.image?.name ? convertImgToUrl(request?.image) : ''
        const err = validation({ title, notes, type })
        setErrors(err)
        if (objectKeyConvertToArray(err)?.length == 0) {
            setLoading(true)
            const success = () => {
                close && close(false)
                onSuccess && onSuccess(false)
                setLoading(false)
                setRequest({
                    title: '',
                    type: '',
                    notes: '',
                    image: '',
                })
            }
            const fail = () => {
                setLoading(false)
            }
            dispatch(createSupportRequest(access_token, { ...request, restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : "" }, success, fail))
        }
    }

    return {
        onChangeHandler, request, loading, onSubmit, errors, handleClose
    }

}

export default useAddRequest