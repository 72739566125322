import Svgs from 'Assets/svgs'
import DashboardCard from 'Components/ConceirgeCards/DashboardCard'
import Popup from 'Components/Elements/Popup'
import AdminDashBoardCards from 'Pages/Admin/MainDashbaord/Elements/AdminDashBoardCards'
import AdminDashboardCardShimmer from 'Utility/Shimmers/AdminDashboardCardShimmer'
import ConceirgeCardShimmer from 'Utility/Shimmers/ConceirgeCardShimmer'
import React from 'react'

const BookingDetails = ({ open, close, data, loading }) => {
    return (
        <>
            <Popup
                open={open}
                close={close}
                heading={
                    <div className='flex flex-col'>
                        <h1>{open?.title ? open?.title : `Booking ${open?.type}`}</h1>
                        {loading
                            ? <div className='h-4 w-52 rounded bg-gray-200' />
                            : <p className='!text-base font-normal text-blueGrey'>
                                Payment: {' '}
                                <b>{data?.admin_earning_status}</b>
                            </p>
                        }
                    </div>
                }
            >
                {loading ?
                    <div className="grid grid-cols-2 gap-4">
                        {[0, 1, 2, 3]?.map(itm => {
                            return <ConceirgeCardShimmer showViewDetail={false} customPadding={"px-2 py-10"} />
                        })}
                    </div>
                    :

                    <div className="grid grid-cols-2 gap-4">
                        <DashboardCard
                            title1={"Total bookings"}
                            amount1={data?.current_booking_count}
                            className={"border border-secondary"}
                            text1Class={"!text-base !text-blueGrey"}
                            showViewDetail={false}
                            hideCurrency
                        />
                        <DashboardCard
                            title1={"Total Guest"}
                            amount1={data?.current_month_per_pax_count}
                            className={"border border-secondary"}
                            text1Class={"!text-base !text-blueGrey"}
                            showViewDetail={false}
                            hideCurrency
                        />
                        <DashboardCard
                            title1={"Total Sale"}
                            amount1={data?.current_month_total_bill}
                            className={"border border-secondary"}
                            text1Class={"!text-base !text-blueGrey"}
                            showViewDetail={false}
                        />
                        <DashboardCard
                            title1={"Total Commission"}
                            amount1={data?.current_month_admin_earning}
                            className={"border border-secondary"}
                            text1Class={"!text-base !text-blueGrey"}
                            showViewDetail={false}
                        />
                    </div>
                }
            </Popup>
        </>
    )
}

export default BookingDetails