import { getAnnoncedConciergeReward, getUpcommingConciergeReward } from 'Adapters/APIs/Concierge/Details'
import { getConciergeReferralData } from 'Adapters/APIs/Concierge/InviteFriend'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const useRewards = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')

    // redux states
    const state = useSelector(state => state)
    const announced_rewards = state?.concierge?.announced_rewards
    const upcomming_rewards = state?.concierge?.upcomming_rewards
    const history = state?.inviteFriend?.history
    const pagination_data = state?.inviteFriend?.pagination_data

    // states
    const [type, setType] = useState('conciergesOfTheMonth')
    const [loading, setLoading] = useState(true)
    const [upcomingLoading, setUpcomingLoading] = useState(true)
    const [winnerDetail, setWinnerDetail] = useState(false)
    const [secondLoader, setSecondLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)

    // get announce rewards
    const getAnnouncedRewards = () => {
        const success = () => { setLoading(false) }
        const fail = () => { setLoading(false) }
        setLoading(true)
        let payload = {}
        dispatch(getAnnoncedConciergeReward(access_token, payload, success, fail))
    }

    // get upcomming rewards
    const getUpcommingRewards = () => {
        const success = () => { setUpcomingLoading(false) }
        const fail = () => { setUpcomingLoading(false) }
        setUpcomingLoading(true)
        let payload = {}
        dispatch(getUpcommingConciergeReward(access_token, payload, success, fail))
    }

    // get referrals data
    const getReferralsData = () => {
        let payload = {
            page: currentPage,
            per_page: 10,
            status: "accepted",
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : ""
        }
        const success = () => {
            setLoading(false)
        }
        const fail = () => {
            setLoading(false)
        }
        if (currentPage == 1) {
            setLoading(true)
            setSecondLoader(false)
        } else {
            setSecondLoader(true)
        }
        dispatch(getConciergeReferralData(access_token, payload, success, fail))
    }

    useEffect(() => {
        setLoading(true)
        if (type == 'conciergesOfTheMonth') {
            getAnnouncedRewards()
            getUpcommingRewards()
        }
    }, [type])

    useEffect(() => {
        if (type !== 'conciergesOfTheMonth') {
            setCurrentPage(1)
            setLoading(true)
            setSecondLoader(false)
            getReferralsData()
        }
    }, [type, currentPage])

    return {
        type, setType, loading, announced_rewards, upcomming_rewards, upcomingLoading, navigate, winnerDetail, setWinnerDetail,
        history, pagination_data, secondLoader, currentPage, setCurrentPage
    }

}

export default useRewards