import { getTeamMembersData } from "Adapters/APIs/Concierge/TeamMembers";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

let timeOutId = undefined
const useManageTeamMembersHelper = () => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')

    // redux state
    const state = useSelector(state => state)
    const team_members = state?.concierge?.team_members
    const pagination = state?.concierge?.team_members_pagination

    // states
    const [currentPage, setCurrentPage] = useState(1)
    const [addMember, setAddMember] = useState(false)
    const [editData, setEditData] = useState(false)
    const [loading, setLoading] = useState(true)
    const [secondLoader, setSecondLoader] = useState(false)
    const [searchText, setSearchText] = useState("")

    // success callback
    const successCallback = () => {
        setLoading(true)
        setCurrentPage(1)
        setSearchText("")
        getTeamMembers()
        setAddMember(false)
        setEditData(false)
    }

    // get team members data
    const getTeamMembers = () => {
        const success = () => {
            setLoading(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoading(false)
            setSecondLoader(false)
        }
        if (currentPage == 1) {
            setLoading(true)
        } else {
            setSecondLoader(true)
        }
        let payload = {
            page: currentPage,
            per_page: 10,
            search_text: searchText,
            user_id: user_data?.id
        }
        dispatch(getTeamMembersData(access_token, payload, success, fail))
    }

    useEffect(() => {
        if (searchText === null) {
            getTeamMembers()
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                getTeamMembers()
            }, 500);
        }
    }, [searchText, currentPage])

    useEffect(() => {
        setCurrentPage(1)
    }, [searchText])

    return {
        currentPage, setCurrentPage, addMember, setAddMember, editData, setEditData, team_members, pagination, loading, searchText,
        setSearchText, secondLoader, successCallback
    }
}

export default useManageTeamMembersHelper