import React from 'react'
import DashboardLayout from 'Components/DashboardLayout'
import Back from 'Components/Elements/Back'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import NotFound from 'Utility/NotFound'
import InfiniteScroll from 'react-infinite-scroll-component'
import useAnnouncementHelper from './helper'
import AnnouncementCard from './Elements/AnnouncementCard'

const ConceirgeAnnouncement = () => {
    const { announcement, pagination, loading, currentPage, setCurrentPage, secondLoader } = useAnnouncementHelper()

    return (
        <>
            <DashboardLayout active={'announcement'}>
                <main className=" lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                    <div className="px-4 space-y-4">
                        <div className="flex justify-between items-center">
                            <Back title={'Announcement'} />
                        </div>
                    </div>
                    <div className="py-6">
                        <div className="relative border-t mx-4 py-4 border-lightGrey">
                            {loading
                                ? <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                                    {[0, 1, 2, 3, 4, 5]?.map(itm => {
                                        return <div className='animate-pulse bg-gray-400 p-4 sm:rounded-[20px] h-40'></div>
                                    })}
                                </div>
                                :
                                announcement?.length > 0 ?
                                    <InfiniteScroll
                                        dataLength={announcement?.length ? announcement?.length : 10} //This is important field to render the next data
                                        next={() => {
                                            setCurrentPage(currentPage + 1)
                                        }}
                                        hasMore={pagination && pagination?.current_page !== pagination?.total_pages}
                                        loader={secondLoader
                                            && <div className="flex justify-center items-center my-4">
                                                <PreloaderSm />
                                            </div>
                                        }
                                    >
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                                            {announcement?.map(itm => {
                                                return <AnnouncementCard
                                                    key={itm?.id}
                                                    title={itm?.name}
                                                    description={itm?.details ? itm?.details : ""}
                                                    date={`${itm?.start_date} ${itm?.time}`}
                                                />
                                            })}
                                        </div>
                                    </InfiniteScroll>
                                    : <NotFound />
                            }
                        </div>
                    </div>
                </main>
            </DashboardLayout>
        </>
    )
}
export default ConceirgeAnnouncement