import { currency } from 'Adapters/variables'
import React from 'react'

const RedeemHistoryCard = ({ status, amount, created_at, approved_at }) => {
    return (
        <div className='bg-darkGrey rounded-lg'>
            <div className='flex gap-2 justify-between items-center py-4 px-4'>
                <div className='text-blueGrey space-y-1'>
                    <p>Amount: <span className='text-white'>{amount} <span className="text-secondary">{currency}</span></span></p>
                    <p>Date: <span className='text-white'>{created_at}</span></p>
                    <p>Approval Date: <span className='text-white'>{approved_at}</span></p>
                </div>
                <span className={`${status == 'Accepted' ? 'placed' : "pending"} px-4 py-2 w-fit rounded-[10px] text-sm font-normal flex justify-center items-center`}>
                    {status}
                </span>
            </div>
        </div>
    )
}

export default RedeemHistoryCard