import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import useValidations from "Hooks/useValidations";
import { apiErrorMessage } from "Constants/Data/Errors";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { OtpVerification, resendOTP, verifyUpdateEmailOTP } from "Adapters/APIs/Authentication";
import Cookies from "js-cookie";
import { saveDefaultRoute } from "Utility/indexedDBUtils";

let COUNTDOWN = 60;
export const useHelper = (isReset, email, step, setStep, setEmailStep, isUpdate, isNew, onResendClick, addBusiness) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation()

    const inp_1 = useRef();
    const inp_2 = useRef();
    const inp_3 = useRef();
    const inp_4 = useRef();
    const recaptchaRef = useRef();

    // states
    const [otp, setOtp] = useState({
        num1: "",
        num2: "",
        num3: "",
        num4: "",
    });
    const [isFinished, setIsFinished] = useState(false);
    const [count, setCount] = useState(isFinished ? 0 : COUNTDOWN);
    const [loading, setLoading] = useState(false);
    const [userEmail, setUserEmail] = useState('')
    const [done, setDone] = useState(false)

    const { storeDataInLocalStorage, getDataFromLocalStorage, getDataFromSessionStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')
    const default_route = getDataFromLocalStorage('defaultRoute')

    const {
        errors,
        onBlurHandler,
        onFocusHandler,
        removeWhiteSpacesAndSpecialCharactors,
        // removeSpecialCharacterForMobileNumber,
    } = useValidations();

    // otp input
    useEffect(() => {
        let num_inps = document.querySelectorAll(".numberAddInp");
        num_inps.forEach((inp, i) => {
            inp.addEventListener("input", () => {
                const inputValue = removeWhiteSpacesAndSpecialCharactors(inp.value);
                if (inputValue && inputValue != "") {
                    if (i != num_inps.length - 1) {
                        num_inps[i + 1].select();
                    }
                }
            });
        });
    }, []);

    useEffect(() => {
        if (!isFinished) {
            setTimeout(() => {
                countDown();
            }, 1000);
        }
    }, [count, isFinished]);


    // Check for reset user Email
    useEffect(() => {
        if (addBusiness) {
            let businessData = getDataFromSessionStorage("businessData");
            if (businessData?.email) {
                setUserEmail(businessData?.email || email)
            }
        } else {
            let signupData = getDataFromSessionStorage("signupData");
            if (signupData?.email) {
                setUserEmail(signupData?.email || email)
            } else {
                // if there is isReset then don't navigate to previous screen
                if (!isReset)
                    navigate(-1)
            }
        }
    }, []);

    // start timer
    const startTimer = (sec) => {
        setIsFinished(false);
        setCount(sec);
    };

    // conntdown
    const countDown = () => {
        if (count !== 0) {
            setCount(count - 1);
        }
    };

    // request email otp
    const requestEmailOTP = () => {
        let signupData = getDataFromSessionStorage("signupData");
        setUserEmail(signupData?.email || email)

        let payload = {
            type: 'email',
            email: signupData?.email || email
        };
        const success = (response) => { }
        const fail = (response) => { }
        dispatch(resendOTP(payload, success, fail));
    }

    // resend OTP
    const resendPassswordOTP = (type) => {
        try {
            setOtp({
                num1: "",
                num2: "",
                num3: "",
                num4: "",
            });
            if (!type) {
                requestEmailOTP()
            }
            startTimer(COUNTDOWN);
        } catch (error) { }
    };

    // user login function
    const userLoginFunction = (data) => {
        storeDataInLocalStorage('access_token', data?.token ? data?.token : getDataFromLocalStorage('access_token'))
        storeDataInLocalStorage('user_data', data?.user)

        switch (data?.user?.role) {
            case 'Super Admin':
                storeDataInLocalStorage('defaultRoute', '/admin/dashboard')
                storeDataInLocalStorage('defaultRouteParent', '/admin')
                saveDefaultRoute('admin');
                if (!location.pathname.startsWith('/admin/')) {
                    navigate('/admin/dashboard');
                }
                break;
            case 'User':
                if (data?.user?.is_approved == 2) {
                    storeDataInLocalStorage('defaultRoute', '/concierge/edit-profile')
                    storeDataInLocalStorage('defaultRouteParent', '/concierge')
                    navigate('/concierge/edit-profile')
                } else {
                    storeDataInLocalStorage('defaultRoute', '/concierge/dashboard')
                    storeDataInLocalStorage('defaultRouteParent', '/concierge')
                    saveDefaultRoute('concierge');
                    if (!location.pathname.startsWith('/concierge/')) {
                        navigate('/concierge/dashboard');
                    }
                }
                break;
            case 'Admin':
                if (data?.user?.restaurant?.is_approved == 2) {
                    storeDataInLocalStorage('defaultRoute', '/restaurant/edit-profile')
                    storeDataInLocalStorage('defaultRouteParent', '/restaurant')
                    navigate('/restaurant/edit-profile')
                } else {
                    storeDataInLocalStorage('defaultRoute', '/restaurant/dashboard')
                    storeDataInLocalStorage('defaultRouteParent', '/restaurant')
                    saveDefaultRoute('restaurant');
                    if (!location.pathname.startsWith('/restaurant/')) {
                        if (data?.user?.restaurant?.is_new == 1) {
                            navigate('/restaurant/profile?activeTab=profile');
                        } else {
                            navigate('/restaurant/dashboard');
                        }
                    }
                }
                break;

            default:
                break;
        }
    }

    // Verify OTP Call Back
    let success = (response) => {
        setLoading(false);
        if (addBusiness) {
            navigate("/restaurant/business")
        } else {
            if (isNew) {
                onResendClick && onResendClick()
                storeDataInLocalStorage("user_data", response?.data?.user);
            } else {
                setEmailStep && setEmailStep(3)
            }
            if (step == 2) {
                setStep(3)
            } else {
                // let data
                // if (default_route == '/restaurant/dashboard') {
                //     data = response?.data?.user?.restaurant
                // } else {
                //     data = response?.data?.user
                // }
                // storeDataInLocalStorage("user_data", data);
                // storeDataInLocalStorage("userDetail", data);
                // if (response?.data?.token != undefined) {
                //     storeDataInLocalStorage("token", response?.data?.token);
                //     storeDataInLocalStorage("user_id", response?.data?.user?.id);
                // }
                userLoginFunction(response?.data)
                sessionStorage.removeItem("signupMobileNumber");
                sessionStorage.removeItem("signupData");
            }
        }
    };

    // Fail Call Back
    let fail = (err) => {
        setLoading(false);
        if (err?.response?.data?.status == 403) {
            if (!isReset) {
                setDone(true)
                // navigate("/login")
            }
        } else {
            toast.error(apiErrorMessage, { toastId: 'toast' });
        }
    };

    // handle submit otp for verification
    const verifyOtp = async () => {
        if (!isReset && getDataFromSessionStorage("signupData") === null || getDataFromSessionStorage("signupData") === undefined) {
            navigate("/signup");
        } else {
            setLoading(true);
            let signupData = getDataFromSessionStorage("signupData");
            let { num1, num2, num3, num4 } = otp;
            if ((num1 && num2 && num3 && num4)) {
                let verification_code = `${num1}${num2}${num3}${num4}`;
                let payload = {
                    type: 'email',
                    email: signupData?.email || email,
                    otp: verification_code,
                    isReset: isReset,
                };
                if (default_route == '/restaurant/dashboard') {
                    payload = {
                        ...payload,
                        restaurant_id: user_data?.restaurant?.id,
                        is_parent: user_data?.is_parent ? 1 : 0
                    }
                }
                if (isUpdate) {
                    dispatch(verifyUpdateEmailOTP(access_token, {
                        ...payload,
                        type: isNew ? 'new' : 'existing',
                        new_email: isNew ? email : ''
                    }, success, fail));

                } else {
                    dispatch(OtpVerification(payload, success, fail));
                }
            }
        }
    };

    // set verification code state here
    const onChangeHandler = (e) => {
        e.target.value = removeWhiteSpacesAndSpecialCharactors(e.target.value);
        const { name, value } = e.target;

        if (value.length > 1) return false;
        setOtp((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    return {
        navigate, resendPassswordOTP, userEmail, onBlurHandler, onFocusHandler, setOtp, onChangeHandler, verifyOtp, inp_1, inp_2,
        inp_3, inp_4, count, isFinished, otp, errors, recaptchaRef, loading, done, setDone
    };
};
