import { useState } from "react"

const useRestaurantFormHelper = (addBusiness) => {

    // states
    const [step, setStep] = useState(1)

    return {
        step, setStep
    }
}

export default useRestaurantFormHelper