import React from 'react'
import NotFoundImage from "../Assets/images/not_record_found.png"
import Image from 'Components/Elements/Image'
import Svgs from 'Assets/svgs'

const NotFound = () => {
    return (
        <div className='flex flex-col gap-3 justify-center items-center w-full my-10'>
            {/* <div className='w-32'>
                <Image src={NotFoundImage} className='w-full !bg-transparent h-full object-cover' alt="not found" />
            </div> */}
            {/* <Svgs.NotFound2 /> */}
            <Svgs.NoRecordFound />
            <h1 className='text-white font-semibold text-lg'>No record found</h1>
        </div>
    )
}

export default NotFound