import Button from 'Components/Elements/Button'
import FilePicker from 'Components/Elements/FilePicker'
import Popup from 'Components/Elements/Popup'
import React from 'react'

const ConfirmPayPopup = ({ open, close, btnLoading, onSubmit, errors, setErrors, proof, setProof, text }) => {
    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            heading={<div className="flex flex-col w-full">
                <h1 className='font-semibold text-white'>Total Bill Value</h1>
                <p className='font-medium text-white text-sm'>Proceed to payment</p>
            </div>}
        >
            <div className="flex flex-col w-full space-y-4 rounded-md">
                <FilePicker
                    title={'Attach transfer proof'}
                    required={true}
                    error={errors?.proof}
                    value={proof}
                    name={'proof'}
                    onChange={(e) => {
                        setErrors({ ...errors, proof: '' })
                        setProof(e?.target?.files[0])
                    }}
                    accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG"}
                    customClass={"attach-file-btn-signup"}
                    customValueClass={"border border-lightGrey"}
                />
                <Button
                    loading={btnLoading}
                    onClick={onSubmit}
                >
                    {text}
                </Button>
            </div>
        </Popup>
    )
}

export default ConfirmPayPopup