import { currency } from 'Adapters/variables'
import Button from 'Components/Elements/Button'
import Image from 'Components/Elements/Image'
import Popup from 'Components/Elements/Popup'
import React from 'react'

const PaymentDetailPopup = ({ open, close, name, amount, number, onSubmit, proof, loading }) => {
    return (
        <>
            <Popup
                open={open}
                close={close}
                heading={'Payment'}
                size={'xs'}
            >
                <div className="flex flex-col gap-5">
                    <h1 className='text-blueGrey'>
                        Hi {name}, {amount} {currency} received from Platform
                    </h1>
                    <div className="flex h-60">
                        <Image src={proof} showDummy={true} className='object-contain' />
                    </div>

                    <div className="pt-2 grid grid-cols-2 gap-3">
                        <Button
                            customThemeBtn={'view-edit-btn'}
                            onClick={() => { close && close(false) }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                onSubmit && onSubmit(number)
                            }}
                            loading={loading}
                        >
                            Receive
                        </Button>
                    </div>
                </div>
            </Popup >
        </>
    )
}

export default PaymentDetailPopup