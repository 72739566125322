import Svgs from 'Assets/svgs'
import moment from 'moment'
import React from 'react'

const ActivitLogsCard = ({ data }) => {
    return (
        <>
            <div className={`flex flex-col gap-3 p-4 bg-lightGrey text-white shadow justify-between rounded-xl`}>
                <div className="flex justify-between gap-3 items-center">
                    <div className="">
                        <Svgs.UserIcon2 />
                    </div>
                    <div className="flex flex-1 flex-col">
                        <h3 className='font-semibold text-lg'>{data?.user?.first_name}</h3>
                        <p className='text-sm font-light'>{data?.user?.email}</p>
                    </div>
                    <div className="flex text-secondary gap-2 font-bold text-xs bg-primary rounded-xl p-2 px-4">
                        <Svgs.CalenderV2/> <p>{moment(data?.created_at).format('DD-MM-YYYY')}</p>
                    </div>
                </div>
                <hr className='border-darkGrey' />
                <div className="grid grid-cols-1 2sm:grid-cols-2 gap-3 items-center">
                    <div className='flex gap-2 items-center p-1 rounded-xl justify-start'>
                        Before Status:
                        <span className="capitalize text-secondary text-base font-semibold">
                            {data?.old_value}
                        </span>
                    </div>
                    <div className='flex gap-2 items-center p-1 rounded-xl 2sm:justify-end'>
                        After Status:
                        <span className="capitalize text-secondary text-base font-semibold">
                            {data?.new_value}
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ActivitLogsCard