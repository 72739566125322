import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { getAllNotifications, getLoginUserData, makeRestaurantIsNewFalse } from 'Adapters/APIs/Authentication';
import useLogin from 'OnBaording/Login/helper';
import { saveDefaultRoute, saveRestaurantId } from 'Utility/indexedDBUtils';
import Cookies from 'js-cookie';
import useLogoutHelper from 'Hooks/useLogoutHelper';
import { playSound } from 'Utility/Howl';
import { switchBusinessProfile } from 'Adapters/APIs/Restaurant/Business';

const useAppHelper = () => {
    const { successCallback, failCallback } = useLogin()
    const { logoutUser } = useLogoutHelper()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const { getDataFromLocalStorage, storeDataInLocalStorage, removeDataFromSessionStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const userData = getDataFromLocalStorage('user_data')
    const userLocation = getDataFromLocalStorage('userLocation')

    // states
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [isInternetWorking, setIsInternetWorking] = useState(true);
    const [cookieValue, setCookieValue] = useState(Cookies.get('logoutUser'));
    const [notificationCookieValue, setNotificationCookieValue] = useState(Cookies.get('getNotificationData'));
    const [userCookieValue, setUserCookieValue] = useState(Cookies.get('getUserProfileData'));
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [switchProfile, setSwitchProfile] = useState(false)
    const [switchLoader, setSwitchLoader] = useState(false)
    const [swicthLoading, setSwitchLoading] = useState(false)
    const [clickActionUrlState, setClickActionUrlState] = useState(null);

    // Timer and sound effect logic
    useEffect(() => {
        const checkTimers = () => {
            const timers = JSON.parse(localStorage.getItem('timers')) || [];
            const currentTime = Date.now();

            // Filter the timers, removing any that have passed and playing sound for expired ones
            const updatedTimers = timers.filter((timer) => {
                if (currentTime >= timer.alertTime) {
                    playSound('reservationAlert');  // Play the sound when the timer expires
                    return false;  // Remove the expired timer
                }
                return true;  // Keep the timer if it hasn't expired yet
            });

            if (updatedTimers.length > 0) {
                // Update the timers in localStorage if there are still timers left
                localStorage.setItem('timers', JSON.stringify(updatedTimers));
            } else {
                // Remove the 'timers' key if all timers are done
                localStorage.removeItem('timers');
            }
        };

        // Set up an interval to check every second
        const interval = setInterval(checkTimers, 1000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // update network status on base of internet connection
        window.addEventListener('online', updateNetworkStatus);
        window.addEventListener('offline', updateNetworkStatus);

        return () => {
            window.removeEventListener('online', updateNetworkStatus);
            window.removeEventListener('offline', updateNetworkStatus);
        };
    }, []);


    useEffect(() => {
        if (!userLocation) {
            // Check if the browser supports Geolocation API
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        storeDataInLocalStorage('userLocation', `${latitude},${longitude}`)
                    },
                    (err) => {
                        console.error(err.message);
                    }
                );
            } else {
                console.error('Geolocation is not supported by this browser.');
            }
        }
    }, []);

    useEffect(() => {
        // check internet is working or not
        if (isOnline) {
            // get user data
            getUserData();
            if (userCookieValue) {
                Cookies.remove('getUserProfileData');
            }
        } else {
            setIsInternetWorking(false);
        }
    }, [isOnline, location.pathname, userCookieValue]);

    useEffect(() => {
        // routes conditions on basis of roles
        if (access_token || localStorage.getItem('access_token')) {
            switch (userData?.role) {
                case 'Super Admin':
                    if (!location.pathname.startsWith('/admin/')) {
                        navigate('/admin/dashboard');
                        saveDefaultRoute('admin');
                    }
                    break;
                case 'User':
                    if (!location.pathname.startsWith('/concierge/')) {
                        navigate('/concierge/dashboard');
                        saveDefaultRoute('concierge');
                    }
                    break;
                case 'Admin':
                    if (!location.pathname.startsWith('/restaurant/')) {
                        saveDefaultRoute('restaurant');
                        if (userData?.restaurant?.is_new == 1) {
                            dispatch(makeRestaurantIsNewFalse(access_token, { restaurant_id: userData?.restaurant?.id }, () => { }, () => { }))
                            navigate('/restaurant/profile?activeTab=profile');
                        } else {
                            navigate('/restaurant/dashboard');
                        }
                    }
                    break;
                default:
                    navigate("/login");
            }
        }
    }, [])

    useEffect(() => {
        const pathName = location.pathname;
        if (!pathName.includes('/concierge/restaurant')) {
            // Remove data from sessionStorage
            removeDataFromSessionStorage("filterData")
        }

        if (!pathName.includes('/restaurant/business')) {
            // Remove data from sessionStorage
            removeDataFromSessionStorage("editBusinessData")
        }

        if (!pathName.includes('/restaurant/create-business')) {
            // Remove data from sessionStorage
            removeDataFromSessionStorage("businessData")
        }

        if (!pathName.includes('/concierge/business')) {
            // Remove data from sessionStorage
            removeDataFromSessionStorage("create_booking")
        }
    }, [location.pathname])

    useEffect(() => {
        // generateTestNotification() // open this for only test notification generation
        // get api for notification on every page change
        if (access_token) {
            getNotifications()
            if (notificationCookieValue) {
                Cookies.remove('getNotificationData');
            }
        }
    }, [access_token, location.pathname, notificationCookieValue])

    useEffect(() => {
        if(location.pathname.startsWith('/restaurant/')){
            // Check if the cookies are set and trigger the necessary actions
            const notificationClickUrl = Cookies.get('notificationClickUrl');
            const notificationRestaurantId = Cookies.get('notificationRestaurantId');
    
            // Store the clickActionUrl in state before removing cookies
            if (notificationClickUrl) {
                setClickActionUrlState(notificationClickUrl);
            }
    
            if (notificationRestaurantId) {
                // Switch profile and open popup
                switchToOtherBusiness(notificationRestaurantId); // Your logic for profile switch
                setSwitchProfile(true); // Open the popup in the new tab
            }
    
            return () => {
                // Cleanup if needed
            };
        }
    }, []);

    useEffect(() => {
        const channel = new BroadcastChannel('cookie_channel');

        channel.onmessage = (event) => {
            if (event.data.type === 'REMOVE_COOKIES') {
                // Remove cookies in all tabs
                Cookies.remove('notificationClickUrl');
                Cookies.remove('notificationRestaurantId');
            }
        };

        return () => {
            channel.close(); // Cleanup on component unmount
        };
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newNotificationCookieValue = Cookies.get('getNotificationData');
            if (newNotificationCookieValue !== notificationCookieValue) {
                setNotificationCookieValue(newNotificationCookieValue);
            }
        }, 1000); // Check every second

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [notificationCookieValue]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newCookieValue = Cookies.get('logoutUser');
            if (newCookieValue !== cookieValue) {
                setCookieValue(newCookieValue);
            }
        }, 1000); // Check every second

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [cookieValue]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newGetDataCookieValue = Cookies.get('getUserProfileData');
            if (newGetDataCookieValue !== userCookieValue) {
                setUserCookieValue(newGetDataCookieValue);
            }
        }, 1000); // Check every second

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [userCookieValue]);

    useEffect(() => {
        if (cookieValue) {
            logoutUser();
            Cookies.remove('logoutUser'); // remove the cookie after logout
        }
    }, [cookieValue]);

    useEffect(() => {
        // add restaurant id in indexedDB
        if (userData?.restaurant?.id) {
            saveRestaurantId(userData?.restaurant?.id)
        }
    }, [userData?.restaurant?.id])

    // get notification data
    const getNotifications = () => {
        const success = () => { }
        const fail = () => { }
        dispatch(getAllNotifications(access_token, { page: 1 }, success, fail))
    }

    // update network status
    const updateNetworkStatus = () => {
        setIsOnline(navigator.onLine);
    };

    // get user data
    const getUserData = async () => {
        const success = (data) => {
            setIsInternetWorking(true)
            if (data?.user?.is_active == 0 || data?.user?.restaurant?.is_active == 0 || data?.user?.is_deleted == 1) {
                logoutUser();
            }
            successCallback && successCallback(data, true)
        }
        const fail = (error) => {
            if (error?.response?.status != 401) {
                setIsInternetWorking(false)
                failCallback && failCallback(error)
            } else if (error?.response?.status == 401) {
                if (access_token) {
                    logoutUser();
                }
            } else {
                setIsInternetWorking(true)
            }
        }
        let payload = {}
        if (userData?.restaurant && userData?.restaurant?.id) {
            payload = {
                restaurant_id: userData?.restaurant?.id
            }
        }
        dispatch(getLoginUserData(access_token, payload, success, fail))
    };

    // handle switching business
    const handleSwitchingBusiness = () => {
        setSwitchLoader(true);
        successCallback && successCallback(switchProfile, true);

        // Use the stored state value instead of accessing cookies directly
        if (clickActionUrlState) {
            window.location.href = clickActionUrlState;
        } else {
            window.location.reload();
        }

        // Broadcast a message to all tabs to remove the cookies
        const channel = new BroadcastChannel('cookie_channel');
        channel.postMessage({ type: 'REMOVE_COOKIES' });
        channel.close(); // Close the channel
    }

    // switch to other business
    const switchToOtherBusiness = (data) => {
        const success = (data) => {
            setSwitchLoading(false);
            setSwitchProfile(data);
        }

        const fail = (error) => {
            setSwitchLoading(false);
        }

        let payload = { restaurant_id: data };
        setSwitchLoading(true);
        dispatch(switchBusinessProfile(access_token, payload, success, fail));
    }

    // handle Yes click
    const handleYesClick = () => {
        // Use the state value instead of cookies
        if (clickActionUrlState) {
            handleSwitchingBusiness()
        } else {
            console.error("Notification Click URL not found in state.");
        }

        // Close the popup
        setSwitchProfile(false);

        // Broadcast a message to all tabs to remove the cookies
        const channel = new BroadcastChannel('cookie_channel');
        channel.postMessage({ type: 'REMOVE_COOKIES' });
        channel.close(); // Close the channel
    }

    // handle No click
    const handleNoClick = () => {
        // Broadcast a message to all tabs to remove the cookies
        const channel = new BroadcastChannel('cookie_channel');
        channel.postMessage({ type: 'REMOVE_COOKIES' });
        channel.close(); // Close the channel

        // Close the popup
        setSwitchProfile(false);
    };


    return {
        isInternetWorking, isOnline, screenWidth, switchProfile, setSwitchProfile, handleSwitchingBusiness, switchLoader, handleYesClick,
        handleNoClick
    }
}

export default useAppHelper