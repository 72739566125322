import { addThreadComments, getConceirgeSuppReq, getSingleConceirgeSuppReq, reOpenSupportRequest } from "Adapters/APIs/Concierge/SupportRequest";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useSupportRequest = () => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // redux state
    const state = useSelector(state => state)
    const support_requests = state?.concierge?.support_requests
    const pagination = state?.concierge?.supp_req_pagination

    // states
    const [currentPage, setCurrentPage] = useState(1)
    const [endDate, setEndDate] = useState();
    const [startDate, setStartDate] = useState();
    const [createReq, setCreateReq] = useState(false)
    const [openDetail, setOpenDetail] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingSingle, setLoadingSingle] = useState(true)
    const [supportDetail, setSupportDetail] = useState()
    const [commentLoading, setCommentLoading] = useState(false)
    const [loadingThread, setLoadingThread] = useState(false)
    const [status, setStatus] = useState('')

    // get concierge support request
    const getConceirgeSupportReq = (noLoader) => {
        if (currentPage == 1) {
            !noLoader && setLoading(true)
        }
        dispatch(getConceirgeSuppReq(access_token, {
            page: currentPage,
            end_date: endDate,
            start_date: startDate,
            status: status

        }, '', '', setLoading))
    }

    // get single request
    const getSingleRequest = (id, again) => {
        const success = (data) => {
            setSupportDetail(data?.request)
            setLoadingSingle(false)
            setLoadingThread(false)
        }
        const fail = () => {
            setLoadingSingle(false)
            setLoadingThread(false)
        }
        if (again)
            setLoadingThread(true)
        else
            setLoadingSingle(true)
        dispatch(getSingleConceirgeSuppReq(access_token, id, success, fail))
    }

    // add thread comment
    const addThreadComment = (id, comment, status) => {
        const success = () => {
            getSingleRequest(id, true)
            setCommentLoading(false)
        }
        const reOpenSuccess = () => {
            getConceirgeSupportReq()
            getSingleRequest(id, true)
            setCommentLoading(false)
        }
        const fail = () => {
            setCommentLoading(false)
        }
        setCommentLoading(true)
        if (status) {
            dispatch(reOpenSupportRequest(access_token, { id: id }, reOpenSuccess, fail))
        } else
            dispatch(addThreadComments(access_token, { id: id, message: comment }, success, fail))
    }

    useEffect(() => {
        getConceirgeSupportReq()
    }, [currentPage, endDate, status, startDate])

    useEffect(() => {
        setLoading(true)
        setCurrentPage(1)
    }, [startDate, endDate, status])

    return {
        currentPage, setCurrentPage, endDate, setEndDate, startDate, setStartDate, createReq, setCreateReq, openDetail, setOpenDetail,
        support_requests, pagination, loading, loadingSingle, getSingleRequest, getConceirgeSupportReq, supportDetail, commentLoading,
        addThreadComment, loadingThread, setStatus, status
    }
}

export default useSupportRequest