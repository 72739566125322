import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import DateInput from 'Components/Elements/DateInput'
import FilePicker from 'Components/Elements/FilePicker'
import Image from 'Components/Elements/Image'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import { error_message } from 'Constants/Functions/Variables'
import moment from 'moment'
import React, { useState } from 'react'

const PromotionDetailPopup = ({ open, close, title, type, description, duration, price, image, promotion, onChange, errors, submitLoading, onBtnClick }) => {
    const [step, setStep] = useState(1)
    const [error, setError] = useState()

    return (
        <Popup
            open={open}
            close={close}
            heading={
                step == 1
                    ? 'Boost your business'
                    : "Offer Payment"
            }
            size={'sm'}
        >
            {step == 1
                // Package Detail
                ? <div className="flex flex-col gap-4">
                    <h1 className="text-xl font-semibold text-white"> {title && title} </h1>
                    <div className='flex gap-3 items-center text-blueGrey'>
                        <span><Svgs.CalendarGreen fill={'var(--secondary-color)'} /></span>
                        {duration}
                    </div>
                    <p className='text-blueGrey text-sm'>{description}</p>
                    <div className='font-semibold text-base gap-2 flex text-white'>
                        Price:
                        <span className='text-blueGrey font-normal'>
                            {price} {currency}
                        </span>
                    </div>
                    <Image
                        src={image}
                        showDummy={true}
                        className={'h-80'}
                    />

                    {type == 'all' && <>
                        <DateInput
                            value={promotion?.start_date}
                            name={'start_date'}
                            error={error}
                            title={'Select Date'}
                            min={moment()?.format('YYYY-MM-DD')}
                            onChange={(e) => {
                                onChange && onChange(e)
                                setError()
                            }}
                        />
                        <Button
                            className={'mt-2'}
                            onClick={() => {
                                if (promotion?.start_date) {
                                    setStep(step + 2)
                                } else {
                                    setError(error_message)
                                }
                            }}
                            title={'Pay Now'}
                        />
                    </>
                    }
                </div>
                // Payment Confimation
                : <div className="flex flex-col gap-4">
                    <h1 className="text-base text-white"> Proceed to payment </h1>
                    <FilePicker
                        required={true}
                        title="Attach transfer proof"
                        value={promotion?.proof}
                        name={'proof'}
                        error={errors?.proof}
                        id={'proof'}
                        accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.doc,.DOC,.docx,.DOCX"}
                        onChange={onChange}
                    />
                    <LoginInput
                        title={'Bill amount'}
                        required={false}
                        value={price}
                        disabled={true}
                        leftIcon={currency}
                    />
                    <Button className={'mt-2'}
                        loading={submitLoading}
                        onClick={onBtnClick}
                    >
                        Submit
                    </Button>

                </div>
            }
        </Popup>
    )
}

export default PromotionDetailPopup