import { BASE_URL, approve_promotion_purchase_status, get_purchased_packages } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";
import axios from "axios";
import { toast } from "react-toastify";

// get admin approved/unapproved promotions/packages
export const getAdminPurchasedPackages = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_purchased_packages}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } 
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail(error)
        return error;
    }
}

// update pending promotion status
export const updatePendingPromotionsStatusStatus = (access_token, payload, success, fail) => async (dispatch) => {
    const formData = convertDataToFormData(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${approve_promotion_purchase_status}`, formData, headers);
        if (response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, { toastId: 'toast' })
        }
    } catch (error) {
        if (error?.response?.status == 422) {
            toast.error(error?.response?.data?.message, { toastId: 'toast' })
        } else {
            toast.error(apiErrorMessage, { toastId: 'toast' })
        }
        fail && fail()
        return error;
    }
}
