import { BASE_URL, add_reward, announce_reward, get_announced_rewards_url, get_top_performs, get_unannounced_rewards_url, update_reward } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { getAdminAnnouncedRewards, getAdminUpcommingRewards } from "../../../Redux/Admin/Rewards/Action";
import axios from "axios";
import { toast } from "react-toastify";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";

// get admin announced rewards data
export const getAdminAnnoncedReward = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_announced_rewards_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            dispatch(getAdminAnnouncedRewards(data))
            success && success()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
        return error;
    }
}

// get admin upcomming/unannounced rewards data
export const getAdminUpcommingReward = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_unannounced_rewards_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            dispatch(getAdminUpcommingRewards(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
        return error;
    }
}


// update or add admin rewards
export const updateOrAddAdminReward = (access_token, payload, success, fail) => async (dispatch) => {
    const formData = convertDataToFormData(payload)
    let endUrl = payload?.id ? update_reward : add_reward
    let editId = payload?.id ? payload?.id : ""
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${endUrl}${editId}`, formData, headers);
        if (response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, { toastId: 'toast' })
        }
    } catch (error) {
        if (error?.response?.status == 404) {
            toast.error(error?.response?.data?.message, { toastId: 'toast' })
        } else {
            toast.error(apiErrorMessage, { toastId: 'toast' })
        }
        fail && fail()
        return error;
    }
}

// get admin top performs data
export const getAdminTopPerformers = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_top_performs}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
        return error;
    }
}

// announce reward
export const announceAdminReward = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${announce_reward}${query}`, {}, headers);
        if (response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, { toastId: 'toast' })
        }
    } catch (error) {
        if (error?.response?.status == 404) {
            toast.error(error?.response?.data?.message, { toastId: 'toast' })
        } else {
            toast.error(apiErrorMessage, { toastId: 'toast' })
        }
        fail && fail()
        return error;
    }
}