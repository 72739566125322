import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import Dropdown from 'Components/Elements/Dropdown'
import Popup from 'Components/Elements/Popup'
import { Month_With_All } from 'Constants/Data/Months'
import React from 'react'

const CSVExportPopup = ({ open, close, title, onSubmit, data, setData, loading }) => {
    return (
        <Popup
            open={open}
            close={() => {
                close && close(false)
                setData({
                    month: "",
                    year: new Date().getFullYear().toString()
                })
            }}
            heading={title ? title : "Download Transaction Data"}
            customSizeStyle={'md:w-[40vw] lg:w-[30vw]'}
        >
            <div className='flex flex-col space-y-4 pt-5 pb-10'>
                <p className='text-blueGrey'>
                    Select the year & the month for which you want to download transaction data.
                </p>
                <div className='flex gap-2'>
                    <Dropdown
                        title={'Select Year'}
                        value={data?.year}
                        onChange={(e) => {
                            setData({
                                ...data,
                                year: e.target.value
                            })
                        }}
                        option={[
                            { label: 2024, value: 2024 },
                            { label: 2025, value: 2025 },
                            { label: 2026, value: 2026 },
                            { label: 2027, value: 2027 },
                            { label: 2028, value: 2028 },
                            { label: 2029, value: 2029 },
                            { label: 2030, value: 2030 }
                        ]}
                        name={'year'}
                        required={true}
                        placeholder={"Select Year"}
                    />
                    <Dropdown
                        title={'Select Month'}
                        value={data?.month}
                        onChange={(e) => {
                            setData({
                                ...data,
                                month: e.target.value
                            })
                        }}
                        option={Month_With_All}
                        name={'month'}
                        required={true}
                        placeholder={"Select Month"}
                    />
                </div>
            </div>
            <div className='w-full'>
                <Button
                    loading={loading}
                    onClick={() => {
                        onSubmit && onSubmit()
                    }}
                    className={"w-full"}>
                    <div className='flex gap-2 items-center w-full justify-center'>
                        <Svgs.ExportIcon width={"20"} height={"20"} />
                        Download CSV
                    </div>
                </Button>
            </div>
        </Popup>
    )
}

export default CSVExportPopup