import { UpdateFCMToken } from "Adapters/APIs/Authentication";
import { requestForToken } from "firebase.config";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const NotificationFCMHandler = () => {
    const dispatch = useDispatch();
    
    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage('access_token');
    const fcm_token = getDataFromLocalStorage('fcm_token');

    useEffect(() => {
        // Check if notification permission is already stored in localStorage
        const notificationPermission = localStorage.getItem('notificationPermission');

        // Set interval to check for notification permission and FCM token every 5 seconds
        const intervalId = setInterval(() => {
            // Request notification permission if not stored or still default
            if (!notificationPermission || notificationPermission === 'default') {
                askForNotificationPermission();
            } else if (Notification.permission === "granted" && access_token && !fcm_token) {
                // Generate FCM token if permission is granted and token does not exist
                requestFireBaseToken();
            }
        }, 1000); // Check every second

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [access_token, fcm_token]); // Dependencies to ensure re-run when tokens change

    // Function to request Firebase token
    const requestFireBaseToken = async () => {
        const success = (token) => {
            storeDataInLocalStorage('fcm_token', token);
            add_FCM_Tokens(token);
        };
        requestForToken(success);
    };

    // Function to add FCM token in the database
    const add_FCM_Tokens = (token) => {
        dispatch(UpdateFCMToken(access_token, {
            fcm_token: token,
            is_web: true
        }));
    };

    // Request notification permission
    const askForNotificationPermission = () => {
        if ('Notification' in window && Notification.permission === "default") {
            Notification.requestPermission().then((permission) => {
                // Store the permission in localStorage
                localStorage.setItem('notificationPermission', permission);

                if (permission === "granted" && access_token && !fcm_token) {
                    // Generate FCM token if permission is granted
                    requestFireBaseToken();
                }
            });
        }
    };

    return null;
};

export default NotificationFCMHandler;
