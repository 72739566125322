import { BASE_URL, get_concierge_announcements, get_restaurant_announcements } from "Adapters/variables";
import axios from "axios";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { getConceirgeAnnouncementData, getRestaurantAnnouncementData } from "../../Redux/Announcement/Actions";

// get restaurant announcement
export const getRestaurantAnnouncement = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_restaurant_announcements}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            dispatch(getRestaurantAnnouncementData(data))
        }
    } catch (error) {
        fail && fail()
        dispatch(getRestaurantAnnouncementData({
            announcements: [],
            pagination_data: {}
        }))
        return error;
    }
}

// get concierge announcement
export const getConciergeAnnouncement = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_concierge_announcements}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            dispatch(getConceirgeAnnouncementData(data))
        }
    } catch (error) {
        fail && fail()
        dispatch(getConceirgeAnnouncementData({
            announcements: [],
            pagination_data: {}
        }))
        return error;
    }
}