import { openDB } from 'idb';

const DB_NAME = 'HotelConcierge';
const DB_VERSION = 1;
const STORE_NAME = 'settings';

// Get all data from store (for reloading)
export async function getAllDataFromStore(storeName) {
  const db = await openDB(DB_NAME, DB_VERSION);
  const tx = db.transaction(storeName, 'readonly');
  const store = tx.objectStore(storeName);
  const allData = await store.getAll();  // Retrieves all entries
  await tx.done;
  return allData;
}

// Save defaultRoute to IndexedDB
export async function saveDefaultRoute(defaultRoute) {
  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    },
  });
  await db.put(STORE_NAME, defaultRoute, 'defaultRoute');
}

// Get defaultRoute
export async function getDefaultRoute() {
  const db = await openDB(DB_NAME, DB_VERSION);
  return db.get(STORE_NAME, 'defaultRoute');
}

// Save restaurant_id to IndexedDB
export async function saveRestaurantId(restaurantId) {
  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    },
  });
  await db.put(STORE_NAME, restaurantId, 'restaurantId');
}

// Retrieve restaurant_id from IndexedDB
export async function getRestaurantId() {
  const db = await openDB(DB_NAME, DB_VERSION);
  return db.get(STORE_NAME, 'restaurantId');
}

// Function to delete a key
export async function deleteKey(keyName) {
  try {
    const db = await openDB(DB_NAME, DB_VERSION);
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);

    // Delete the key
    await store.delete(keyName);
    await tx.done;

    console.log(`Key "${keyName}" deleted successfully from IndexedDB.`);
  } catch (error) {
    console.error(`Failed to delete key "${keyName}" from IndexedDB:`, error);
  }
}

// Function to clear all keys in the store
export async function clearStore() {
  try {
    const db = await openDB(DB_NAME, DB_VERSION);
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);

    // Clear the entire object store
    await store.clear();
    await tx.done;

    console.log(`All data cleared from store "${STORE_NAME}".`);
  } catch (error) {
    console.error(`Failed to clear store "${STORE_NAME}" from IndexedDB:`, error);
  }
}
