import PaymentCard from 'Components/PaymentCard'
import React from 'react'
import usePaymentsHistory from './helper'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import DateRangePicker from 'Components/Elements/ReactDateRange'
import moment from 'moment'
import DashboardLayout from 'Components/DashboardLayout'
import Svgs from 'Assets/svgs'
import PaymentCardShimmer from 'Utility/Shimmers/PaymentCardShimmer'
import NotFound from 'Utility/NotFound'
import Popup from 'Components/Elements/Popup'
import Image from 'Components/Elements/Image'
import PayableOrderDetails from '../PayableOrderDetails'
import ConfirmBooking from 'Pages/Concierges/Bookings/ConfirmBooking'
import ViewAllBtn from 'Components/Elements/ViewAllBtn'
import Back from 'Components/Elements/Back'

const PaymentHistory = ({ noPagination, title, viewAllStyle,titleClassName,InfiniteScrollStyle,className, subscription, viewAllIcon, data, customGrid }) => {
    const {
        navigate, loading, currentPage, pagination, payment, setCurrentPage, endDate, startDate, setEndDate, setStartDate,
        showProof, setShowProof, openDetails, setOpenDetails, bookingDetails, setBookingDetails
    } = usePaymentsHistory(subscription, data)
    return (
        <>
            <DashboardLayout active={subscription ? 'subscription-payments' : 'payment'}>
                <main className={`${noPagination ? "" : "py-4"} ${noPagination ? "" : "cursor-pointer lg:ml-72 md:ml-64 pt-20"} h-screen overflow-y-auto ${className ? className : ""}`}>
                    <div className={`${noPagination ? "" : "py-4"} flex flex-col`}>
                        <div className="px-4 flex items-center justify-between">
                            <Back
                                titleClassName={titleClassName}
                                hideIcon={noPagination}
                                title={title
                                    ? title
                                    : "Payments History"
                                }
                                noPadding={noPagination}
                            />
                            {noPagination
                                ?
                                <ViewAllBtn
                                    viewAllStyle={viewAllStyle}
                                    viewAllIcon={viewAllIcon}
                                    onClick={() => {
                                        subscription
                                            ? navigate('/restaurant/subscription-history')
                                            : navigate('/restaurant/payment-history')
                                    }}
                                />
                                : <DateRangePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                />
                            }
                        </div>
                        {loading
                            ? <div className={`gap-4 ${customGrid ? customGrid : "grid grid-cols-1 sm:grid-cols-2"} p-4`}>
                                {[0, 1, 2, 3, 4, 5]?.map(itm => {
                                    return <PaymentCardShimmer  className={'!rounded-[20px]'}/>
                                })}
                            </div>
                            : payment?.length > 0 ?
                                <InfiniteScroll
                                    dataLength={payment?.length ? payment?.length : 10} //This is important field to render the next data
                                    next={() => {
                                        !noPagination && setCurrentPage(currentPage + 1)
                                    }}
                                    className={`p-4 ${InfiniteScrollStyle ? InfiniteScrollStyle : ''}`}
                                    hasMore={pagination && pagination?.current_page !== pagination?.total_pages}
                                    loader={((pagination?.current_page !== pagination?.total_pages) && !noPagination)
                                        && <div className="flex justify-center items-center my-4">
                                            <PreloaderSm />
                                        </div>
                                    }
                                // height={!noPagination && currentPage == 1 ? '40rem' : ''}
                                >
                                    <div className={`gap-4 ${customGrid ? customGrid : "grid grid-cols-1 md:grid-cols-2"}`}>
                                        {payment?.map(itm => {
                                            return (
                                                <PaymentCard
                                                    onClick={() => { (!noPagination && !subscription) && setOpenDetails(itm?.reservations) }}
                                                    date={moment(itm?.created_at)?.format('DD MMM, YYYY')}
                                                    amount={itm?.amount}
                                                    image={itm?.proof}
                                                    status={itm?.is_received}
                                                    isSubscription={subscription}
                                                    setShowProof={setShowProof}
                                                    number={itm?.number}
                                                    className={'!rounded-[20px]'}
                                                />
                                            )
                                        })}
                                    </div>
                                </InfiniteScroll>
                                : <NotFound />
                        }
                    </div>
                </main>
                <Popup
                    open={showProof}
                    close={setShowProof}
                    heading={"Payment Proof"}
                >
                    <div className="flex max-h-64">
                        <Image
                            src={showProof}
                            alt={'Payment Proof'}
                            className={'rounded-xl object-contain'}
                        />
                    </div>
                </Popup>

                {openDetails?.length > 0 &&
                    <PayableOrderDetails
                        heading={'Bookings'}
                        open={openDetails}
                        bookingStatusSteps={
                            [{ name: 'Placed', value: 1 },
                            { name: 'Confirmed', value: 2 },
                            { name: 'Billed', value: 3 },
                            ]}
                        onCardClick={(number)=>{
                            navigate(`/restaurant/booking?reservation=${number}`)
                        }}
                        close={setOpenDetails}
                        hideButton={true}
                        data={{
                            bookings: openDetails
                        }}
                    />
                }

                {/* {bookingDetails &&
                    <ConfirmBooking
                        isRestaurant={true}
                        close={setBookingDetails}
                        data={bookingDetails}
                    />
                } */}
            </DashboardLayout>
        </>
    )
}

export default PaymentHistory