import React from 'react'

const RadioBtn = ({ onClick, checked, id, gap, text, value, name, error, className, Icon }) => {
    return (
        <div className={`flex items-center ${gap}`}>
            <input
                name={name ? name : "default-radio"}
                id={`radio-${id}`}
                onClick={onClick}
                checked={checked}
                type="radio"
                value={value}
                className={`w-4 h-4 bg-gray-100 border border-gray-300 outline-none focus:outline-none accent-secondary ${className ? className : ''}`}
                autoComplete="off"
            />
            <label
                for={`radio-${id}`}
                className={`ml-2 mt-0.5 text-sm font-medium ${error && '!text-red-500'}`}>
                {text}
            </label>
        </div>
    )
}

export default RadioBtn