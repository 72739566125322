import moment from 'moment'
import React from 'react'
import NotFound from 'Utility/NotFound'

const BookingAnalytics = ({ data }) => {

    return (
        <>
            <div className="overflow-x-auto rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right min-w-[600px]">
                    <thead class="text-sm text-white bg-lightGrey capitalize">
                        <tr>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                ID
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Date
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Status
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Guest Name
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Total Guest
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Total Bill
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Business Earning
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Commission
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Payable To Concierge
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Admin Earning
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 &&
                            data?.map(itm => {
                                return (
                                    <>
                                        <tr className="bg-darkGrey border-b border-lightGrey cursor-pointer text-blueGrey">
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.number}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {moment(itm?.date).format('DD-MM-YYYY')}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                <span className={`${itm?.status?.split(' ')[0]?.toLowerCase()} px-2 py-1 w-fit rounded-lg text-sm font-normal flex justify-center items-center`}>
                                                    {itm?.status}
                                                </span>
                                            </td>
                                            <td class="px-6 py-3 font-semibold whitespace-nowrap ">
                                                {itm?.guest_name}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.total_pax}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.amount}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.restaurant_earning}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.commission}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.manager_earning}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.platform_earning}
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                    </tbody>
                </table>
                {data?.length == 0 &&
                    <NotFound />
                }
            </div>
        </>
    )
}

export default BookingAnalytics