import { currency } from 'Adapters/variables'
import Button from 'Components/Elements/Button'
import FilePicker from 'Components/Elements/FilePicker'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import ConciergePayBookingCard from 'Pages/Admin/Concierges/SingleConcierge/Elements/ConciergePayBookingCard'
import React from 'react'

const PayableOrderDetails = ({ open, heading, onCardClick, close, data, hideButton, bookingStatusSteps, payClicked, errors, onSubmitPayment, paidProof, setPaidProof, setPayClicked, btnLoading, setPayLoader, onChange }) => {
    return (
        <>
            <Popup
                open={open}
                close={close}
                size={"sm"}
                heading={heading ? heading : 'Pay Now'}
            >
                {data?.bookings?.map((itm, ind) => (
                    <ConciergePayBookingCard
                        name={itm?.guest_name}
                        pax={itm?.total_pax}
                        onClick={(e) => {
                            onCardClick && onCardClick(itm?.number)
                            close()
                        }}
                        number={itm?.number}
                        bookingStatusSteps={bookingStatusSteps ? bookingStatusSteps : false}
                        date={itm?.date_time}
                        amount={hideButton ? '' : itm?.commission}
                        hideStatus={hideButton ? false : true}
                        status={itm?.status}
                    />
                ))}
                {!hideButton &&
                    <div className='mt-5'>
                        <Button
                            text={`Pay ${data?.vat_added_commission} ${currency} (${data?.commission_due} + ${data?.vat} VAT)`}
                            className={"w-full"}
                            onClick={() => {
                                setPaidProof && setPaidProof()
                                setPayLoader && setPayLoader(false)
                                setPayClicked && setPayClicked(true)
                            }}
                        />
                    </div>
                }
            </Popup>

            {payClicked &&
                <Popup
                    open={payClicked}
                    close={setPayClicked}
                    customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
                    heading={'Total Bill Value'}
                >
                    <div className="flex flex-col gap-4">
                        <h1 className="text-base text-white"> Proceed to payment </h1>
                        <FilePicker
                            required={true}
                            title="Attach transfer proof"
                            value={paidProof}
                            name={'paidProof'}
                            error={errors?.paidProof}
                            id={'paidProof'}
                            accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.pdf,.PDF,.doc,.DOC,.docx,.DOCX"}
                            onChange={onChange}
                        />
                        <LoginInput
                            title={'Payable Commission'}
                            required={false}
                            value={data?.vat_added_commission}
                            disabled={true}
                            leftIcon={currency}
                        />
                        <Button className={'mt-2'}
                            text={"Submit"}
                            loading={btnLoading}
                            disabled={btnLoading}
                            onClick={() => {
                                onSubmitPayment(() => {
                                    setPayClicked(false)
                                })
                            }}
                        />
                    </div>
                </Popup>
            }
        </>
    )
}

LoginInput.defaultProps = {
    payClicked: false,
};

export default PayableOrderDetails