import Button from 'Components/Elements/Button'
import React from 'react'
import useNotifications from './helper'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import Svgs from 'Assets/svgs'
import NotificationCard from './Element/NotificationCard'
import NotificationShimmer from 'Utility/Shimmers/NotificationShimmer'
import useNotificationRedirectionHelper from './NotificationRedirectionHelper'

const NotificationsDropdown = ({ open, setOpen }) => {
    const { notification, loading, pagination, currentPage, setCurrentPage, markAllAsRead, user_unread_count, markAsReadLoader,
        handleReadSingleNotification
    } = useNotifications(setOpen)
    const { redirectNotifications } = useNotificationRedirectionHelper(setOpen)
    return (
        <>
            <div className={`fixed inset-0 h-[100vh] top-[4.25rem] w-[100vw] z-50 flex justify-end`}>
                <div
                    className="bg-[#ffffff]/15 absolute inset-0 z-[1]"
                    onClick={() => { setOpen(false); }}
                ></div>
                <div className={`fixed mt-2 mx-5 bg-primary shadow-2xl rounded-2xl md:max-w-[35vw] w-11/12 z-[2]`}
                >
                    <div className={'gap-3 w-full rounded-2xl overflow-hidden'}>
                        <div className={"flex gap-4 items-center justify-between p-4"} >
                            <h1 className="font-semibold text-lg sticky text-white !my-0 flex items-center gap-2">
                                Notifications {loading ?
                                    <div className='bg-gray-500 rounded-md h-6 w-8'></div>
                                    : user_unread_count > 0 ? `(${user_unread_count})` : `(0)`}
                            </h1>
                            {user_unread_count > 0 ?
                                <Button
                                    customPadding="px-2 !text-sm !py-2 normal-case"
                                    onClick={() => {
                                        markAllAsRead()
                                    }}
                                    disabled={markAsReadLoader}
                                    loading={markAsReadLoader}
                                >
                                    Mark all as read
                                </Button>
                                : ""}
                        </div>
                        <div className="flex flex-col gap-4 pt-0 max-h-[70vh] overflow-hidden">
                            {loading ?
                                <div className="flex flex-col p-3 gap-3">
                                    {[0, 1, 2, 3, 4]?.map(itm => {
                                        return <NotificationShimmer />
                                    })}
                                </div>
                                :
                                <InfiniteScroll
                                    dataLength={notification?.length ? notification?.length : 10} //This is important field to render the next data
                                    next={() => { setCurrentPage(currentPage + 1) }}
                                    hasMore={pagination && pagination?.current_page !== pagination?.total_pages}
                                    loader={(pagination?.current_page !== pagination?.total_pages)
                                        && <div className="flex justify-center items-center my-4">
                                            <PreloaderSm />
                                        </div>
                                    }
                                    height={'40rem'}
                                    className={" top-full !m-0 !mt-1 bg-primary z-[180] max-h-[70vh] overflow-auto rounded-b-2xl"}>
                                    <div className="flex flex-col p-3 gap-3">
                                        {notification?.map((ele, index) => {
                                            const icon = index % 2 === 0
                                                ? <Svgs.CalenderFillIcon fill={'var(--secondary-color)'} background={'var(--lightGrey-color)'} />
                                                : <Svgs.PromotionFillIcon fill={'var(--secondary-color)'} background={'var(--lightGrey-color)'} />;
                                            return <>
                                                <NotificationCard
                                                    title={ele?.title}
                                                    message={ele?.message}
                                                    created_at={ele?.created_at}
                                                    read_at={ele?.read_at}
                                                    icon={icon}
                                                    navigate_url={redirectNotifications}
                                                    id={ele?.id}
                                                    type={ele?.type}
                                                    data={ele}
                                                    ids={ele?.notification_data}
                                                    handleReadSingleNotification={handleReadSingleNotification}
                                                />
                                            </>
                                        })}
                                    </div>
                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotificationsDropdown