import { deleteReferralInvite, getConciergeReferralData, getRedeemReferralData, redeemReferralEarning, sendNewReferral, sendReferralAgain } from "Adapters/APIs/Concierge/InviteFriend"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import Cookies from "js-cookie"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"

const useInviteFriendHelper = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams();
    const active_tab = searchParams.get('active_tab');

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')
    const defaultRoute = getDataFromLocalStorage('defaultRoute')

    // redux states
    const state = useSelector(state => state)
    const history = state?.inviteFriend?.history
    const pagination_data = state?.inviteFriend?.pagination_data

    // states
    const [activeTab, setActiveTab] = useState(active_tab || 'invite-history')
    const [loading, setLoading] = useState(true)
    const [secondLoader, setSecondLoader] = useState(false)
    const [emailLoader, setEmailLoader] = useState(false)
    const [resendLoader, setResendLoader] = useState(false)
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [redeemLoader, setRedeemLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [email, setEmail] = useState("")
    const [redeemClick, setRedeemClick] = useState(false)

    // submit new referral on new email
    const handleSubmitReferral = () => {
        let payload = {
            email: email,
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : ""
        }
        const success = () => {
            setEmailLoader(false)
            setEmail("")
            setCurrentPage(1)
            getReferralsData()
        }
        const fail = () => {
            setEmailLoader(false)
        }
        setEmailLoader(true)
        if (email && email?.toLowerCase()?.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            dispatch(sendNewReferral(access_token, payload, success, fail))
        } else {
            setEmailLoader(false)
            toast.error("Please enter a valid email address.", { toastId: 'toast' })
        }
    }

    // again send referral on email
    const handleResendReferral = (id, successCallBack) => {
        let payload = {
            id: id,
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : ""
        }
        const success = () => {
            setResendLoader(false)
            successCallBack && successCallBack()
        }
        const fail = () => {
            setResendLoader(false)
        }
        setResendLoader(true)
        dispatch(sendReferralAgain(access_token, payload, success, fail))
    }

    // delete pending referral
    const handleDeletePendingReferral = (id, successCallBack) => {
        let payload = {
            id: id,
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : ""
        }
        const success = () => {
            setDeleteLoader(false)
            successCallBack && successCallBack()
            setCurrentPage(1)
            setLoading(true)
            setSecondLoader(false)
            getReferralsData()
        }
        const fail = () => {
            setDeleteLoader(false)
        }
        setDeleteLoader(true)
        dispatch(deleteReferralInvite(access_token, payload, success, fail))
    }

    // get referrals data
    const getReferralsData = () => {
        let payload = {
            page: currentPage,
            per_page: 10,
            status: activeTab === 'invite-history' ? "" : "accepted",
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : ""
        }
        const success = () => {
            setLoading(false)
        }
        const fail = () => {
            setLoading(false)
        }
        if (currentPage == 1) {
            setLoading(true)
            setSecondLoader(false)
        } else {
            setSecondLoader(true)
        }
        dispatch(getConciergeReferralData(access_token, payload, success, fail))
    }

    // get redeem history data
    const getRedeemHistoryData = () => {
        let payload = {
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : ""
        }
        const success = () => {
            setLoading(false)
        }
        const fail = () => {
            setLoading(false)
        }
        if (currentPage == 1) {
            setLoading(true)
            setSecondLoader(false)
        } else {
            setSecondLoader(true)
        }
        dispatch(getRedeemReferralData(access_token, payload, success, fail))
    }

    // handle redeem amunt
    const redeemReferAmount = () => {
        let payload = {
            amount: user_data?.wallet,
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : ""
        }
        const success = () => {
            Cookies.set('getUserProfileData', true)
            setRedeemLoader(false)
            setRedeemClick(false)
        }
        const fail = () => {
            setRedeemLoader(false)
        }
        setRedeemLoader(true)
        dispatch(redeemReferralEarning(access_token, payload, success, fail))
    }

    
    useEffect(() => {
        if (active_tab) {
            setActiveTab(active_tab);
            
            // Replace the current URL without slot_id using the history API
            const newUrl = '/restaurant/invite-friend';
            window.history.replaceState(null, '', newUrl);
        }
    }, []);

    useEffect(() => {
        if (activeTab !== 'redeem-history') {
            setCurrentPage(1)
            setLoading(true)
            setSecondLoader(false)
            getReferralsData()
        } else {
            getRedeemHistoryData()
        }
    }, [activeTab, currentPage])

    return {
        navigate, activeTab, setActiveTab, loading, currentPage, setCurrentPage, user_data, history, pagination_data, secondLoader,
        handleSubmitReferral, email, setEmail, emailLoader, handleResendReferral, resendLoader, handleDeletePendingReferral,
        deleteLoader, redeemClick, setRedeemClick, redeemLoader, redeemReferAmount
    }
}

export default useInviteFriendHelper