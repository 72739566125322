
import useResetPassword from './helper'
import PasswordInput from 'Components/Elements/PasswordInput'
import Button from 'Components/Elements/Button'
import Svgs from 'Assets/svgs'
import Back from 'Components/Elements/Back'


const ResetPassword = ({ step, setStep }) => {

    const { done, navigate, resetPassword, loader, errors, handleChange, changePasswordWithEmail,
    } = useResetPassword({ step, setStep })
    const { password, password_confirmation } = resetPassword;
    return (
        <>
            {
                !done ? <>
                    <div className='flex flex-col gap-5'>
                        <div>
                            <Back
                                onClick={() => {
                                    if (step == 3) {
                                        setStep(1)
                                    } else {
                                        setStep(-1)
                                    }
                                }}
                                fill={'var(--secondary-color)'}
                                title={'Forgot Password'}
                                titleClassName={'font-bold text-[1.5rem] text-white'}
                            />
                            <p className="text-white w-full text-sm mt-2">Kindly re-enter your new password and confirm it.  So that you can easily access your account.</p>
                        </div>
                        <div className={'flex flex-col gap-5'}>
                            <PasswordInput
                                title={'New Password'}
                                placeholder={'Enter your new password'}
                                name="password"
                                label="Password"
                                value={password}
                                onChange={
                                    (e) => {
                                        handleChange(e)
                                    }
                                }
                                error={errors.password}
                            />
                            <PasswordInput
                                title={'Confirm Password'}
                                placeholder={'Re-enter your password'}
                                name="password_confirmation"
                                label="Confirm Password"
                                value={password_confirmation}
                                onChange={
                                    (e) => {
                                        handleChange(e)
                                    }
                                }
                                error={errors.password_confirmation}
                            />
                        </div>
                        <Button
                            onClick={() => {
                                changePasswordWithEmail()
                            }}
                            disabled={loader}
                            loading={loader}
                        >
                            Continue
                        </Button>
                    </div>
                </> : <>
                    {/* <div className='flex flex-col space-y-4 justify-center items-center text-center mb-8'>
                        <Svgs.ConfirmationSvg width={'180'} />
                        <h3 className='text-3xl text-white font-bold'>Congragulations</h3>
                        <p className='text-white'>Thank you! Your account is being reviewed and we will notify you once it has been approved</p>
                        <Button
                            onClick={() => navigate('/login')}
                        >
                            Sign In
                        </Button>
                    </div> */}
                    <div className='bg-darkGrey rounded-3xl p-5 mt-6 max-w-md w-full'>
                        <div className='flex flex-col space-y-3 py-4 '>
                            <h3 className='text-3xl text-white font-bold'>Congratulations</h3>
                            <p className='text-white pb-7 text-sm'>Your password has been successfully changed.</p>
                            <Button
                                onClick={() => navigate('/login')}
                            >
                                Sign In
                            </Button>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default ResetPassword