import DashboardLayout from 'Components/DashboardLayout'
import Button from 'Components/Elements/Button'
import LoginInput from 'Components/Elements/LoginInput'
import React from 'react'
import CreateRequest from './CreateWishList'
import RequestCard from 'Components/RequestCard'
import Popup from 'Components/Elements/Popup'
import useSupportRequest from './helper'
import SupportRequestCardShimmer from 'Utility/Shimmers/SupportRequestCardShimmer'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import NotFound from 'Utility/NotFound'
import Back from 'Components/Elements/Back'
import DateRangePicker from 'Components/Elements/ReactDateRange'
import ConciergeWishlistShimmer from 'Utility/Shimmers/ConciergeWishlistShimmer'

const WishlistConcierge = () => {
    const { currentPage, setCurrentPage, endDate, setEndDate, startDate, setStartDate, createReq, setCreateReq, openDetail, setOpenDetail,
        wishlist, pagination, loading, activeTab, setActiveTab, searchText, setSearchText, loadingSingle, supportDetail, onToggleClick,
        addThreadComment, loadingThread, updateForm, setUpdateForm, getWishlist, navigate
    } = useSupportRequest()

    return (
        <>
            <DashboardLayout active={'wishlist'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen max-h-screen overflow-y-auto">
                    <div className="pt-4 flex justify-between items-center">
                        <Back
                            title={'Wishlist'}
                        />
                        <Button onClick={() => setCreateReq(true)}>Add Wishlist</Button>
                    </div>
                    <div className="py-6">
                        <div className="border-b border-lightGrey flex items-baseline gap-5 text-blueGrey">
                            <p onClick={() => setActiveTab('not converted')} className={`${activeTab == 'not converted' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>
                                Pending
                            </p>
                            <p onClick={() => setActiveTab('converted')} className={`${activeTab == 'converted' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>
                                Converted
                            </p>
                        </div>
                        <div className="lg:col-span-8 space-y-5">
                            <div className="flex py-3 w-full gap-3 items-end lg:items-center justify-end">
                                <div className="flex">
                                    <LoginInput
                                        title={""}
                                        placeholder={'Search...'}
                                        value={searchText}
                                        onChange={(e) => { setSearchText(e.target.value) }}
                                        required={false}
                                        inputPadding={"px-4 py-[9px]"}
                                        parentClass={"xs:w-fit w-full"}
                                    />
                                </div>
                                <DateRangePicker
                                    startDate={startDate}
                                    className="!max-w-none"
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    maxDate={new Date()}
                                />
                            </div>
                            <div className="space-y-3">
                                <div className="relative border-t border-lightGrey">
                                    {loading
                                        ? <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 p-4">
                                            {[0, 1, 2, 3, 4, 5]?.map(itm => {
                                                return <ConciergeWishlistShimmer />
                                            })}
                                        </div>
                                        :
                                        wishlist?.length > 0 ?
                                            <InfiniteScroll
                                                dataLength={wishlist?.length ? wishlist?.length : 10} //This is important field to render the next data
                                                next={() => {
                                                    setCurrentPage(currentPage + 1)
                                                }}
                                                hasMore={pagination && pagination?.current_page !== pagination?.total_pages}
                                                loader={(pagination?.current_page !== pagination?.total_pages)
                                                    && <div className="flex justify-center items-center my-4">
                                                        <PreloaderSm />
                                                    </div>
                                                }
                                                height={'40rem'}
                                            >
                                                <div className="grid grid-cols-1 sm:grid-cols-2 p-4 gap-5">
                                                    {wishlist?.map(itm => {
                                                        return <RequestCard
                                                            onCardClick={() => {
                                                                navigate(`/concierge/wishlist/${itm?.id}`)
                                                            }}
                                                            assignedUser={itm?.assigned_user ? `${itm?.assigned_user?.first_name} ${itm?.assigned_user?.last_name}` : ""}
                                                            address={itm?.address}
                                                            id={itm?.id}
                                                            number={itm?.number}
                                                            status={itm?.status}
                                                            title={itm?.name}
                                                            image={itm?.image}
                                                            category={itm?.type}
                                                            date={itm?.created_at}
                                                            user={itm?.user}
                                                            isConcierge={true}
                                                            adminWishlist={true}
                                                            is_active={itm?.is_active}
                                                            onToggleClick={onToggleClick}
                                                            phoneNumber={itm?.user?.phone_number && itm?.user?.phone_number != 'null' ? `+${itm?.user?.phone_code} ${itm?.user?.phone_number}` : itm?.user?.mobile_number && itm?.user?.mobile_number != 'null' ? `+${itm?.user?.mobile_code} ${itm?.user?.mobile_number}` : "----"}
                                                            addedBy={itm?.user ? `${itm?.user?.first_name} ${itm?.user?.last_name}` : ""}
                                                            onEditClick={() => {
                                                                setUpdateForm(itm)
                                                                setCreateReq(true)
                                                            }}
                                                            allowEdit={activeTab == 'not converted'}
                                                        />
                                                    })}
                                                </div>
                                            </InfiniteScroll>
                                            : <NotFound />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <CreateRequest
                        open={createReq}
                        close={setCreateReq}
                        onSuccess={getWishlist}
                        isUpdate={updateForm}
                        setIsUpdate={setUpdateForm}
                    />

                    <Popup
                        open={openDetail}
                        close={setOpenDetail}
                        heading={'Request Detail'}
                        size={'md'}
                    >
                        {loadingSingle
                            ? <SupportRequestCardShimmer
                                isDetail={true}
                            />
                            : <RequestCard
                                id={supportDetail?.id}
                                number={supportDetail?.number}
                                status={supportDetail?.status}
                                title={supportDetail?.title}
                                category={supportDetail?.type}
                                image={supportDetail?.image}
                                date={supportDetail?.created_at}
                                user={supportDetail?.user}
                                isDetail={true}
                                note={supportDetail?.notes}
                                threads={supportDetail?.thread}
                                onSubmit={addThreadComment}
                                loadingThread={loadingThread}
                                phoneNumber={supportDetail?.user?.phone_number && supportDetail?.user?.phone_number != 'null' ? `+${supportDetail?.user?.phone_code} ${supportDetail?.user?.phone_number}` : supportDetail?.user?.mobile_number && supportDetail?.user?.mobile_number != 'null' ? `+${supportDetail?.user?.mobile_code} ${supportDetail?.user?.mobile_number}` : "----"}
                            />}
                    </Popup>
                </main>
            </DashboardLayout>
        </>
    )
}

export default WishlistConcierge