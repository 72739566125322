// import { getAllPromotion, getAllRestaurents, getDashboardDataAPI } from "Adapters/APIs/restaurants"
import { getDropdownConciergesAPI, getDropdownRestaurantAPI } from "Adapters/APIs/Admin/dashboard"
import { getAllPromotion, getDashboardDataAPI } from "Adapters/APIs/Concierge/Details"
import { getRestaurantBookings } from "Adapters/APIs/Restaurant/Bookings"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const useDashboard = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')

    // States
    const [loader, setLoader] = useState(true)
    const [resturantLoading, setResturantLoading] = useState(true)
    const [promotionloading, setPromotionLoading] = useState(true)
    const [createBookingPopup, setCreateBookingPopup] = useState()
    const [addPromotion, setAddPromotion] = useState(false)
    const [bookingLoader, setBookingLoader] = useState(false)
    const [openReminder, setOpenReminder] = useState(false)

    // Redux
    const state = useSelector(state => state)
    const dashboardData = state?.concierge?.dashbaord_data
    const restaurants = state?.retaurant?.restaurants
    const promotions = state?.concierge?.promotions
    const bookings = state?.bookings?.bookings

    // get booking data
    const getBooking = () => {
        const success = () => {
            setBookingLoader(false)
        }
        const fail = () => {
            setBookingLoader(false)
        }
        let payload = {
            status: "placed",
            restaurant_id: user_data?.restaurant?.id
        }
        setBookingLoader(true)
        dispatch(getRestaurantBookings(access_token, payload, success, fail))
    }


    useEffect(() => {
        setLoader(true)
        setPromotionLoading(true)
        setResturantLoading(true)
        dispatch(getDashboardDataAPI(access_token, { restaurant_id: user_data?.restaurant?.id }, '', '', setLoader))
        dispatch(getDropdownConciergesAPI())
        // dispatch(getAllPromotion(access_token, '', '', '', setPromotionLoading))
        getBooking()
    }, [])

    return {
        dashboardData, restaurants, promotions, navigate, user_data, addPromotion, setAddPromotion, loader, resturantLoading,
        promotionloading, createBookingPopup, setCreateBookingPopup, bookingLoader, bookings, openReminder, setOpenReminder
    }

}

export default useDashboard