import { BASE_URL, check_and_send_referral_url, delete_referral_invite_url, get_redeem_history_data_url, get_referral_data_url, referral_withdraw_url, resend_referral_invite_url } from "Adapters/variables";
import axios from "axios";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { getReferralCommissionHistoryAction, getReferralHistoryAction } from "../../../Redux/InviteFriend/Actions";
import { toast } from "react-toastify";

// get concierge referral data
export const getConciergeReferralData = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_referral_data_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            if (payload?.type == 'referral_commission') {
                dispatch(getReferralCommissionHistoryAction(data))
            } else {
                dispatch(getReferralHistoryAction(data))
            }
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}

// get redeem history data
export const getRedeemReferralData = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_redeem_history_data_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            dispatch(getReferralHistoryAction(data))
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}

// post new referral 
export const sendNewReferral = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${check_and_send_referral_url}`, payload, headers);
        if (response?.status == 200) {
            success && success(response);
            toast.success(response?.data?.message, {
                toastId: 'toast'
            })
            return
        }
    } catch (error) {
        if (error?.response?.data?.status == 400) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
        } else if (error?.response?.data?.status == 422) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
        }
        fail && fail();
        return
    }
}

// resend referral to email
export const sendReferralAgain = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${resend_referral_invite_url}/${payload?.id}`, payload, headers);
        if (response?.status == 200) {
            success && success(response);
            toast.success(response?.data?.message, {
                toastId: 'toast'
            })
            return
        }
    } catch (error) {
        if (error?.response?.data?.status == 400) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
        }
        fail && fail();
        return
    }
}

// delete referral invite
export const deleteReferralInvite = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${delete_referral_invite_url}/${payload?.id}`, payload, headers);
        if (response?.status == 200) {
            success && success(response);
            toast.success(response?.data?.message, {
                toastId: 'toast'
            })
            return
        }
    } catch (error) {
        if (error?.response?.data?.status == 400) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
        }
        fail && fail();
        return
    }
}

// redeem refer amount
export const redeemReferralEarning = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${referral_withdraw_url}`, payload, headers);
        if (response?.status == 200) {
            success && success(response);
            toast.success(response?.data?.message, {
                toastId: 'toast'
            })
            return
        }
    } catch (error) {
        if (error?.response?.data?.status == 400) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
        }
        fail && fail();
        return
    }
}