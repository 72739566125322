import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import useConciergeRestaurant from './helper'
import DashboardLayout from 'Components/DashboardLayout'
import LoginInput from 'Components/Elements/LoginInput'
import AdminRestaurantCardShimmer from 'Utility/Shimmers/AdminRestaurantCardShimmer'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import RestaurantCard from 'Pages/Admin/MainDashbaord/Elements/RestaurantCard'
import Button from 'Components/Elements/Button'
import CreateBooking from '../Bookings/CreateBookings'
import Svgs from 'Assets/svgs'
import RestaurantFIlterPopup from './RestaurantFIlterPopup'
import Back from 'Components/Elements/Back'
import NotFound from 'Utility/NotFound'
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { currency } from 'Adapters/variables'
import { SORT_BY_FILTER } from 'Constants/Data/Categories'
import { convertObjectToArray, objectKeyConvertToArray } from 'Hooks/useObjectKeys'
import { convertObjectToForm } from 'Constants/Functions/convertDataToFormData'

const ConciergeRestaurant = () => {
  const { allRestaurants, Cuisines, searchText, filterData, setFilterData, setSearchText, pagination_data, loader, currentPage, featured,
    setCurrentPage, createBookingPopup, setCreateBookingPopup, filterPopup, setFilterPopup, getRestaurants, onChangeHandler, storeRestaurant,
    featureLoader, categories, storeDataInSessionStorage, removeDataFromSessionStorage, navigate, appliedFilter
  } = useConciergeRestaurant()

  return (
    <>
      <DashboardLayout active={'Dashboard'}>
        <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 flex flex-col gap-5 min-h-screen ">
          <div className='flex sm:flex-row flex-col gap-2 justify-between sm:items-center py-4'>
            <Back
              title={'Businesses'}
              onClick={() => {
                removeDataFromSessionStorage('filterData')
                navigate(-1)
              }}
            />
            <div className='flex justify-end items-center gap-2 w-full'>
              <LoginInput
                title={''}
                name={'searchText'}
                value={searchText}
                onChange={(e) => { setSearchText(e.target.value) }}
                placeholder={'Search business'}
                inputPadding={"px-4 py-[9px]"}
                inputClass={"flex-1"}
                parentClass={"w-full sm:max-w-xs"}
              />
              <div className='xs:w-fit xs:flex-none flex-1'>
                <Button onClick={() => setFilterPopup(true)}>
                  <Svgs.FilterIcon fill={'var(--primary-color)'} height={"21"} />
                </Button>
              </div>
            </div>
          </div>
          {convertObjectToArray(appliedFilter)?.length > 0 ?
            <div className="flex gap-5 my-3 min-w-full overflow-x-auto whitespace-nowrap px-4 py-2">
              {appliedFilter?.price_per_cover &&
                <div className="flex gap-1 text-white capitalize">
                  <span className='font-semibold text-secondary'>Price per cover:</span>
                  {appliedFilter?.price_per_cover} {currency}
                </div>
              }
              {appliedFilter?.radius &&
                <div className="flex gap-1 text-white capitalize">
                  <span className='font-semibold text-secondary'>Radius:</span>
                  {appliedFilter?.radius} KM
                </div>
              }
              {appliedFilter?.sort_by &&
                <div className="flex gap-1 text-white capitalize">
                  <span className='font-semibold text-secondary'>Sort by:</span>
                  {SORT_BY_FILTER?.find(itm => itm?.value == appliedFilter?.sort_by)?.label}
                </div>
              }
              {appliedFilter?.category &&
                <div className="flex gap-1 text-white capitalize">
                  <span className='font-semibold text-secondary'>Category:</span>
                  {categories?.find(itm => itm?.value == appliedFilter?.category)?.label}
                </div>
              }
              {appliedFilter?.cuisine_id &&
                <div className="flex gap-1 text-white capitalize">
                  <span className='font-semibold text-secondary'>Sub Category:</span>
                  {Cuisines?.find(itm => itm?.value == appliedFilter?.cuisine_id)?.label}
                </div>
              }
              {appliedFilter?.google_rating &&
                <div className="flex gap-1 text-white capitalize">
                  <span className='font-semibold text-secondary'>Google Rating:</span>
                  {appliedFilter?.google_rating}
                </div>
              }
              {appliedFilter?.tripadvisor_rating &&
                <div className="flex gap-1 text-white capitalize">
                  <span className='font-semibold text-secondary'>Trip Advisor Rating:</span>
                  {appliedFilter?.tripadvisor_rating}
                </div>
              }
            </div>
            : ""
          }


          {featureLoader ?
            <div className="!m-0 flex flex-col gap-3">
              <div className='h-6 w-60 bg-gray-500 rounded-md'></div>
              <div className="!m-0 grid md:grid-cols-2 grid-cols-1 gap-4">
                {[0, 1, 2, 3, 4, 5].map((index) => (
                  <AdminRestaurantCardShimmer showImages={true} hideInfo={true} hideStatus={true} bookBtn={true} key={index} />
                ))}
              </div>
            </div>
            : featured?.length > 0
              ? <div className="!m-0 flex flex-col gap-3">
                <h1 className='font-semibold text-2xl text-white'>Featured Businesses</h1>
                <div className="flex flex-col w-full overflow-hidden">
                  <Swiper class
                    modules={[Autoplay]}
                    spaceBetween={16}
                    autoplay={true}
                    breakpoints={{
                      1028: {
                        slidesPerView: 2,
                      },
                      320: {
                        slidesPerView: 1,
                      },
                    }}
                  >
                    {featured?.map((itm, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <RestaurantCard
                            showImages={true}
                            hideInfo={true}
                            customHeight={"h-[25rem]"}
                            data={itm}
                            images={itm?.images}
                            index={index}
                            name={itm?.name}
                            phoneNumber={itm?.phone_number && itm?.phone_number !== 'null' ? `+${itm?.phone_code} ${itm?.phone_number}` : itm?.mobile_number && itm?.mobile_number !== 'null' ? `+${itm?.mobile_code} ${itm?.mobile_number}` : "----"}
                            address={itm?.address}
                            totalBookings={itm?.reservations}
                            totalEarned={itm?.total_earned}
                            totalDues={itm?.due_payments}
                            hideStatus={true}
                            isFeatured={true}
                            // active={itm?.is_active == 1 ? true : false}
                            bookBtn={<>
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation()
                                  setCreateBookingPopup(itm?.id)
                                }}
                                text={'Book now'} className={'w-fit flex-end'} />
                            </>}
                            onCardClick={() => { storeRestaurant(itm) }}
                          />
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </div>
              </div>
              : ""
          }

          <div className="!m-0 flex flex-col gap-3">
            <h1 className='font-semibold text-2xl text-white'>All Businesses</h1>
            {loader ?
              <div className="!m-0 grid md:grid-cols-2 grid-cols-1 gap-4 p-2">
                {[0, 1, 2, 3, 4, 5, 6, 7].map((index) => (
                  <AdminRestaurantCardShimmer showImages={true} hideInfo={true} hideStatus={true} bookBtn={true} key={index} />
                ))}
              </div>
              :
              allRestaurants?.length > 0
                ? <InfiniteScroll
                  dataLength={allRestaurants?.length ? allRestaurants?.length : 10} //This is important field to render the next data
                  next={() => {
                    setCurrentPage(currentPage + 1)
                    // getAdminRestaurants()
                  }}
                  hasMore={pagination_data && pagination_data?.current_page !== pagination_data?.total_pages}
                  loader={(pagination_data?.current_page !== pagination_data?.total_pages) &&
                    <div className="flex justify-center items-center my-4">
                      <PreloaderSm />
                    </div>
                  }
                // height={'40rem'}
                >
                  <div className="!m-0 grid md:grid-cols-2 grid-cols-1 gap-4 p-2">
                    {allRestaurants?.map((itm, index) => {
                      return <RestaurantCard
                        showImages={true}
                        hideInfo={true}
                        data={itm}
                        images={itm?.images}
                        index={index}
                        name={itm?.name}
                        phoneNumber={itm?.phone_number && itm?.phone_number !== 'null' ? `+${itm?.phone_code} ${itm?.phone_number}` : itm?.mobile_number && itm?.mobile_number !== 'null' ? `+${itm?.mobile_code} ${itm?.mobile_number}` : "----"}
                        address={itm?.address}
                        totalBookings={itm?.reservations}
                        totalEarned={itm?.total_earned}
                        totalDues={itm?.due_payments}
                        hideStatus={true}
                        // active={itm?.is_active == 1 ? true : false}
                        bookBtn={<>
                          <Button
                            onClick={(event) => {
                              event.stopPropagation()
                              setCreateBookingPopup(itm?.id)
                            }}
                            text={'Book now'} className={'w-fit flex-end'} />
                        </>}
                        onCardClick={() => {
                          storeRestaurant(itm)
                        }}
                      />
                    })}
                  </div>
                </InfiniteScroll>
                : <NotFound />
            }
          </div>
          {createBookingPopup && <CreateBooking
            selectRestaurant={createBookingPopup}
            close={() => setCreateBookingPopup()}
          />}

          <RestaurantFIlterPopup
            open={filterPopup}
            close={setFilterPopup}
            filterData={filterData}
            Cuisines={Cuisines}
            categories={categories}
            setFilterData={setFilterData}
            onChange={onChangeHandler}
            onClearFilter={() => {
              removeDataFromSessionStorage('filterData')
              getRestaurants(1, 'clear')
              getRestaurants('featured')
            }}
            onSubmit={() => {
              storeDataInSessionStorage('filterData', filterData)
              getRestaurants(1)
              getRestaurants('featured')
            }}
          />
        </main>
      </DashboardLayout>
    </>
  )
}

export default ConciergeRestaurant