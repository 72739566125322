import Svgs from "Assets/svgs";
import AccordianShimmer from "Utility/Shimmers/AccordianShimmer";
import React, { useEffect, useState } from "react";

const AccordionItem = ({ title, content, showDelete, onDeleteClick, isOpen, onEditClick, onClick, showEdit, contentClass, showBorder, showCardBackground
}) => {
    return (
        <div className={` rounded-lg my-4 ${isOpen ? "" : "border border-lightGrey"}`}>
            <button
                onClick={onClick}
                className={`flex justify-between items-center w-full p-4 focus:outline-none ${isOpen ? "bg-secondary text-primary rounded-t-lg" : "text-white bg-darkGrey rounded-lg"}`}
            >
                <span className={`text-lg font-semibold ${isOpen ? "text-black" : ""}`}>{title}</span>
                <div className="flex gap-2 items-center">
                    {showEdit ?
                        <div
                            className={`flex rounded-full size-9 items-center justify-center ${!isOpen ? "bg-[--secondary-color]" : 'bg-[--darkGrey-color]'}`}
                            onClick={(e) => {
                                e.stopPropagation()
                                onDeleteClick && onDeleteClick()
                            }}
                        >
                            <Svgs.BinV2 fill={`${!isOpen ? "var(--darkGrey-color)" : 'var(--secondary-color)'}`} />
                        </div>
                        : ""
                    }
                    {showDelete ?
                        <div
                            className="flex"
                            onClick={(e) => {
                                e.stopPropagation()
                                onEditClick && onEditClick()
                            }}
                        >
                            <div
                                className={`flex rounded-full size-9 items-center justify-center ${!isOpen ? "bg-[--secondary-color]" : 'bg-[--darkGrey-color]'}`}
                            >
                                <Svgs.EditGoldIconV2 fill={`${!isOpen ? "var(--darkGrey-color)" : 'var(--secondary-color)'}`} />
                            </div>
                        </div>
                        : ""
                    }
                    <div
                        className={`flex rounded-full size-9 items-center justify-center ${!isOpen ? "bg-[--secondary-color]" : 'bg-[--darkGrey-color]'}`}
                    >
                        <Svgs.ArrowUpV2 fill={`${!isOpen ? "var(--darkGrey-color)" : 'var(--secondary-color)'}`} />
                    </div>
                </div>
            </button>
            <div
                className={`${contentClass ? `${contentClass} overflow-y-auto overflow-hidden` : ""} overflow-hidden rounded-b-lg ${showCardBackground && "bg-darkGrey"} ${isOpen ? `max-h-screen ${showBorder ? "border border-t-0 border-[#DFDFDF]" : ""}` : "max-h-0"}`}
            >
                <div>
                    {content}
                </div>
            </div>
        </div>
    );
};

const Accordion = ({ items, showEdit, type, showDelete, onDeleteClick, onEditClick, contentClass, loader, showBorder, showCardBackground }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleItemClick = (index) => {
        setOpenIndex(index === openIndex ? null : index);
    };

    const onEdit = (itm, index) => {
        setOpenIndex(index);
        onEditClick && onEditClick(itm)
    }
    const onDelete = (id, index) => {
        setOpenIndex(index);
        onDeleteClick && onDeleteClick(id)
    }

    useEffect(() => {
        setOpenIndex(null)
    }, [type])

    return (
        <>
            {loader ?
                <AccordianShimmer />
                :
                <div className="w-full mx-auto">
                    {items?.map((item, index) => (
                        <AccordionItem
                            key={index}
                            title={item?.title || item?.name}
                            isOpen={index === openIndex}
                            id={item?.id}
                            onEditClick={() => { onEdit(item, index) }}
                            onClick={() => {
                                // e.stopPropagation()
                                handleItemClick(index)
                            }}
                            showEdit={showEdit}
                            showDelete={showDelete}
                            onDeleteClick={() => onDelete(item?.id, index)}
                            content={item?.content}
                            contentClass={contentClass}
                            showBorder={showBorder}
                            showCardBackground={showCardBackground}
                        />
                    ))}
                </div>
            }
        </>
    );
};

Accordion.defaultProps = {
    showEdit: true,
    showBorder: true,
    showCardBackground: true
}

export default Accordion;
