export const buildQueryString = (data, removePage) => {
    if(data){        
        let query = '?';
    
        // Exclude 'page' property from data
        if (data?.page && removePage) {
            delete data.page;
        }
    
        for (let dt in data) {
            if (data[dt]) {
                query += `${dt}=${encodeURIComponent(data[dt])}&`;
            }
        }
    
        // Remove the last '&' character from the query
        query = query?.slice(0, -1);
    
        return query;
    }else{
        return ''
    }
}