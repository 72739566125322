import React from 'react'

const ViewAllBtn = ({ title,className,viewAllStyle,viewAllIcon, onClick }) => {
    return (
        <button className={`${className} ${viewAllStyle} rounded-lg md:rounded-[10px] border border-secondary text-secondary text-xs font-semibold py-2 px-6 flex items-center gap-1`}
            onClick={onClick && onClick}>
            {viewAllIcon ? viewAllIcon : ""}
            {title ? title : "View All"}
        </button>
    )
}

export default ViewAllBtn