import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

let COUNTDOWN = 5;
let initialState = {
    num1: "",
    num2: "",
    num3: "",
    num4: "",
}

const useOTPInputHelper = ({ addBusiness, email, isReset }) => {
    const navigate = useNavigate()

    const inp_1 = useRef();
    const inp_2 = useRef();
    const inp_3 = useRef();
    const inp_4 = useRef();
    const recaptchaRef = useRef();

    const { storeDataInLocalStorage, getDataFromLocalStorage, getDataFromSessionStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [isFinished, setIsFinished] = useState(false);
    const [count, setCount] = useState(isFinished ? 0 : COUNTDOWN);
    const [otp, setOtp] = useState(initialState);
    const [userEmail, setUserEmail] = useState('')

    const {
        errors,
        onBlurHandler,
        onFocusHandler,
        removeWhiteSpacesAndSpecialCharactors,
    } = useValidations();

    useEffect(() => {
        if (!isFinished) {
            setTimeout(() => {
                countDown();
            }, 1000);
        }
    }, [count, isFinished]);

    // otp input
    useEffect(() => {
        let num_inps = document.querySelectorAll(".numberAddInp");
        num_inps.forEach((inp, i) => {
            inp.addEventListener("input", () => {
                const inputValue = removeWhiteSpacesAndSpecialCharactors(inp.value);
                if (inputValue && inputValue != "") {
                    if (i != num_inps.length - 1) {
                        num_inps[i + 1].select();
                    }
                }
            });
        });
    }, []);

    // Check for reset user Email
    useEffect(() => {
        if (addBusiness) {
            let businessData = getDataFromSessionStorage("businessData");
            if (businessData?.email) {
                setUserEmail(businessData?.email || email)
            }
        } else {
            let signupData = getDataFromSessionStorage("signupData");
            if (signupData?.email) {
                setUserEmail(signupData?.email || email)
            } else {
                // if there is isReset then don't navigate to previous screen
                if (!isReset)
                    navigate(-1)
            }
        }
    }, []);

    // start timer
    const startTimer = (sec) => {
        setIsFinished(false);
        setCount(sec ? sec : COUNTDOWN);
    };

    // conntdown
    const countDown = () => {
        if (count !== 0) {
            setCount(count - 1);
        }
    };

    // set verification code state here
    const onChangeHandler = (e) => {
        e.target.value = removeWhiteSpacesAndSpecialCharactors(e.target.value);
        const { name, value } = e.target;

        if (value.length > 1) return false;
        setOtp((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    return {
        onBlurHandler, onFocusHandler, setOtp, onChangeHandler, inp_1, inp_2, inp_3, inp_4, count, isFinished, otp, errors, recaptchaRef,
        startTimer, initialState, userEmail
    }
}

export default useOTPInputHelper