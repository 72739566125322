import Svgs from 'Assets/svgs';
import React from 'react'

const CheckMenu = ({
    title,
    id,
    checked,
    onClick,
    error,
    icon,
    disabled,
}) => {

    return (
        <>
            <div onClick={disabled ? () => { } : onClick}
                className={`${error && "border border-error" } cursor-pointer relative bg-lightGrey flex items-center flex-col justify-center gap-3 pt-8 pb-7 px-4 text-center rounded-[20px] `}>
                <div className={`${error ? 'border-error' : "border-secondary" } absolute right-3 top-3 border size-5 rounded-full flex items-center justify-center ${checked ? 'bg-secondary' : ''}`}>
                    {checked &&
                        <Svgs.Checked className={`size-3`} />
                    }
                </div>
                {icon &&
                    <div className="rounded-[10px] flex items-center justify-center w-20 h-16 bg-secondary">
                        {icon}
                    </div>
                }
                <p className="font-semibold text-lg text-white">{title}</p>
            </div>
        </>
    )
}

CheckMenu.defaultProps = {
    onChange: () => { },
};

export default CheckMenu