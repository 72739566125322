import { getRestaurantBusiness } from "Adapters/APIs/Restaurant/Business"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import useSwitchBusinessHelper from "./useSwitchBusinessHelper"

const useRestaurantAllBusiness = () => {
    const { swicthLoading, switchLoader, switchProfile, setSwitchProfile, switchToOtherBusiness, handleSwitchingBusiness }
        = useSwitchBusinessHelper()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { getDataFromLocalStorage, storeDataInSessionStorage, getDataFromSessionStorage, removeDataFromSessionStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')
    const defaultRoute = getDataFromLocalStorage('defaultRoute')

    // redux states
    const state = useSelector(state => state)
    const allBusinesses = state?.restaurantBusiness?.businesses
    const pagination_data = state?.restaurantBusiness?.pagination_data

    // states
    const [addPopup, setAddPopup] = useState(false)
    const [loader, setLoader] = useState(true)
    const [secondLoader, setSecondLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [restaurantData, setRestaurantData] = useState(false)

    // handle back click
    const handleBackClick = () => {
        removeDataFromSessionStorage("editBusinessData")
        setRestaurantData(false)
    }

    // handle success function 
    const successCallBack = () => {
        setCurrentPage(1)
        getAllBusinesses()
        removeDataFromSessionStorage("editBusinessData")
        setRestaurantData(false)
    }

    // get all businesses
    const getAllBusinesses = () => {
        const success = (data) => {
            setLoader(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoader(false)
            setSecondLoader(false)
        }
        let payload = {
            page: currentPage,
            per_page: 10,
            restaurant_id: user_data?.restaurant?.id
        }
        if (currentPage == 1) {
            setLoader(true)
        } else {
            setSecondLoader(true)
        }
        dispatch(getRestaurantBusiness(access_token, payload, success, fail))
    }

    useEffect(() => {
        let editData = getDataFromSessionStorage("editBusinessData");
        if (editData) {
            setRestaurantData(editData)
            setTimeout(() => {
                removeDataFromSessionStorage("editBusinessData")
            }, 1000);
        }
    }, [])

    useEffect(() => {
        if (access_token && (defaultRoute == '/restaurant/dashboard')) {
            getAllBusinesses()
        }
    }, [])

    return {
        addPopup, setAddPopup, currentPage, setCurrentPage, secondLoader, setSecondLoader, loader, allBusinesses, pagination_data,
        navigate, switchToOtherBusiness, restaurantData, setRestaurantData, storeDataInSessionStorage, removeDataFromSessionStorage,
        switchProfile, setSwitchProfile, handleSwitchingBusiness, switchLoader, swicthLoading, handleBackClick, successCallBack,
        user_data
    }
}

export default useRestaurantAllBusiness