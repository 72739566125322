import { AddTeamMember, UpdateTeamMember } from "Adapters/APIs/Concierge/TeamMembers"
import { objectKeyConvertToArray } from "Hooks/useObjectKeys"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import useValidations from "Hooks/useValidations"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

let initialState = {
    name: '',
    phone_number: '',
    phone_code: '+971',
    is_active: 1
}
const useAddEditTeamMemberHelper = (close, onSuccess, editData) => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()

    const { errors, setErrors, validation, validateNumber } = useValidations()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState(initialState)

    useEffect(() => {
        if (editData?.id) {
            setFormData({
                name: editData?.name,
                phone_code: editData?.phone_code,
                phone_number: editData?.phone_number,
                is_active: editData?.is_active
            })
        }
    }, [editData])

    // handle change
    const onChangeHandler = (e) => {
        const { name, value } = e?.target
        if (name == 'phone_number') {
            setErrors((prev) => ({
                ...prev,
                [name]: ''
            }));
            validateNumber(name, value);
            const [code, number] = value.split('-');
            const formattedCode = code.startsWith('+') ? code : `+${code}`;
            setFormData({
                ...formData,
                phone_number: number,
                phone_code: formattedCode,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            })
            setErrors({
                ...errors,
                [name]: ''
            })
        }
    }

    // handle close
    const handleClose = () => {
        close && close(false)
        setFormData(initialState)
    }

    // handle submit data
    const onSubmit = () => {
        let payload = { ...formData }
        let requiredFields = { ...payload, phone_number: `${formData?.phone_code}-${formData?.phone_number}` }
        delete requiredFields.is_active
        const err = validation(requiredFields)
        setErrors(err)
        if (objectKeyConvertToArray(err)?.length == 0) {
            setLoading(true)
            const success = () => {
                close && close(false)
                onSuccess && onSuccess(false)
                setLoading(false)
                setErrors()
                setFormData(initialState)
            }
            const fail = () => {
                setLoading(false)
            }
            if (editData?.id) {
                payload = {
                    ...payload,
                    id: editData?.id
                }
                dispatch(UpdateTeamMember(access_token, payload, success, fail))
            } else {
                dispatch(AddTeamMember(access_token, payload, success, fail))
            }
        }
    }

    return {
        onChangeHandler, formData, loading, onSubmit, errors, handleClose
    }

}

export default useAddEditTeamMemberHelper