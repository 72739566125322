import { BASE_URL, create_wishlist_url, get_wishlist_url, toggle_wish_list_url, update_wishlist_url } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { getWishListActions, getWishListDetailActions } from "../../../Redux/Concierge/ConciergeDashboard/Actions";
import axios from "axios";
import { toast } from "react-toastify";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";

// get concierge wishlist detail
export const getConceirgeWishlist = (access_token, payload, success, fail,) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_wishlist_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            dispatch(getWishListActions(data))
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}

// get wishlist detail
export const getConceirgeWishlistDetail = (access_token, id, success, fail,) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_wishlist_url}/${id}`, headers);
        if (response?.status == 200) {
            const data = response?.data?.wishlist
            success && success()
            dispatch(getWishListDetailActions(data))
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}

// toggle wishlist
export const toggleWishList = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${toggle_wish_list_url}/${payload}`, {}, headers);
        if (response?.status == 200) {
            const data = response?.data
            toast.success(response?.data?.message)
            success && success(data)
            setLoading && setLoading(false)
        }
    } catch (error) {
        // toast.error(apiErrorMessage, { toastId: "toast" })
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}
export const createWishlist = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const formData = convertDataToFormData(payload)
        const response = await axios.post(`${BASE_URL}${create_wishlist_url}`, formData, headers);
        if (response?.status == 200) {
            const data = response?.data
            toast.success(response?.data?.message)
            success && success(data)
            setLoading && setLoading(false)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}
export const updateWishlist = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const formData = convertDataToFormData(payload)
        const response = await axios.post(`${BASE_URL}${update_wishlist_url}/${payload?.id}`, formData, headers);
        if (response?.status == 200) {
            const data = response?.data
            toast.success(response?.data?.message)
            success && success(data)
            setLoading && setLoading(false)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}