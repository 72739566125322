import { updateSupportRequestStatus } from "Adapters/APIs/Admin/supportrequest";
import { addThreadComments, getConceirgeSuppReq, getSingleConceirgeSuppReq, reOpenSupportRequest } from "Adapters/APIs/Concierge/SupportRequest";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useRestaurantSupportRequests = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')

    const state = useSelector(state => state)
    const support_requests = state?.concierge?.support_requests
    const pagination = state?.concierge?.supp_req_pagination

    // states
    const [currentPage, setCurrentPage] = useState(1)
    const [endDate, setEndDate] = useState();
    const [startDate, setStartDate] = useState();
    const [createReq, setCreateReq] = useState(false)
    const [openDetail, setOpenDetail] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingSingle, setLoadingSingle] = useState(true)
    const [supportDetail, setSupportDetail] = useState()
    const [commentLoading, setCommentLoading] = useState(false)
    const [loadingThread, setLoadingThread] = useState(false)
    const [status, setStatus] = useState('')
    const [updateStatusValue, setUpdateStatusValue] = useState('')
    const [updateStatusLoader, setUpdateStatusLoader] = useState(false)

    // get support request
    const getSupportReq = (noLoader) => {
        if (currentPage == 1) {
            !noLoader && setLoading(true)
        }
        dispatch(getConceirgeSuppReq(access_token, {
            page: currentPage,
            end_date: endDate,
            start_date: startDate,
            status: status,
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : "",
        }, '', '', setLoading))
    }

    // get single request data
    const getSingleRequest = (id, again) => {
        const success = (data) => {
            setSupportDetail(data?.request)
            setLoadingSingle(false)
            setLoadingThread(false)
        }
        const fail = () => {
            setLoadingSingle(false)
            setLoadingThread(false)
        }
        if (again)
            setLoadingThread(true)
        else
            setLoadingSingle(true)
        dispatch(getSingleConceirgeSuppReq(access_token, id, success, fail))
    }

    // add comment
    const addThreadComment = (id, comment, status) => {
        const success = () => {
            getSingleRequest(id, true)
            setCommentLoading(false)
        }
        const reOpenSuccess = () => {
            getSupportReq(true)
            getSingleRequest(id, true)
            setCommentLoading(false)
        }
        const fail = () => {
            setCommentLoading(false)
        }
        setCommentLoading(true)
        if (status) {
            dispatch(reOpenSupportRequest(access_token, { id: id, restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : "" }, reOpenSuccess, fail))
        } else
            dispatch(addThreadComments(access_token, { id: id, message: comment, restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : "" }, success, fail))
    }

    // update support request status
    const updateSupportRequestStatusValue = (id, status) => {
        const success = () => {
            setCurrentPage(1)
            setStartDate()
            setEndDate()
            setStatus('')
            getSupportReq()
            setUpdateStatusLoader(false)
            setOpenDetail(false)
            setUpdateStatusValue()
        }
        const fail = () => {
            setUpdateStatusLoader(false)
        }
        setUpdateStatusLoader(true)
        dispatch(updateSupportRequestStatus(access_token, { id: id, status: status, restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : "" }, success, fail))
    }

    useEffect(() => {
        getSupportReq()
    }, [currentPage, endDate, status, startDate])

    useEffect(() => {
        setLoading(true)
        setCurrentPage(1)
    }, [startDate, endDate, status])

    return {
        navigate, currentPage, setCurrentPage, endDate, setEndDate, startDate, setStartDate, createReq, setCreateReq, openDetail, setOpenDetail,
        support_requests, pagination, loading, loadingSingle, getSingleRequest, supportDetail, commentLoading, addThreadComment, loadingThread,
        setStatus, status, getSupportReq, updateStatusValue, setUpdateStatusValue, updateStatusLoader, updateSupportRequestStatusValue
    }
}

export default useRestaurantSupportRequests