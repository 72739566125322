import { add_team_members, BASE_URL, get_team_members, update_team_members } from "Adapters/variables";
import axios from "axios";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { getTeamMembers, getTeamMembersDropdown } from "../../../Redux/Concierge/ConciergeDashboard/Actions";
import { toast } from "react-toastify";


// get all support requests
export const getTeamMembersData = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_team_members}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            if (payload?.is_dropdown) {
                dispatch(getTeamMembersDropdown(data))
            } else {
                dispatch(getTeamMembers(data))
            }
        }
    } catch (error) {
        if (error?.response?.status == 404) {
            if (payload?.is_dropdown) {
                dispatch(getTeamMembersDropdown({}))
            } else {
                dispatch(getTeamMembers({}))
            }
        }
        fail && fail()
        return error;
    }
}

// add team member
export const AddTeamMember = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${add_team_members}`, payload, headers);
        if (response?.status == 201) {
            const data = response?.data
            toast.success(response?.data?.message)
            success && success(data)
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}

// update team members
export const UpdateTeamMember = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${update_team_members}/${payload?.id}`, payload, headers);
        if (response?.status == 201) {
            const data = response?.data
            toast.success(response?.data?.message)
            success && success(data)
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}