
import { getResaurantSubScription, paySubScriptionPayment } from "Adapters/APIs/Restaurant/Payment";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useSubscriptionPayments = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { setErrors, errors, validation } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')

    // states
    const [loading, setLoading] = useState(true)
    const [details, setDetails] = useState()
    const [paySubscription, setPaySubscription] = useState(false)
    const [selectedMonths, setSelectedMonths] = useState([])
    const [paidProof, setPaidProof] = useState()
    const [payLoader, setPayLoader] = useState(false)

    const totalMonths = selectedMonths?.length
    const total_price_no_vat = totalMonths * details?.monthly_fee
    const total_vat = totalMonths * details?.monthly_vat

    const total_price_with_vat = total_price_no_vat + total_vat
    const [payClick, setPayClick] = useState(false)

    const onProofChangeHandler = (e) => {
        const { name, files } = e?.target
        setPaidProof(files[0])
        setErrors({ ...errors, [name]: '' })
    }

    // Submit Subsrciption Payment
    const onSubmitPayment = () => {
        let payload = {
            months: selectedMonths,
            amount: total_price_with_vat,
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : "",
            proof: paidProof,
        }
        const success = () => {
            setPayClick(false)
            setPayLoader(false)
            setPaySubscription(false)
            getpayment()
        }
        const fail = () => {
            setPayLoader(false)
        }
        let requiredFields = {
            paidProof: paidProof,
            months: selectedMonths?.length > 0 ? true : false
        }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setPayLoader(true)
            dispatch(paySubScriptionPayment(access_token, payload, success, fail))
        }
    }

    // get subscription Payment
    const getpayment = () => {
        const success = (data) => {
            setDetails(data)
            setLoading(false)
        }
        const fail = () => {
            setLoading(false)
        }
        dispatch(getResaurantSubScription(access_token, { restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : "" }, success, fail))
    }

    const onSelectedChange = (name, type) => {
        // on All selection
        if (type == 'all') {
            selectedMonths?.length == details?.due_months_date?.length
                ? setSelectedMonths([])
                : setSelectedMonths(details?.due_months_date)
        } else if (selectedMonths.includes(name)) {
            setSelectedMonths(selectedMonths?.filter((item) => item !== name));
        } else {
            // Add the name to selectedMonths array
            setSelectedMonths([...selectedMonths, name]);
        }
    }

    useEffect(() => {
        getpayment()
    }, [])

    useEffect(() => {
        setSelectedMonths(details?.due_months_date)
    }, [details, paySubscription])

    useEffect(() => {
        setPaidProof()
        setPayLoader(false)
    }, [payClick])

    useEffect(() => {
        setPaidProof()
        setPayLoader(false)
    }, [payClick])

    return {
        loading, details, navigate, user_data, paySubscription, setPaySubscription, selectedMonths, onSelectedChange, setPayClick, errors,
        total_price_no_vat, total_vat, total_price_with_vat, payClick, paidProof, onProofChangeHandler, payLoader, onSubmitPayment
    }
}

export default useSubscriptionPayments