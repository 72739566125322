import { BASE_URL, add_thread_comment_url, create_support_request_url, get_concierge_support_req_url, get_singel_concierge_support_req_url, update_support_request_status } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { getSupportReq } from "../../../Redux/Concierge/ConciergeDashboard/Actions";
import axios from "axios";
import { toast } from "react-toastify";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";

// get support request
export const getConceirgeSuppReq = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_concierge_support_req_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            setLoading && setLoading(false)
            dispatch(getSupportReq(data))
        }
    } catch (error) {
        dispatch(getSupportReq(error?.response?.data))
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}

// get single support request
export const getSingleConceirgeSuppReq = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_singel_concierge_support_req_url}/${payload}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
            setLoading && setLoading(false)
        }
    } catch (error) {
        // toast.error(apiErrorMessage, { toastId: "toast" })
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}

// add thread comments
export const addThreadComments = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${add_thread_comment_url}/${payload?.id}`, { message: payload?.message }, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
            setLoading && setLoading(false)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}

// update support request status
export const updateSupportRequestStatus = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${update_support_request_status}/${payload?.id}`, { status: payload?.status }, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
            setLoading && setLoading(false)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}