import axios from "axios";
import { add_business, add_cuisine, BASE_URL, country_code_url, get_all_business_url, get_all_categories_url, get_all_cuisines_url, get_all_restaurants_url, get_restaurant_business_hours, post_restaurant_business_hours } from "Adapters/variables";
import { getAllCuisines } from "../../Redux/Cuisines/Actions";
import { getAllCategoriesData } from "../../Redux/Categories/Actions";
import { getAllRestuarants, getBusinessHours } from "../../Redux/Concierge/Retaurants/Actions";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";
import { getAllBusinessData } from "../../Redux/Business/Actions";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";

// get all categories
export const getAllCategories = (payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const response = await axios.get(`${BASE_URL}${get_all_categories_url}${query}`);
        if (response?.status == 200) {
            const data = response?.data?.categories
            dispatch(getAllCategoriesData(data))
            success && success()
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}

// get all cuisines
export const getAllCuisine = (payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const response = await axios.get(`${BASE_URL}${get_all_cuisines_url}${query}`);
        if (response?.status == 200) {
            const data = response?.data?.data
            dispatch(getAllCuisines(data))
            success && success()
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}

// add cuisine
export const addCuisine = (payload, success, fail) => async (dispatch) => {
    const formData = convertDataToFormData(payload)
    try {
        const response = await axios.post(`${BASE_URL}${add_cuisine}`, formData);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        }
    } catch (error) {
        if (error?.response?.data?.status == '400') {
            toast.error(error?.response?.data?.errors?.name[0], { toastId: 'toast' })
        } else {
            toast.error(apiErrorMessage, { toastId: 'toast' })
        }

        fail && fail()
        return error;
    }
}

// get all business
export const getAllBusiness = (payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const response = await axios.get(`${BASE_URL}${get_all_business_url}${query}`);
        if (response?.status == 200) {
            const data = response?.data?.businesses
            success && success(data)
            dispatch(getAllBusinessData(data))
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}

// add cuisine
export const addNewBusiness = (payload, success, fail) => async (dispatch) => {
    try {
        const response = await axios.post(`${BASE_URL}${add_business}`, payload);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'toast' })
        fail && fail()
        return error;
    }
}

export const getUserLocation = (setUserLocation) => async (dispatch) => {
    try {
        const response = await axios.get(country_code_url)
        setUserLocation(`${response?.data?.latitude},${response?.data?.longitude}`)
    } catch (error) {
        return error;
    }
}
export const getAllRestaurents = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_all_restaurants_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data?.restaurants
            setLoading && setLoading(false)
            if (payload?.get_single_restaurant) {
                success && success(data)
            } else {
                success && success()
                dispatch(getAllRestuarants(data))
            }
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}
export const getAllConcierge = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_all_restaurants_url}`, headers);
        if (response?.status == 200) {
            const data = response?.data?.restaurants
            setLoading && setLoading(false)
            dispatch(getAllRestuarants(data))
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}

export const getRestaurantBusinessHours = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_restaurant_business_hours}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data?.business_hours
            dispatch(getBusinessHours(data))
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}

export const postRestaurantBusinessHours = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${post_restaurant_business_hours}`, payload, headers);
        if (response?.status == 200) {
            const data = response?.data?.business_hours
            dispatch(getBusinessHours(data))
            toast.success(response?.data?.message, { toastId: 'toast' })
            success && success()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'toast' })
        fail && fail()
        return error;
    }
}