import { BASE_URL, add_package, get_all_promotions_url, get_packages, update_package } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";
import axios from "axios";
import { toast } from "react-toastify";


// get admin promotions data
export const getAdminPromotionsData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_packages}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail(error)
        return error;
    }
}

// update or add admin promotions/packages
export const updateOrAddAdminPromotion = (access_token, payload, success, fail) => async (dispatch) => {
    const showToast = payload.showToast
    delete payload.showToast
    const formData = convertDataToFormData(payload)
    let endUrl = payload?.id ? update_package : add_package
    let editId = payload?.id ? payload?.id : ""
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${endUrl}${editId}`, formData, headers);
        if (response?.status == 201 || response?.status == 200) {
            success && success()
            !showToast && toast.success(response?.data?.message, { toastId: 'toast' })
        }
    } catch (error) {
        if (error?.response?.status == 404) {
            !showToast && toast.error(error?.response?.data?.message, { toastId: 'toast' })
        } else {
            !showToast && toast.error(apiErrorMessage, { toastId: 'toast' })
        }
        fail && fail()
        return error;
    }
}