import { getDropdownConciergesAPI } from "Adapters/APIs/Admin/dashboard"
import { CancelRequestBooking, RequestBookingmodification, billBookingRequest, confirmRequestBooking, getRestaurantBookings, getSingleReservation, noShowRequestBooking } from "Adapters/APIs/Restaurant/Bookings"
import { convertImgToUrl, objectKeyConvertToArray } from "Hooks/useObjectKeys"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import useValidations from "Hooks/useValidations"
import { useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

const initialBookingForm = {
    restaurant_id: '',
    guest_name: '',
    total_pax: '',
    date: '',
    time: '',
    occasion: '',
    allergies: '',
    special_note: '',
}
const initialFilterState = {
    user_id: "",
    start_date: "",
    end_date: "",
    status: "",
    search_text: ""
}

const useBookings = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const reservation = searchParams.get('reservation');

    const { errors, setErrors, validation } = useValidations()

    const { getDataFromLocalStorage, getDataFromSessionStorage, removeDataFromSessionStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage("user_data")
    const booking_id = getDataFromSessionStorage('booking_id')

    const state = useSelector(state => state)
    const bookings = state?.bookings?.bookings
    const upcomming_bookings = state?.bookings?.upcomming_bookings
    const pagination_data = state?.bookings?.booking_pagination_data
    const dropdown_concierges = state?.adminDashboard?.dropdown_concierges

    const prevFilterDataRef = useRef(initialFilterState);

    // states
    const [type, setType] = useState('total')
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [bookingPopup, setBookingPopup] = useState(false)
    const [bookingDetail, setBookingDetail] = useState(false)
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [bookingForm, setBookingForm] = useState(initialBookingForm)
    const [modificationLoading, setModificationLoading] = useState(false)
    const [addFilter, setAddFilter] = useState(false)
    const [filterData, setFilterData] = useState(initialFilterState)
    const [filterUpdate, setFilterUpdate] = useState(false)
    const [singleLoading, setSingleLoading] = useState(false)
    const [appliedFilter, setAppliedFilter] = useState()

    // get Single Booking detail
    const getSingleBookingDetail = () => {
        const success = (data) => {
            setBookingForm(data)
            setSingleLoading(false)
        }
        const fail = () => {
            setSingleLoading(false)
        }
        setSingleLoading(true)
        dispatch(getSingleReservation(access_token, reservation, success, fail))
    }

    // handle change filter 
    const handleChangeFilter = (e) => {
        const { name, value } = e.target
        setFilterData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    // on apply filter
    const onApplyFilter = () => {
        setAddFilter(false)
        if (filterUpdate) {
            setCurrentPage(1)
            setLoading(true)
            getBooking()
        }
    }

    // on clear filter
    const onClearFilter = () => {
        setAddFilter(false)
        setAppliedFilter()
        const prevFilterData = initialFilterState;
        // Function to compare the current state with the previous state
        const hasStateUpdated = (prev, current) => {
            return Object.keys(prev).some(key => prev[key] !== current[key]);
        };
        if (hasStateUpdated(prevFilterData, filterData)) {
            prevFilterDataRef.current = initialFilterState
            getBooking(false, true)
            setFilterData(initialFilterState)
        }
    }

    // get booking data
    const getBooking = (noLoading, noFilter) => {
        const success = () => {
            setLoading(false)
            setBookingPopup(false)
        }
        const fail = () => {
            setLoading(false)
        }

        let payload = {
            page: currentPage,
            per_page: 10,
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : ""
        }
        if (type == 'upcomming') {
            payload = {
                ...payload,
                type: 'upcoming'
            }
        }
        if (!noFilter) {
            payload = {
                ...payload,
                ...filterData
            }
            delete payload.dropdownValue
            setAppliedFilter({
                status: payload?.status,
                search_text: payload?.search_text,
                start_date: payload?.start_date,
                end_date: payload?.end_date,
                user_id: payload?.user_id,
            })
        }
        !noLoading && setLoading(true)
        dispatch(getRestaurantBookings(access_token, payload, success, fail))
    }

    // request modification
    const requestModification = (type, payload) => {
        let payloadUpdated = {
            ...payload,
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : ""
        }
        const success = () => {
            setModificationLoading(false)
            getBooking()
            setBookingDetail(false)
        }
        const fail = () => { setModificationLoading(false) }
        type != 'billing' && setModificationLoading(type)
        type == 'billing'
            ? onConfirmBilling(payloadUpdated)
            : type == 'no_show'
                ? dispatch(noShowRequestBooking(access_token, payloadUpdated, success, fail))
                : type == 'confirm'
                    ? dispatch(confirmRequestBooking(access_token, payloadUpdated, success, fail))
                    : type == 'cancel'
                        ? dispatch(CancelRequestBooking(access_token, payloadUpdated, success, fail))
                        : dispatch(RequestBookingmodification(access_token, payloadUpdated, success, fail))
    }

    // on confirm billing 
    const onConfirmBilling = (payload) => {
        let payloadUpdated = {
            ...payload,
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : ""
        }
        const success = () => {
            getBooking()
            setBookingDetail(false)
            setModificationLoading(false)
        }
        const fail = () => { setModificationLoading(false) }
        const proof = payloadUpdated?.proof?.name ? convertImgToUrl(payloadUpdated?.proof) : ''
        const amount = payloadUpdated?.amount
        const err = validation({ amount, proof })
        setErrors(err)
        if (objectKeyConvertToArray(err)?.length == 0) {
            setModificationLoading('billing')
            dispatch(billBookingRequest(access_token, payloadUpdated, success, fail))
        }
    }

    // const allBookingData = useMemo(() => {
    //     return type == 'total' ? bookings : type == 'upcomming' ? upcomming_bookings : []
    // }, [type, bookings, upcomming_bookings])

    const allBookingData = useMemo(() => {
        return bookings
    }, [bookings])

    useEffect(() => {
        getBooking()
    }, [currentPage, type])

    useEffect(() => {
        setLoading(true)
        setCurrentPage(1)
    }, [type])

    useEffect(() => {
        dispatch(getDropdownConciergesAPI())
    }, [])

    useEffect(() => {
        if (reservation) {
            setBookingDetail(true)
            getSingleBookingDetail()
            const newUrl = '/restaurant/booking';
            window.history.replaceState(null, '', newUrl);
        }
    }, [])

    // check for notification redirection
    useEffect(() => {
        if (booking_id) {
            const data = allBookingData?.find(itm => itm?.number == booking_id)
            setBookingForm(data)
            setBookingDetail(true)
            removeDataFromSessionStorage('booking_id')
        }
    }, [booking_id, allBookingData])


    useEffect(() => {
        const prevFilterData = prevFilterDataRef.current;
        // Function to compare the current state with the previous state
        const hasStateUpdated = (prev, current) => {
            return Object.keys(prev).some(key => prev[key] !== current[key]);
        };
        if (hasStateUpdated(prevFilterData, filterData)) {
            setCurrentPage(1)
            setFilterUpdate(true)
        } else {
            setFilterUpdate(false)
        }
        // Update the ref with the current state for the next render
        prevFilterDataRef.current = filterData;
    }, [filterData]);

    return {
        type, setType, loading, currentPage, dropdown_concierges, setCurrentPage, allBookingData, filterData, setFilterData, startDate,
        setStartDate, endDate, setEndDate, setBookingPopup, bookingDetail, setBookingDetail, bookingForm, setBookingForm, pagination_data,
        setErrors, requestModification, modificationLoading, errors, onConfirmBilling, addFilter, setAddFilter, filterUpdate, setFilterUpdate,
        handleChangeFilter, onApplyFilter, onClearFilter, singleLoading, appliedFilter, navigate
    }

}

export default useBookings