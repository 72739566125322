import { switchBusinessProfile } from "Adapters/APIs/Restaurant/Business"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import useLogin from "OnBaording/Login/helper"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

const useSwitchBusinessHelper = () => {
    const { successCallback } = useLogin()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { getDataFromLocalStorage, storeDataInSessionStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const defaultRoute = getDataFromLocalStorage('defaultRoute')

    // states
    const [swicthLoading, setSwitchLoading] = useState(false)
    const [switchProfile, setSwitchProfile] = useState(false)
    const [switchLoader, setSwitchLoader] = useState(false)

    const handleSwitchingBusiness = () => {
        setSwitchLoader(true)
        successCallback && successCallback(switchProfile, true)
        window.location.href = defaultRoute
    }

    // switch to other business
    const switchToOtherBusiness = (data) => {
        const success = (data) => {
            setSwitchLoading(false)
            setSwitchProfile(data)
        }
        const fail = (error) => {
            setSwitchLoading(false)
            if (error?.response?.data?.status == 404) {
                let sessionData = {
                    restaurant_name: data?.name,
                    categories: data?.categories?.map(itm => itm?.id),
                    cuisine_id: data?.cuisine?.id,
                    contact_person: data?.contact_person,
                    authorized_manager: data?.authorized_manager,
                    email: data?.email,
                    phone_code: data?.phone_code,
                    phone_number: `${data?.phone_code ? data?.phone_code : ""}-${data?.phone_number}`,
                    mobile_code: data?.mobile_code,
                    mobile_number: `${data?.mobile_code ? data?.mobile_code : ""}-${data?.mobile_number ? data?.mobile_number : ""}`,
                    address: data?.address,
                    trade_license: data?.trade_license,
                    terms_condition: true,
                    privacy_policy: true,
                }
                storeDataInSessionStorage('businessData', { ...sessionData, restaurant_id: data?.id })
                navigate("/restaurant/create-business")
            }
        }
        let payload = {
            restaurant_id: data?.id
        }
        setSwitchLoading()
        dispatch(switchBusinessProfile(access_token, payload, success, fail))
    }

    return {
        swicthLoading, switchLoader, switchProfile, setSwitchProfile, switchToOtherBusiness, handleSwitchingBusiness
    }
}

export default useSwitchBusinessHelper