import Svgs from 'Assets/svgs';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

const FilePicker = ({
    id,
    title,
    name,
    value,
    accept,
    error,
    required,
    bgColor,
    onChange,
    customClass,
    mb_file_size,
    multiple,
    imgWidth,
    imgHeight,
    errorValidation,
    errorText, imgSize
}) => {
    const [image, setImage] = useState(undefined);
    const [fileType, setFileType] = useState(''); // State to track file type (pdf, image, etc.)

    const inputRef = useRef(null);  // Ref for the input field

    // Effect to handle setting the image from URL
    useEffect(() => {
        if (value) {
            if (typeof value === 'string') {
                setImage(value);
                if (value.endsWith('.pdf')) {
                    setFileType('pdf');
                } else if (value.match(/\.(doc|docx)$/)) {
                    setFileType(value.endsWith('.doc') ? 'doc' : 'docx');
                } else if (value.match(/\.(jpeg|jpg|gif|png)$/)) {
                    setFileType('image');
                }
            } else if (value instanceof Blob || value instanceof File) {
                let url = URL.createObjectURL(value);
                setImage(url);
            }
        }
    }, [value]);
    

    const handleDelete = () => {
        // Manually set the file input value to an empty string
        if (inputRef.current) {
            inputRef.current.value = '';
        }
        // Call onChange with an empty value
        onChange && onChange({
            target: {
                name: name ? name : 'file',
                value: '',
                files: []
            }
        });
    };

    const handleImageChange = (e) => {
        if (errorValidation) {
            toast.error(errorText ? errorText : "You can't add more images", { toastId: "errorValidationToast" });
            return;
        }

        const file = e.target.files[0];
        if (!file) return;  // No file selected

        if (multiple) {
            for (let i = 0; i < e.target.files.length; i++) {
                let file = e.target.files[i];
                let file_size_mb = file.size / 1000 / 1000;
                if (file_size_mb > mb_file_size) {
                    toast.info(`Maximum file size limit is ${mb_file_size} MB`, { toastId: "sizeLimitToast" });
                    return;
                }
            }
        } else {
            let file_size_mb = file.size / 1000 / 1000;
            if (file_size_mb > mb_file_size) {
                toast.info(`Maximum file size limit is ${mb_file_size} MB`, { toastId: "sizeLimitToast" });
                return;
            }
        }

        const fileExtension = file?.name?.split('.').pop();
        const validFileTypes = accept ? accept.split(',') : ['*'];

        if (!accept ||
            validFileTypes.includes(file.type) || // Check MIME type (like image/png)
            validFileTypes.includes(`.${fileExtension}`) || // Check extension (like .png)
            (accept ? accept : '*')
                ?.split(',')
                ?.map(format => {
                    if (format.startsWith('image/')) {
                        return format.replace('image/', '').trim();
                    } else if (format.startsWith('application/')) {
                        return format.replace('application/', '').trim();
                    }
                    return format.trim();
                })
                .includes(fileExtension)
        ) {
            // Update file type state based on the file extension
            setFileType(fileExtension);

            if (imgWidth && imgHeight) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const img = new Image();
                    img.src = event.target.result;
                    img.onload = () => {
                        const width = img.width;
                        const height = img.height;
                        if (width <= imgWidth && height <= imgHeight) {
                            onChange && onChange(e);
                            if (!multiple) {
                                let url = URL.createObjectURL(file);
                                setImage(url);
                            }
                        } else {
                            toast.info(`Reduce image resolution to ${imgSize} or lower`, { toastId: "resolutionToast" });
                        }
                    };
                };
                reader.readAsDataURL(file);
            } else {
                onChange && onChange(e);
                if (!multiple) {
                    let url = URL.createObjectURL(file);
                    setImage(url);
                }
            }

            inputRef.current.value = null; // Clear input value
        } else {
            toast.info(`Only ${accept ? accept : 'image/*'} formats are supported.`, { toastId: "toast" });
            return;
        }
    };

    return (
        <>
            <div className="flex relative my-2 flex-col gap-2">
                {title &&
                    <div className={`text-sm col-span-2 text-blueGrey flex items-center gap-1`}>
                        {title}
                        {required && <span className="leading-[1] text-red-600"> *</span>}
                    </div>
                }
                <input
                    ref={inputRef}
                    hidden
                    type="file"
                    multiple={multiple ? true : false}
                    onChange={handleImageChange}
                    id={id ? id : "edit"}
                    name={name ? name : 'file'}
                    accept={accept ? accept : 'image/*'}
                />
                <label
                    htmlFor={id ? id : "edit"}
                    className={`${error && 'border-red-500 text-red-500'} ${customClass ? customClass : "attach-file-btn"} ${bgColor ? bgColor : ''} gap-3 cursor-pointer`}
                >
                    <div>
                        <Svgs.AttachmentIcon
                            fill={error ? '#eb3b3b' : 'var(--secondary-color)'}
                        />
                    </div>
                    Attach File
                </label>
                {error && (
                    <span className={"text-error flex items-center gap-1 text-xs absolute top-[103%] w-full justify-end"}>
                        <Svgs.I fill="#eb3b3b" />
                        {error}
                    </span>
                )}
            </div>
            {(value?.name || (value != '' && value != null && typeof value === 'string')) &&
                <div className={`flex gap-3 bg-darkGrey text-blueGrey items-center p-2 rounded-xl shadow justify-between ${customClass ? customClass : "attach-file-btn"}`}>
                    <div className="flex items-center gap-2 line-clamp-1">
                        <div><Svgs.FileIcon /></div>
                        {(value?.name || value)}
                    </div>

                    <div
                        className='cursor-pointer p-2 rounded-full'
                        onClick={handleDelete} // Use the delete handler function
                    >
                        <Svgs.CrossIcon fill={'#fff'} />
                    </div>
                </div>
            }
        </>
    );
};

FilePicker.defaultProps = {
    required: true
};

export default FilePicker;
