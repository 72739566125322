import { GET_ALL_BOOKINGS, GET_BOOKING_ANALYTICS } from "./ActionType";

const initialState = {
    bookings: [],
    total_booking: 0,
    total_bill_amount: 0,
    total_manager_earning: 0,
    total_concierges_recieved: 0,
    upcomming_bookings: [],
    booking_pagination_data: {},
    booking_analytics: [],
    ba_pagination: {}

}

const BookingReducder = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_BOOKINGS:
            return {
                ...state,
                bookings: payload?.reservations,
                booking_pagination_data: payload?.pagination_data?.meta,
                upcomming_bookings: payload?.upcoming_bookings,
                total_booking: payload?.total_earning,
                total_concierges_recieved: payload?.total_concierges_recieved,
                booking_data: payload
            }
        case GET_BOOKING_ANALYTICS:
            return {
                ...state,
                booking_analytics: payload?.all_bookings,
                ba_pagination: payload?.pagination_data?.meta,
                total_manager_earning: payload?.total_manager_earning,
                total_concierges_recieved: payload?.total_concierges_recieved,
                total_bill_amount: payload?.total_bill_amount,
            }
        default:
            return {
                ...state,
            };
    }
}

export default BookingReducder