import Svgs from "Assets/svgs"

const AdminSidebarRoutes = (active) => {
  const adminDashBoard = [
    {
      url: '/admin/dashboard',
      heading: 'Dashboard',
      active: active == 'Dashboard',
      icon: <Svgs.DashbaordIcon />
    },
    {
      url: '/admin/restaurants',
      heading: 'Businesses',
      active: active == "Restaurant",
      icon: <Svgs.BuildingIcon fill={active == "Restaurant" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
    },
    {
      url: '/admin/concierges',
      heading: 'Concierges',
      active: active == 'Concierge',
      icon: <Svgs.ConciergeIcon />
    },
    // {
    //   url: '/admin/wishlist',
    //   heading: 'Wishlist',
    //   active: active == 'Wishlist',
    //   icon: <Svgs.WishlistIcon fill={active == "Wishlist" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
    // },
    {
      url: '/admin/rewards',
      heading: 'Rewards',
      active: active == 'reward',
      icon: <Svgs.RewardIcon />
    },
    {
      url: '/admin/transactions-payments',
      heading: 'Transactions & Payments',
      active: active == 'transaction',
      icon: <Svgs.TransactionIcon />
    },
    // {
    //   url: '/admin/promotions',
    //   heading: 'Promotions Boosting Managment',
    //   active: active == 'promotion',
    //   icon: <Svgs.PromotionSvg stroke={active == "promotion" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
    // },
    // {
    //   url: '/admin/promotion-request',
    //   heading: 'Promotion Request',
    //   active: active == 'PromotionRequest',
    //   icon: <Svgs.PromotionRequest fill={active == "PromotionRequest" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
    // },
    {
      url: '/admin/finance',
      heading: 'Finance',
      active: active == 'finance',
      icon: <Svgs.RewardIcon />
    },
    // {
    //   url: '/admin/staff',
    //   heading: 'Staff',
    //   active: active == 'staff',
    //   icon: <Svgs.ProfileIcon />
    // },
    {
      url: '/admin/analytics',
      heading: 'Analytics',
      active: active == 'analytics',
      icon: <Svgs.AnalyticsIcon fill={active == "analytics" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
    },
    // {
    //   url: '/admin/support-request',
    //   heading: 'Support Request',
    //   active: active == 'SupportRequest',
    //   icon: <Svgs.WishlistIcon fill={active == "SupportRequest" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
    // },
  ]
  return adminDashBoard
}

export default AdminSidebarRoutes