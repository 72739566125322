import Svgs from 'Assets/svgs';
import React from 'react'

const TeamMemberCard = ({ data, key, name, phoneNumber, onEditClick }) => {
    return (
        <div className='flex justify-between items-center gap-2 p-4 bg-darkGrey rounded-md' key={key}>
            <div className='flex flex-col space-y-2'>
                <h2 className='text-lg text-white'>{name}</h2>
                <p className='text-sm text-blueGrey'>{phoneNumber}</p>
            </div>
            <div className='flex border p-1.5 rounded-full border-[#4E4E4E] cursor-pointer'
                onClick={(e) => {
                    e.stopPropagation();
                    onEditClick && onEditClick()
                }}
            >
                <Svgs.EditIconV2 fill={'var(--secondary-color)'} height={14} width={14} />
            </div>
        </div>
    )
}

export default TeamMemberCard