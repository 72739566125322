import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import CheckBox from 'Components/Elements/Checkbox'
import React from 'react'

const SubscriptionCard = ({ title, isCheckbox, onSelectedChange, selected, details, data }) => {
    return (
        <>
            <div className="bg-darkGrey rounded-lg p-4 border border-secondary">
                <div className="flex flex-col xs:flex-row justify-between gap-2 items-center text-blueGrey">
                    {isCheckbox &&
                        <CheckBox
                            label={''}
                            checked={selected ? selected : false}
                            name={title}
                            onChange={() => onSelectedChange(data, 'single')}
                        />}
                    <div className="flex flex-col gap-3">
                        <div className="flex items-center gap-3 text-white font-semibold text-lg">
                            <div className='bg-lightGrey flex px-[10px] py-2 rounded-[10px] gap-2'>
                                <Svgs.ReportIconV3 stroke={'var(--secondary-color)'} />
                            </div>
                            Pending
                        </div>
                    </div>
                    <span className=" font-semibold text-base">{title}</span>
                    <div className='flex w-fit gap-2 text-black font-semibold px-4 py-1.5 bg-secondary rounded-[10px]'>
                        {details?.monthly_fee} {currency}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubscriptionCard